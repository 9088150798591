import { Box, Container, Typography } from "@mui/material";
import React from "react";
import CoverImageDisplay from "../../../Shared/CoverImageDisplay";
import SampleEssayAnalysis from "./SampleEssayAnalysis";
import { renderFormattedParagraphs } from "../../../../utils/StringUtils";
import { useTheme } from "@emotion/react";
import ShareButton from "../../../Shared/ShareButton";


const SampleEssayContents = ({ sampleStory, sampleEssayProps, containerStyles = {}, shareURL }) => {
    const theme = useTheme();

    const { allowReadSampleEssay } = sampleEssayProps;

    return (
        <Container maxWidth='sm'
            sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                boxSizing: 'border-box',
                gap: 4,
                ...containerStyles,
            }}
        >
            <CoverImageDisplay coverImages={sampleStory?.metadata?.coverImages} size={'90%'} />

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 4,
                }}
            >
                <Typography variant='h5'>{sampleStory?.metadata?.title ?? ''}</Typography>

                {allowReadSampleEssay &&
                    <>
                        {renderFormattedParagraphs(sampleStory?.sampleEssay ?? '', { fontSize: theme.typography.paragraph.fontSize })}
                    </>
                }

                <Box sx={{ width: '100%', height: '0px', borderBottom: 1, borderColor: 'primary.main', my: 1 }} />

                {sampleStory?.analysis &&
                    <SampleEssayAnalysis analysis={sampleStory?.analysis} />
                }

                {shareURL &&
                    <ShareButton
                        shareUrl={shareURL}
                        shareTitle={'噗噗范文：' + sampleStory.metadata.title}
                    />
                }
            </Box>
        </Container>
    );
};

export default SampleEssayContents
