import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { StyledContainedButton } from '../../styles/StyledButtons';
import { processNewLines } from '../../utils/StringUtils';
import { useTheme } from '@emotion/react';
import { collectionToWriteUrlMapping } from '../WriteStoryPage/storyUtils';
import { useAuthentication } from '../../hooks/useAuthentication';
import useCommonUtilities from '../../hooks/useCommonUtilities';
import happy_cloud from '../../assets/happy_cloud.png';
import { LockRounded } from '@mui/icons-material';


const ActionHub = ({ containerStyles }) => {
    const { userInfo } = useAuthentication();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { openDialog } = useCommonUtilities();
    const theme = useTheme();

    const userTags = userInfo?.userTags;
    const devUserTag = 'dev'

    const handleNewStoryClick = () => {
        navigate(collectionToWriteUrlMapping.essays);
    };

    const handleNewDeckClick = () => {
        if (userTags === devUserTag) {
            navigate(collectionToWriteUrlMapping.pcards);
        } else {
            openDialog({
                content: "素材制作功能将在近期上线！敬请期待！",
                image: (
                    <>
                        <img src={happy_cloud} alt="happy_cloud" style={{ maxHeight: '90px', height: 'auto' }} />
                    </>
                ),
            });
        }
    };

    const panelStyles = {
        border: 1, borderColor: 'primary.main', borderRadius: theme.custom.radius.small,
        mx: 1, padding: 2, gap: 2,
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%'
    };

    return (
        <Box sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: 2,
            width: '100%',
        }}>
            <Typography variant='h5' textAlign='center' fontSize={28}
                sx={{
                    color: 'primary.contrastText',
                    fontWeight: 'bold',
                }}>
                {t('home.actionHubHeader')}
            </Typography>

            <Box sx={{ ...containerStyles, position: 'relative' }}>
                <Grid container direction='row' sx={{rowGap: 2, }}>
                    <Grid item xs={12} sm={6}>
                        <Box sx={panelStyles}>
                            <Typography variant='subtitle1' textAlign='center' sx={{ color: 'text.secondary' }}>
                                {processNewLines(t('home.actionHubSubtitle'))}
                            </Typography>
                            <StyledContainedButton variant="contained" disableRipple onClick={handleNewStoryClick}>
                                {t('home.newEssay')}
                            </StyledContainedButton>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Box sx={panelStyles}>
                            <Typography variant='subtitle1' textAlign='center' sx={{ color: 'text.secondary' }}>
                                {processNewLines(t('home.actionHubSubtitle2'))}
                            </Typography>

                            <StyledContainedButton variant="contained" disableRipple onClick={handleNewDeckClick}>
                                <LockRounded fontSize={'small'} sx={{ marginRight: 1 }} />
                                {t('home.createPCard')}
                            </StyledContainedButton>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default ActionHub;
