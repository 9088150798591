import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Link, Typography, InputAdornment, IconButton, Box, Container, CircularProgress } from '@mui/material';
import { Edit, Visibility, VisibilityOff } from '@mui/icons-material';
import { isValidEmail } from '../../utils/authUtils';
import { useAuthentication } from '../../hooks/useAuthentication';
import ApiServices from '../../services/ApiServices';
import ErrorHelperText from '../Shared/ErrorHelperText';
import { StyledOutlineTextField } from '../../styles/StyledTextFields';
import { StyledContainedButton } from '../../styles/StyledButtons';
import withRoot from '../../withRoot';
import AppAppBar from '../Shared/AppAppBar';
import AppFooter from '../Shared/AppFooter';
import weixinLogo from '../../assets/icon48_appwx_logo.png'


function LoginPage() {
    const { handleLogin, token } = useAuthentication();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const loginMethod = 'mobile';   // email or mobile

    // User credentials
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    // Login states and errors
    const [step, setStep] = useState(1);
    const [usernameError, setUsernameError] = useState(null);
    const [loginError, setLoginError] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    // Handlers
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleUsernameChange = (value) => {
        // Disable mobile number check so that user registered before 04/10/2024 can login with email
        // If the sign-up method is 'mobile', enforce the 11-digit limit
        // if (loginMethod === 'mobile') {
        //     if (value.length <= 11) {
        //         setUsername(value);
        //     }
        // } else {
        //     setUsername(value);
        // }
        setUsername(value)
    };

    const handleClickEditUsername = () => {
        setStep(1);
        setPassword("");
        setShowPassword(false);
        setUsernameError(null);
        setLoginError(null);
    };

    const mobileUsernameCheck = (username) => {
        // Disable mobile number check so that user registered before 04/10/2024 can login with email
        // if (!isValidChineseMobile(username, true)) {
        //     setUsernameError(t('AuthPage.errors.phoneError'));
        //     return false;
        // } else {
        //     return true;
        // }
        return true;
    };

    const emailUsernameCheck = (username) => {
        if (!isValidEmail(username, true)) {
            setUsernameError(t('AuthPage.errors.emailError'));
            return false;
        } else {
            return true;
        }
    }

    const handleContinue = async (event) => {
        event.preventDefault();
        if (isLoading) return;
        setIsLoading(true);
        setLoginError(null);

        if (step === 1) {
            let isUsernameValid;

            if (loginMethod === 'mobile') {
                isUsernameValid = mobileUsernameCheck(username);
            } else if (loginMethod === 'email') {
                isUsernameValid = emailUsernameCheck(username);
            }

            if (isUsernameValid) {
                setUsernameError(null);
                setStep(2);
            }
            setIsLoading(false);
            return;
        } else {
            try {
                const data = await ApiServices.getInstance().userLogin(username, password);
                handleLogin(data.access_token);
                setIsLoading(false);
                navigate(`/home`);
            } catch (error) {
                setIsLoading(false);
                if (error.response?.status === 401) {
                    setLoginError(t('AuthPage.errors.loginError'));
                } else if (error.response?.status === 429) {
                    setLoginError(t('AuthPage.errors.tooManyAttemptsError'));
                } else if (error.response?.status === 410) {
                    setLoginError(t('AuthPage.errors.credentialExpired'));
                } else {
                    setLoginError(t('AuthPage.errors.loginFailed'));
                }
                setIsLoading(false);
            }
        }
    };

    useEffect(() => {
        if (token) {
            navigate(`/home`);
        }
    }, [token, navigate]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                width: '100vw',
                backgroundColor: 'background.main',
            }}
        >
            <AppAppBar />

            <Box
                component="form"
                sx={{ flex: 1, display: 'flex', flexDirection: 'column', minHeight: '90vh' }}
                onSubmit={handleContinue}
            >
                <Container maxWidth='xs'
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >

                    <Typography
                        variant='h4'
                        sx={{
                            textAlign: 'center',
                            fontWeight: 400,
                            marginBottom: 4,
                            color: 'primary.contrastText',
                            marginTop: 10,
                        }}
                    >
                        {t('AuthPage.loginHeader')}
                    </Typography>

                    {/* Credential input fields */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            maxWidth: '320px',
                            boxSizing: 'border-box',
                        }}
                    >
                        {step === 1 ? (
                            <>
                                <StyledOutlineTextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label={t(`AuthPage.${loginMethod}`)}
                                    autoFocus
                                    value={username}
                                    onChange={(e) => {
                                        handleUsernameChange(e.target.value);
                                        setUsernameError(null);
                                    }}
                                    InputProps={loginMethod === 'mobile' ?
                                        {
                                            startAdornment: (
                                                <InputAdornment position="start">+86</InputAdornment>
                                            ),
                                            inputProps: {
                                                type: 'tel',
                                            }
                                        } : {
                                            inputProps: {
                                                type: 'text',
                                            }
                                        }}
                                    error={!!usernameError}
                                    helperText={<ErrorHelperText error={!!usernameError}>{usernameError}</ErrorHelperText>}
                                />
                            </>
                        ) : (
                            <>
                                <StyledOutlineTextField
                                    variant="outlined"
                                    margin="none"
                                    fullWidth
                                    label={t(`AuthPage.${loginMethod}`)}
                                    value={username}
                                    disabled
                                    autoComplete="username"
                                    InputProps={
                                        {
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={handleClickEditUsername}
                                                        style={{ backgroundColor: 'transparent', position: 'relative', right: '-10px' }}
                                                        disabled={isLoading}
                                                    >
                                                        <Edit />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                            startAdornment: loginMethod === 'mobile' ? (
                                                <InputAdornment position="start">+86</InputAdornment>
                                            ) : null,
                                            inputProps: {
                                                type: loginMethod === 'mobile' ? 'tel' : 'text',
                                            },
                                        }
                                    }
                                />
                                <StyledOutlineTextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label={t('AuthPage.password')}
                                    value={password}
                                    autoFocus
                                    autoComplete="current-password"
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                        if (e.target.value === '') {
                                            setLoginError(null);
                                        }
                                    }}
                                    type={showPassword ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={handleClickShowPassword}
                                                    style={{ backgroundColor: 'transparent', position: 'relative', right: '-10px' }}
                                                    disableRipple
                                                    disabled={isLoading}
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    error={!!loginError}
                                    helperText={<ErrorHelperText error={!!loginError}>{loginError}</ErrorHelperText>}
                                    disabled={isLoading}
                                />
                            </>
                        )}
                        {/* 
                        <Typography
                            variant='subtitle2'
                            sx={{ color: 'text.secondary' }}
                        >
                            {t('AuthPage.deviceAdaption')}
                        </Typography> */}
                    </Box>

                    {/* Reset password */}
                    <Link
                        component={RouterLink}
                        to={`/reset-password`}
                        variant="body2"
                        color="primary.contrastText"
                        style={{ textTransform: 'none', textDecoration: 'none', fontWeight: 500, fontSize: '1em', marginTop: '10px', marginLeft: '12px' }}
                    >
                        {t('AuthPage.forgotPassword')}
                    </Link>

                    {/* Continue Button */}
                    <StyledContainedButton
                        variant='contained'
                        disableRipple
                        type='submit'
                        disabled={isLoading}
                        sx={{ marginTop: 3 }}
                    >
                        {isLoading &&
                            <CircularProgress size={'20px'} sx={{ color: 'primary.contrastText', marginRight: 2 }} />
                        }
                        {t('AuthPage.login')}
                    </StyledContainedButton>

                    {/* Link to Signup */}
                    <Typography
                        sx={{ color: 'text.secondary', marginTop: 3, fontSize: '1em', }}
                    >
                        {t('AuthPage.dontHaveAccount')}{' '}
                        <Link
                            component={RouterLink}
                            to={`/signup`}
                            color="primary.contrastText"
                            style={{ textTransform: 'none', textDecoration: 'none', fontWeight: 500, fontSize: '1em' }}
                        >
                            {t('AuthPage.signup')}
                        </Link>
                    </Typography>

                    <Typography
                        sx={{ color: 'text.secondary', marginTop: 3, fontSize: '1em', }}
                    >
                        {t('AuthPage.moreLoginOptions')}
                    </Typography>


                    <Link
                        component={RouterLink}
                        to={`/wechat-login`}
                        color="primary.contrastText"
                        style={{ textTransform: 'none', textDecoration: 'none', fontWeight: 500, fontSize: '1em' }}
                        sx={{ display: 'flex', alignItems: 'center', gap: '5px', marginTop: 1 }}
                    >
                        <Box
                            component="img"
                            sx={{ width: '24px', height: 'auto', objectFit: 'cover', borderRadius: '50%' }}
                            src={weixinLogo}
                            alt="weixin logo"
                        />
                        {t('AuthPage.wechatLogin')}
                    </Link>
                </Container>
            </Box>

            <AppFooter />
        </Box>

    );
}

export default withRoot(LoginPage);
