import { Navigate, BrowserRouter as Router, Routes, Route, 
	// useNavigate, useLocation 
} from 'react-router-dom';
import { AuthenticationProvider } from './providers/AuthenticationProvider';
import { CommonUtilitiesProvider } from './providers/CommonUtilitiesProvider';
import { ProtectedRoute } from './routing/ProtectedRoute';
import './App.css';

// Components
import LoginPage from './components/AuthPage/LoginPage';
import SignupPage from './components/AuthPage/SignupPage';
import HomePage from './components/HomePage/HomePage';
import LandingPage from './components/LandingPage/LandingPage';
import WriteStoryPage from './components/WriteStoryPage/WriteStoryPage';
import ViewStoryPage from './components/WriteStoryPage/ViewStoryPage';
import ResetPasswordPage from './components/AuthPage/ResetPasswordPage';
import WeChatLoginPage from './components/AuthPage/WeChatLoginPage';
// import { useEffect } from 'react';


function App() {
	const EventQRRedirect = () => {
		// const navigate = useNavigate();
		// const location = useLocation();

		// useEffect(() => {
			// const params = new URLSearchParams(location.search);
			// const index = params.get('index');

			// let redirectUrl = '';

			// switch (index) {
			// 	case '1':
			// 		redirectUrl = 'https://ts.pufflearn.com/summer2024';
			// 		break;
			// 	default:
			// 		redirectUrl = 'https://ts.pufflearn.com/summer2024';
			// 		break;
			// }

			// window.location.href = redirectUrl;
		// }, [location, navigate]);

		return null;
	};

	return (

		<Router>
			<div className="App">
				<AuthenticationProvider>
					<CommonUtilitiesProvider>
						<Routes>
							<Route path="/event-qr" element={<EventQRRedirect />} />
							<Route path="/login" element={<LoginPage />} />
							<Route path="/signup" element={<SignupPage />} />
							<Route path="/wechat-login/oauth/wechat-callback" element={<WeChatLoginPage />} />
							<Route path="/wechat-login" element={<WeChatLoginPage />} />
							<Route path="/reset-password" element={<ResetPasswordPage />} />
							<Route path="/landing" element={<LandingPage />} />

							<Route path="read-story" element={<ViewStoryPage />} />
							<Route path="read-story/:story_type" element={<ViewStoryPage />} />
							<Route path="read-story/:story_type/:story_id" element={<ViewStoryPage />} />

							<Route element={<ProtectedRoute />}>
								<Route path="/home" element={<HomePage />} />

								<Route path="write-story" element={<WriteStoryPage />} />
								<Route path="write-story/:story_type" element={<WriteStoryPage />} />
								<Route path="write-story/:story_type/:story_id" element={<WriteStoryPage />} />
							</Route>

							<Route path="/*" element={<Navigate to="/login" replace />} />
						</Routes>

					</CommonUtilitiesProvider>
				</AuthenticationProvider>
			</div>
		</Router>
	);
};

export default App;
