import React from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import GalleryList from '../Shared/GalleryList/GalleryList';


const UserGalleries = ({ essays, containerStyles }) => {
    const { t } = useTranslation();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: 2,
            width: '100%',
        }}>
            <Typography variant='h5'
                sx={{
                    color: 'primary.contrastText',
                    fontWeight: 'bold',
                }}>
                {t('home.userGalleriesHeader')}
            </Typography>

            <Box sx={{
                ...containerStyles,
                pt: 1,
            }}>
                {/* Hide tabs for v3 */}
                {
                    false &&
                    <Tabs value={value} onChange={handleChange} centered >
                        <Tab label={t('home.essays')} disableRipple />
                    </Tabs>
                }


                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        mt: 1,
                    }}
                >
                    {value === 0 && (
                        <GalleryList
                            artifacts={essays} />)
                    }
                </Box>
            </Box>
        </Box>
    );
};

export default UserGalleries;
