import React from 'react';
import { Box, Typography } from "@mui/material"
import { useTranslation } from 'react-i18next';
import { StyledContainedButton } from '../../styles/StyledButtons';
import { FitScreenRounded } from '@mui/icons-material';

const MoreActionsCard = ({ onClickDisplayAllCard }) => {
    const { t } = useTranslation();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                boxSizing: 'border-box',
                width: '96%',
                maxWidth: '220px',
                height: '100%',
                color: 'primary.contrastText',
            }}
        >
            <Typography variant="h6">
                还有更多卡牌
            </Typography>

            <FitScreenRounded fontSize='large' />

            <StyledContainedButton variant="contained" disableRipple
                onClick={onClickDisplayAllCard}
                sx={{ mt: 2 }}
            >
                {t('home.displayAllPCards')}
            </StyledContainedButton>
        </Box>
    );
};

export default MoreActionsCard;
