import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import GalleryCard from './GalleryCard';
import { Typography } from '@mui/material';

function GalleryList({ artifacts, sx = {} }) {
    const { t } = useTranslation();
    const theme = useTheme();

    if (artifacts == null) {
        return null;
    }

    return (
        <Box sx={{
            padding: 0,
            marginTop: 1,
            width: '100%',
            boxSizing: 'border-box',
            ...sx
        }}
        >
            <Grid
                container
                rowSpacing={2}
            >
                {artifacts.map((artifact, index) => (
                    <Grid item xs={12} md={artifacts.length === 1 ? 12 : 6} key={artifact._id} >
                        <GalleryCard
                            index={index}
                            artifact={artifact}
                            t={t}
                            theme={theme}
                        />
                    </Grid>
                ))}
            </Grid>

            {artifacts.length === 0 &&
                <Typography
                    variant='h6'
                    sx={{
                        color: 'text.secondary',
                        marginY: '50px'
                    }}
                >
                    这个列表是空的...
                </Typography>
            }
        </Box>
    );
}

export default GalleryList;
