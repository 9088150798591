import { Box, CircularProgress, Typography } from '@mui/material';
import React from 'react';
import MessageBubble from '../../Shared/MessageBubble';
import CustomTextField from '../../Shared/CustomTextField';
import { useImprovementRound } from './useImprovementRound';
import { useTranslation } from 'react-i18next';
import { StyledContainedButton } from '../../../styles/StyledButtons';
import pupu_avatar from "../../../assets/puff_copilot.png";
import { processNewLines } from '../../../utils/StringUtils';
import DividingLine from '../../Shared/DividingLine';
import { WriteStoryModes } from '../storyUtils';
import StringAvatar from '../../Shared/StringAvatar';
import { useAuthentication } from '../../../hooks/useAuthentication';


const ImprovementRound = ({ index: roundIndex, writeStoryProps, mode }) => {
    const { t } = useTranslation();
    const { userInfo } = useAuthentication();

    // Unpack
    const { writeStoryState: { story } } = writeStoryProps;
    const outlineArchitect = story.data?.outlineArchitect;
    const isLatestRound = roundIndex === outlineArchitect?.improvementAreas.length - 1
    const curQuestions = outlineArchitect?.improvementQuestions?.[roundIndex];
    const currentState = story?.states?.currentState;
    const nickname = story.creatorInfo?.personalInfo?.nickname ?? userInfo?.personalInfo?.nickname;

    const {
        currentAction,
        userAnswers,
        handleUserAnswerChange,
        onSendUserAnswer,
        revisedOutline,
        handleRevisedOutlineChange,
        handleRevisedOutlineBlur,
        onSendRevisedOutline,
        onClickHint,
    } = useImprovementRound({
        roundIndex, writeStoryProps, mode
    });

    const isReadonly = currentState !== 'inProgress' || !isLatestRound || mode === WriteStoryModes.VIEW;

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 2,
            }}
        >
            <DividingLine />

            <Typography
                variant='subtitle2'
                sx={{ width: '100%', textAlign: 'left', color: 'primary.contrastText', }}
            >
                {processNewLines(t('writeStory.oa.improvementQnAHeader'))}
            </Typography>

            {Array.isArray(curQuestions) &&
                curQuestions.map((qna, qnaIndex) => (
                    (qnaIndex === 0 || curQuestions[qnaIndex - 1]?.answered != null) // Not render if previous question is not answered
                        ?
                        <React.Fragment key={`qna-${qnaIndex}`}>
                            <DividingLine />

                            {/* Question from PUPU */}
                            <Box sx={{ width: '100%', paddingRight: '10%', boxSizing: 'border-box' }}>
                                <MessageBubble
                                    message={qna.question}
                                    username={t('puffNickname')}
                                    avatarImage={pupu_avatar}
                                    allowHint={!isReadonly && Array.isArray(qna?.examples) && qna?.examples.length > 0} // Allow display hint if qna.examples is an non-empty array
                                    hintText={t('writeStory.oa.forExample') + '\n' + qna?.examples?.[0]} // Default to display the first hint
                                    hintBtnText={qna.hintRevealedTimes ? t('writeStory.oa.hintBtnText') : `${t('writeStory.oa.hintBtnText')}x1`}
                                    hintBtnColor={qna.hintRevealedTimes ? 'colors.yellow' : 'primary.contrastText'}
                                    onClickHint={(reveal) => onClickHint(qnaIndex, reveal)}
                                />
                            </Box>

                            {qna?.rejectReason &&
                                <Box sx={{ width: '100%', paddingRight: '10%', boxSizing: 'border-box' }}>
                                    <MessageBubble
                                        message={qna.rejectReason}
                                        username={t('puffNickname')}
                                        avatarImage={pupu_avatar}
                                        bgColor={'background.gray'}
                                        iconName={'error'}
                                    />
                                </Box>
                            }

                            {/* User answer input */}
                            <Box sx={{ width: '100%', paddingLeft: '10%', boxSizing: 'border-box', display: 'flex', flexDirection: 'row', gap: '12px', position: 'relative' }}>
                                <CustomTextField
                                    value={userAnswers[qnaIndex]}
                                    ruledLines
                                    setValue={(event) => handleUserAnswerChange(qnaIndex, event.target.value)}
                                    disabled={currentAction === `checkImprovementAnswer_${qnaIndex}` || isReadonly || qna.answered}
                                    helperText={qna.skipped && <span>{t('writeStory.oa.questionSkipped')}</span>}
                                />
                                {qna.answered ?
                                    <>
                                        <StringAvatar name={nickname} />
                                        <Box
                                            sx={{
                                                border: '8px solid transparent',
                                                borderLeftColor: 'background.main300',
                                                position: 'absolute',
                                                top: '20px',
                                                right: `30px`,
                                                transform: 'translate(-50%, -50%)',
                                            }}
                                        />
                                    </>
                                    :
                                    <Box sx={{ width: '48px' }} />
                                }
                            </Box>

                            {/* Buttons */}
                            {!isReadonly && !qna.answered && !qna.skipped &&
                                <Box sx={{ width: { xs: '100%', sm: '80%' }, boxSizing: 'border-box', display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '10px' }}>
                                    <Box sx={{ flexGrow: 1, widht: '100%' }}>
                                        <StyledContainedButton variant='contained' disableRipple sx={{ width: '100%', px: 0 }}
                                            onClick={() => onSendUserAnswer({ qnaIndex, skipCheck: true })}
                                            disabled={currentAction !== null}
                                        >
                                            {currentAction === `skipUserAnswerCheck` &&
                                                <CircularProgress size={'20px'} sx={{ color: 'primary.contrastText', marginRight: 2 }} />
                                            }
                                            {t('writeStory.skipAnswer')}
                                        </StyledContainedButton>
                                    </Box>

                                    <Box sx={{ flexGrow: 10, widht: '100%' }}>
                                        <StyledContainedButton variant='contained' disableRipple sx={{ width: '100%' }}
                                            onClick={() => onSendUserAnswer({ qnaIndex })}
                                            disabled={(userAnswers.length <= qnaIndex || userAnswers[qnaIndex] === '' || currentAction !== null)}
                                        >
                                            {currentAction === `checkImprovementAnswer_${qnaIndex}` &&
                                                <CircularProgress size={'20px'} sx={{ color: 'primary.contrastText', marginRight: 2 }} />
                                            }
                                            {qna?.rejectReason ? t('writeStory.retryAnswer') : t('writeStory.confirmAnswer')}
                                        </StyledContainedButton>
                                    </Box>
                                </Box>
                            }
                        </React.Fragment>
                        :
                        null
                ))
            }

            <DividingLine />

            {['genImprovementQuestions', 'rebuildOutline', 'skipUserAnswerCheck'].includes(currentAction) &&
                <Box sx={{ width: '100%', paddingRight: '10%', boxSizing: 'border-box' }}>
                    <MessageBubble
                        message={t('writeStory.thinking')}
                        username={t('puffNickname')}
                        avatarImage={pupu_avatar}
                        bgColor={'colors.green'}
                        typing={true}
                    />
                </Box>
            }

            {/* Revised outline */}
            {revisedOutline != null &&
                <>
                    <CustomTextField
                        value={revisedOutline}
                        ruledLines
                        setValue={handleRevisedOutlineChange}
                        disabled={['rebuildOutline'].includes(currentAction) || isReadonly}
                        header={processNewLines(t('writeStory.oa.revisedOutlineHeader') + (isReadonly ? '' : t('writeStory.oa.revisedOutlinePromptUserToCheck')))}
                        onBlur={handleRevisedOutlineBlur}
                    />

                    {!isReadonly &&
                        <StyledContainedButton variant='contained' disableRipple sx={{ width: { xs: '96%', sm: '80%' }, marginTop: 3 }}
                            onClick={onSendRevisedOutline}
                            disabled={currentAction !== null || !revisedOutline}
                        >
                            {['rebuildOutline'].includes(currentAction) &&
                                <CircularProgress size={'20px'} sx={{ color: 'primary.contrastText', marginRight: 2 }} />
                            }
                            {t('writeStory.oa.generateSampleEssay')}
                        </StyledContainedButton>
                    }
                </>
            }
        </Box>
    )
};

export default ImprovementRound;
