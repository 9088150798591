import { Box, CircularProgress, Typography } from '@mui/material';
import React from 'react';
import { useStoryComposer } from './useStoryComposer';
import { StyledContainedButton } from '../../../../styles/StyledButtons';
import { useTranslation } from 'react-i18next';
import SampleEssayCard from './SampleEssayCard';
import { getBaseURL, processNewLines } from '../../../../utils/StringUtils';
import { maxNumberOfSampleEssays } from '../../../../configs/appConfig';
import ShareButton from '../../../Shared/ShareButton';
import RatingComponent from '../../../Shared/RatingComponent';
import DividingLine from '../../../Shared/DividingLine';
import { WriteStoryModes } from '../../storyUtils';
import { useAuthentication } from '../../../../hooks/useAuthentication';
import theme from '../../../../styles/theme';


const StoryComposer = ({ writeStoryProps, mode }) => {
    const { t } = useTranslation();
    const { userInfo } = useAuthentication();

    // Unpack
    const { writeStoryState: { story } } = writeStoryProps;
    const storyComposer = story.data?.storyComposer;
    const sampleStories = storyComposer?.sampleStories;
    const currentState = story?.states?.currentState;
    const allowReadSampleEssay = userInfo?.parentalControls?.allowReadSampleEssay || mode === WriteStoryModes.VIEW;

    const {
        currentAction,
        onGenSampleEssay,
        handleRateStory
    } = useStoryComposer({ writeStoryProps });

    const sampleEssayProps = { allowReadSampleEssay };
    const isReadonly = currentState !== 'completed' || mode === WriteStoryModes.VIEW;

    const shareStoryUrl = `${getBaseURL()}/read-story/a1/${story._id}`;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                gap: 3,
            }}
        >
            <DividingLine />

            <Typography
                variant='subtitle2'
                sx={{ width: '100%', textAlign: 'left', color: 'primary.contrastText', }}
            >
                {processNewLines(t('writeStory.a1.storyComposerHeader'))}
            </Typography>

            {Array.isArray(sampleStories) &&
                <>
                    {sampleStories.length > 0 &&
                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 2 }}>
                            {sampleStories.map((sampleStory, index) => (
                                <Box key={index}>
                                    <SampleEssayCard
                                        sampleStory={sampleStory}
                                        loading={currentAction === `genIllustrationPrompt_${index}`}
                                        sampleEssayProps={sampleEssayProps}
                                        shareURL={shareStoryUrl + `/?sample-index=${index}`}
                                    />
                                </Box>
                            ))}

                            <Typography variant='subtitle2' sx={{ color: 'text.secondary', mt: -1 }}>
                                {t('writeStory.a1.sampleStoriesGrid')}
                            </Typography>
                        </Box>
                    }

                    {!isReadonly && sampleStories.length < maxNumberOfSampleEssays &&
                        <>
                            <StyledContainedButton variant="contained" sx={{ width: { xs: '96%', sm: '80%' } }}
                                onClick={() => onGenSampleEssay({ index: sampleStories.length })}
                                disabled={currentAction !== null || sampleStories.length === maxNumberOfSampleEssays}
                            >
                                {currentAction &&
                                    <CircularProgress size={'20px'} sx={{ color: 'primary.contrastText', marginRight: 2 }} />
                                }
                                {currentAction ?
                                    t('writeStory.a1.generating')
                                    :
                                    t('writeStory.a1.generateSampleEssay')
                                }
                            </StyledContainedButton>
                            <Typography variant='subtitle2' sx={{ color: 'text.secondary', mt: '-13px' }}>
                                {t('writeStory.a1.numSampleEssayGenRemain', { chances: (maxNumberOfSampleEssays - sampleStories.length) })}
                            </Typography>
                        </>
                    }

                    {!isReadonly && sampleStories.length > 0 &&
                        <>
                            <DividingLine />

                            <Box sx={{ width: { xs: '96%', sm: '80%' }, gap: 3, display: 'flex', flexDirection: 'column' }}>
                                <ShareButton
                                    btnText={t('writeStory.a1.shareStory')}
                                    shareUrl={shareStoryUrl}
                                    shareTitle={story.metadata.title}
                                    sx={{ width: '100%' }}
                                />

                                {/* NPS rating */}
                                {story?.userFeedback?.nps == null && story?.origin !== 'sample' &&
                                    <RatingComponent
                                        headerText={t('userFeedback.pleaseRate')}
                                        onRate={(rate) => handleRateStory(rate, 'nps')}
                                        rate={story?.userFeedback?.nps}
                                        noteText={{
                                            rate0: t('userFeedback.rate0'),
                                            rate10: t('userFeedback.rate10')
                                        }}
                                        sx={{
                                            padding: '40px',
                                            boxSizing: 'border-box',
                                            borderRadius: theme.custom.radius.medium,
                                            backgroundColor: 'background.main300'
                                        }}
                                    />
                                }
                            </Box>
                        </>
                    }
                </>
            }
        </Box >
    );
}

export default StoryComposer;
