import { useContext } from 'react';
import { CommonUtilitiesContext } from '../contexts/CommonUtilitiesContext';

const useCommonUtilities = () => {
    const context = useContext(CommonUtilitiesContext);

    if (!context) {
        throw new Error('useCommonUtilities must be used within a CommonUtilitiesProvider');
    }

    return context;
};

export default useCommonUtilities;
