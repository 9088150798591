import React, { useState } from 'react';
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import StringAvatar from './StringAvatar';
import { useTranslation } from 'react-i18next';
import { AccountCircleRounded, ExitToAppRounded } from '@mui/icons-material';
import useCommonUtilities from '../../hooks/useCommonUtilities';


const UserMenu = ({ userInfo, handleLogout }) => {
    const { t } = useTranslation();
    const { setAccountModalOpen } = useCommonUtilities();

    // User Menu
    const [anchorElUser, setAnchorElUser] = useState(null);
    const userMenuSettings = [
        { id: 'account', icon: <AccountCircleRounded /> },
        { id: 'logout', icon: <ExitToAppRounded /> }
    ]; const nickname = userInfo?.personalInfo?.nickname;

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = (clickedSetting) => {
        setAnchorElUser(null);
        const isSettingIncluded = userMenuSettings.some(setting => setting.id === clickedSetting);

        if (typeof clickedSetting === 'string' && isSettingIncluded) {
            switch (clickedSetting) {
                case 'account':
                    setAccountModalOpen(true);
                    return;
                case 'logout':
                    handleLogout();
                    return;
                default:
                    return;
            }
        };
    };

    return (
        <React.Fragment>
            <Box sx={{ flexGrow: 0 }}>
                <IconButton disableRipple onClick={handleOpenUserMenu}>
                    <StringAvatar name={nickname} />
                </IconButton>

                <Menu
                    sx={{ mt: '50px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={() => handleCloseUserMenu()}
                >
                    {userMenuSettings.map((setting) => (
                        <MenuItem key={setting.id} onClick={() => handleCloseUserMenu(setting.id)}>
                            {setting.icon}
                            <Typography textAlign="center" sx={{ ml: 1 }}>{t(`userMenu.${setting.id}`)}</Typography>
                        </MenuItem>
                    ))}
                </Menu>
            </Box>
        </React.Fragment>
    );
};

export default UserMenu;
