import { Avatar, Box, IconButton, Typography } from "@mui/material";
import { CheckCircleOutlineRounded, ErrorRounded, LightbulbRounded, TipsAndUpdatesRounded } from "@mui/icons-material";
import { useState } from "react";
import { processNewLines } from "../../utils/StringUtils";
import '../../styles/animations.css'
import theme from "../../styles/theme";


const MessageBubble = ({
    message,
    username,
    avatarImage,
    quoteText,
    hintText,
    iconName,
    bgColor = 'colors.green',
    textColor = 'text.primary',
    tailLeft = true,
    allowHint = false,
    hintBtnText,
    hintBtnColor =  'primary.contrastText',
    onClickHint,
    typing = false,
    usernameFontSize = '10pt',
    messageFontSize = '11pt',
    sx = {},
}) => {
    const IconSwitcher = ({ iconName }) => {
        switch (iconName) {
            case 'check':
                return <CheckCircleOutlineRounded sx={{ color: 'green', fontSize: '20px' }} />;
            case 'error':
                return <ErrorRounded sx={{ color: 'red', fontSize: '20px' }} />;
            default:
                return null;
        }
    };

    const messageBubbleStyle = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        position: 'relative',
        height: 'auto',
        backgroundColor: bgColor,
        borderRadius: theme.custom.radius.small,
        '::before': tailLeft ? {
            content: '""',
            position: 'absolute',
            top: '14px',
            left: 0,
            border: '8px solid transparent',
            borderRightColor: bgColor,
            transform: 'translateX(-100%)'
        } : {
            content: '""',
            position: 'absolute',
            top: '14px',
            right: 0,
            border: '8px solid transparent',
            borderLeftColor: bgColor,
            transform: 'translateX(100%)'
        }
    };

    const [revealHint, setRevealHint] = useState(false);

    return (
        <Box
            sx={{ display: 'flex', alignItems: 'flex-start', width: '100%', direction: tailLeft ? 'ltr' : 'rtl', ...sx, }}
        >
            {avatarImage &&
                <Box sx={{ position: 'relative' }}>
                    <Avatar src={avatarImage} sx={{ mr: 2, mt: username ? 2 : 0 }} />
                    <Box sx={{ marginRight: 1, display: 'flex', alignItems: 'center', position: 'absolute', bottom: '-6px', right: 0 }}>
                        <IconSwitcher iconName={iconName} />
                    </Box>
                </Box>
            }

            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', }}>
                {username &&
                    <Typography variant="body2" sx={{ color: 'text.secondary', fontSize: usernameFontSize }}>
                        {username}
                    </Typography>
                }

                {/* Message Bubble */}
                <Box sx={messageBubbleStyle}>
                    <Box
                        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', width: '100%' }}
                    >
                        {message &&
                            <Typography
                                variant="subtitle2"
                                sx={{
                                    width: '100%',
                                    display: 'block',
                                    alignItems: 'center',
                                    textAlign: 'left',
                                    fontSize: messageFontSize,
                                    color: textColor,
                                    padding: '10px 15px',
                                }}
                            >
                                {processNewLines(message)}
                                {typing && <span className="typing-animation"></span>}
                            </Typography>
                        }

                        {revealHint &&
                            <Typography
                                variant="subtitle2"
                                sx={{
                                    width: '100%',
                                    display: 'block',
                                    alignItems: 'center',
                                    textAlign: 'left',
                                    fontSize: messageFontSize,
                                    color: textColor,
                                    backgroundColor: 'background.gray',
                                    padding: '10px 15px',
                                    borderTopLeftRadius: 0,
                                    borderTopRightRadius: 0,
                                    borderBottomLeftRadius: theme.custom.radius.small,
                                    borderBottomRightRadius: theme.custom.radius.small,
                                }}
                            >
                                {processNewLines(hintText)}
                            </Typography>
                        }

                        {quoteText &&
                            <Typography
                                variant="subtitle2"
                                sx={{
                                    width: '100%',
                                    textAlign: 'left',
                                    display: '-webkit-box',
                                    overflow: 'hidden',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 2, // Limit to two lines
                                    marginTop: '8px',
                                    fontStyle: 'italic',
                                    fontSize: '10pt',
                                    textOverflow: 'ellipsis',
                                    color: 'text.secondary',
                                }}
                            >
                                {processNewLines(quoteText)}
                            </Typography>
                        }
                    </Box>

                    {allowHint &&
                        <IconButton
                            disableRipple
                            sx={{
                                backgroundColor: 'transparent',
                                color: hintBtnColor,
                                position: 'absolute',
                                top: '-4px',
                                right: '-48px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                            onClick={() => {
                                if (typeof onClickHint === 'function') {
                                    onClickHint(!revealHint);
                                }
                                setRevealHint(!revealHint);

                            }}
                        >
                            {revealHint ? <TipsAndUpdatesRounded fontSize="medium" /> : <LightbulbRounded fontSize="medium" />}
                            {hintBtnText &&
                                <Typography
                                    variant="caption"
                                    sx={{
                                        fontWeight: 'bold',
                                        color: 'primary.contrastText',
                                        fontSize: '0.7rem',
                                        whiteSpace: 'nowrap',
                                        width: 'auto',
                                    }}
                                >
                                    {hintBtnText}
                                </Typography>
                            }
                        </IconButton>
                    }
                </Box>
            </Box>
        </Box>
    );
};

export default MessageBubble;
