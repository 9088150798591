export const isValidEmail = (email, allowCheat = false) => {
    // Special case, for admin use
    if (email.startsWith("@@") && allowCheat) {
        return true;
    }

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
};


export function isValidChineseMobile(mobile, allowCheat = false) {
    if (mobile.startsWith("@@") && allowCheat) {
        return true;
    }
    const pattern = /^1[3456789]\d{9}$/;
    return pattern.test(mobile);
};
