import { Container, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { WriteStoryModes } from "../storyUtils";
import ActionMenu from "../ActionMenu";
import IdeaIntake from "./IdeaIntake/IdeaIntake";
import CopilotSelectionPanel from "../../PupuCopilot/SelectionPanel";
import OutlineArchitect from "../OutlineArchitect/OutlineArchitect";
import StoryComposer from "./StoryComposer/StoryComposer";


const A1WriteEssayContent = ({ writeStoryProps, mode }) => {
    const { t } = useTranslation();

    // Unpack
    const { writeStoryState: { story } } = writeStoryProps;
    const currentState = story?.states?.currentState;

    useEffect(() => {
        if (!story?.metadata) { return; }

        let pageTitle = t('puffLearn');

        if (currentState === 'completed') {
            const { title } = story.metadata;

            if (typeof title === 'string' && title.length > 0) {
                pageTitle += (' - ' + title);
            }
        }

        document.title = pageTitle;
    }, [currentState, t, story?.metadata])

    return (
        <Container maxWidth='sm'
            sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                boxSizing: 'border-box',
                gap: 4,
            }}
        >
            <Typography variant='h5' fontSize={28}
                sx={{
                    color: 'primary.contrastText',
                    fontWeight: 'bold',
                }}>
                {t('writeStory.a1.pageHeader')}
            </Typography>

            {story?._id != null && mode === WriteStoryModes.CREATE &&
                <ActionMenu writeStoryProps={writeStoryProps}
                    sx={{ mt: -7, width: '100%', display: 'flex', justifyContent: 'flex-end' }} />
            }

            {story &&
                <>
                    {(['initial', 'inProgress', 'completed'].includes(currentState)) &&
                        <IdeaIntake writeStoryProps={writeStoryProps} mode={mode} />
                    }

                    {(['inProgress', 'completed'].includes(currentState)) &&
                        (story.data?.outlineArchitect) &&
                        <OutlineArchitect writeStoryProps={writeStoryProps} mode={mode} />
                    }

                    {(['completed'].includes(currentState)) &&
                        (story.data?.storyComposer) &&
                        <StoryComposer writeStoryProps={writeStoryProps} mode={mode} />
                    }

                    <CopilotSelectionPanel
                        writeStoryProps={writeStoryProps}
                        showHeader={currentState === 'initial'}
                        allowSelectCopilot={currentState === 'initial'}
                        mode={mode}
                    />
                </>
            }
        </Container>
    )
};

export default A1WriteEssayContent;