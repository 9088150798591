import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import CustomTextField from "../../Shared/CustomTextField";
import { useTranslation } from "react-i18next";
import { StyledContainedButton } from "../../../styles/StyledButtons";
import { PCardColorMapping, PCardIconMapping, availablePCardTypes } from "../../Shared/PCard/PCardUtils";
import { useTheme } from "@emotion/react";
import useIdeaIntakeC1 from "./useIdeaIntakeC1";
import { WriteStoryModes } from "../storyUtils";


const IdeaIntakeC1 = ({ writeStoryProps, mode }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { writeStoryState: { story } } = writeStoryProps;
    const currentState = story?.states?.currentState;

    const {
        currentAction,
        userIdea,
        handleUserIdeaChange,
        selectedCardCategory,
        setSelectedCardCategory,
    } = useIdeaIntakeC1(writeStoryProps);

    const isReadonly = currentState !== 'initial' || mode === WriteStoryModes.VIEW;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '100%',
                boxSizing: 'border-box',
                gap: 3,
                color: 'primary.contrastText',
            }}
        >
            <Typography variant="subtitle2"
                sx={{ width: '100%', textAlign: 'left', ml: '13px' }}
            >
                {t(`writeStory.c1.topicHeader`)}
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: { xs: '90%', sm: '74%' } }}>
                {availablePCardTypes.map((type) => {
                    const Icon = PCardIconMapping[type];
                    return (
                        <StyledContainedButton
                            variant="contained"
                            disableRipple
                            key={type}
                            onClick={() => setSelectedCardCategory(type)}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                padding: '10px 20px',
                                boxSizing: 'border-box',
                                gap: 1,
                                backgroundColor: selectedCardCategory === type ? PCardColorMapping[type] : 'primary.main',
                                borderRadius: theme.custom.radius.small,
                                '&:hover': {
                                    backgroundColor: selectedCardCategory === type ? PCardColorMapping[type] : 'primary.light',
                                },
                            }}
                        >
                            <Icon sx={{ fontSize: '40px' }} />
                            <Typography variant="subtitle2">{t(`PCard.${type}`)}</Typography>
                        </StyledContainedButton>
                    );
                })}
            </Box>

            <CustomTextField
                header={t('writeStory.c1.outlineHeader')}
                disabled={currentAction}
                placeholder={t('writeStory.c1.outlinePlaceholder')}
                value={userIdea}
                ruledLines
                setValue={handleUserIdeaChange}
                readonly={isReadonly}
            />

            {currentState === 'initial' &&
                <StyledContainedButton variant='contained' sx={{ width: { xs: '96%', sm: '80%' } }}
                    // onClick={runIdeaIntake}
                    disabled={currentAction !== null || !userIdea}
                >
                    {currentAction === null ?
                        t('writeStory.c1.ideaIntakeBtn') :
                        <>
                            <CircularProgress size={'20px'} sx={{ color: 'primary.contrastText', marginRight: 2 }} />
                            {t(`writeStory.actions.${currentAction}`)}
                        </>
                    }
                </StyledContainedButton>
            }
        </Box>
    );
};

export default IdeaIntakeC1;
