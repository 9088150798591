import { useState, useCallback, useEffect } from 'react';
import { runPromptsForTextCompletions } from '../../utils/promptUtils';
import { useAuthentication } from '../../hooks/useAuthentication';
import useCommonUtilities from '../../hooks/useCommonUtilities';
import { supportedCopilotTypes } from './copilotUtils';


export const usePupuCopilot = ({ writeStoryState, dispatchAndUpdateStory }) => {
    const { token, fetchUserInfo } = useAuthentication();
    const { lang, verboseSetting } = useCommonUtilities();

    // Unpack
    const { story } = writeStoryState;
    const prompts = story?.prompts;
    const copilot = story?.data?.copilot;

    // States
    const [requestQueue, setRequestQueue] = useState([]);
    const [copilotProcessing, setCopilotProcessing] = useState(null);
    const [availableCopilots, setAvailableCopilots] = useState([]);
    const [selectedCopilots, setSelectedCopilots] = useState([]);

    // const [copilotProcessing, setCopilotProcessing] = useState({ action: 'getCopilotAnswers', key: 'outlineArchitect_0_0', copilotTypes: ['思路老师', '拓展老师'] });

    // Determine which copilots are availabe in prompts
    useEffect(() => {
        if (!prompts) { return; }
        const regex = /^getCopilotResponse_(.+)$/;
        const foundCopilots = [];

        Object.keys(prompts).forEach(key => {
            const match = key.match(regex);
            if (match) {
                const type = match[1];
                if (supportedCopilotTypes.includes(type)) {
                    foundCopilots.push(type);
                }
            }
        });
        setAvailableCopilots(foundCopilots);
        setSelectedCopilots(foundCopilots.slice(0, 4));     // Initially select first copilots found
    }, [prompts])


    const getCopilotTypes = useCallback(async (request, interimState = {}, verbose = verboseSetting) => {
        const action = 'getCopilotTypes';
        setCopilotProcessing({ action, key: request.key, copilotTypes: [] });

        const getCopilotTypeResult = await runPromptsForTextCompletions({
            action,
            doc: writeStoryState.story,
            state: writeStoryState,
            interimState,
            lang,
            token,
            verbose,
            mock: false,
            docID: request.docID,
        });

        return getCopilotTypeResult['1_result']['copilotTypes'];

    }, [token, lang, writeStoryState, verboseSetting]);

    const getCopilotResponse = useCallback(async (request, interimState = {}, copilotType, verbose = verboseSetting) => {
        // Generate copilot response
        const action = `getCopilotResponse_${copilotType}`;

        const getCopilotResponseResult = await runPromptsForTextCompletions({
            action,
            doc: writeStoryState.story,
            state: writeStoryState,
            interimState,
            lang,
            token,
            verbose,
            mock: false,
            docID: request.docID,
        });

        const copilotResponse = getCopilotResponseResult['1_result']['response'];

        return {
            copilotResponse: copilotResponse,
            copilotType: copilotType,
            userInput: request.userInput,
            gradeLevel: request.gradeLevel,
            key: request.key
        };

    }, [token, lang, writeStoryState, verboseSetting]);

    // Add copilot request to queue
    const triggerCopilotResponse = useCallback((request) => {
        setRequestQueue(prevQueue => [...prevQueue, request]);
    }, []);

    const processNextRequest = useCallback(async () => {
        if (requestQueue.length > 0 && copilotProcessing === null) {

            const nextRequest = requestQueue[0];
            if (verboseSetting > 1) { console.debug(`${requestQueue.length} requests queued. Start processing ${nextRequest.key}`) }

            try {
                const interimState = {
                    'temp.userInput': nextRequest.userInput,
                    'temp.userGradeLevel': nextRequest.gradeLevel,
                };

                // Determine which types of copilot to run
                const selectedCopilotTypes = await getCopilotTypes(nextRequest, interimState);
                const _id = nextRequest.docID ?? writeStoryState.story?._id;
                const copilotPromises = [];

                const copilotTypes = selectedCopilotTypes.slice(0, 2)
                setCopilotProcessing({ action: 'getCopilotAnswers', key: nextRequest.key, copilotTypes: copilotTypes });

                for (const copilotType of copilotTypes) {   // Use at most two tools per trigger.
                    if (supportedCopilotTypes.includes(copilotType) === false) {
                        console.warn(`Invalid copilot type: ${copilotType}`);
                        continue;
                    }
                    const copilotPromise = getCopilotResponse(nextRequest, interimState, copilotType)
                        .then((response) => {
                            dispatchAndUpdateStory({
                                _id, updateObject: {
                                    [`data.copilot.messagesArray`]: response,
                                }, method: 'append', verbose: verboseSetting
                            });

                            const prevNewMsgCount = Number(copilot?.numNewMessages?.[copilotType] || 0);
                            dispatchAndUpdateStory({
                                _id, updateObject: {
                                    [`data.copilot.numNewMessages.${copilotType}`]: prevNewMsgCount + 1,
                                }, method: 'write', verbose: verboseSetting
                            });
                        }
                        ).catch(error => {
                            console.error('Error with getting copilot responses:', error);
                        });

                    copilotPromises.push(copilotPromise);
                }
                await Promise.all(copilotPromises);

                setRequestQueue(prevQueue => prevQueue.slice(1));
            } catch (error) {
                console.error("Error generating copilot response:", error);
            } finally {
                setCopilotProcessing(null);
                fetchUserInfo().catch(console.error);
            };
        }
    }, [requestQueue, copilotProcessing, getCopilotTypes, getCopilotResponse, fetchUserInfo, verboseSetting, dispatchAndUpdateStory, writeStoryState.story, copilot?.numNewMessages]);

    // Automatically process the next request in the queue when the queue or processing state changes
    useEffect(() => {
        processNextRequest();
    }, [requestQueue, copilotProcessing, processNextRequest]);

    return {
        availableCopilots,
        selectedCopilots,
        setSelectedCopilots,
        triggerCopilotResponse,
        copilotProcessing,
    };
};
