import { Avatar, Badge, Box, Container, IconButton, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { copilotAvatarMapping } from "./copilotUtils";
import { PersonAddAlt1Rounded } from "@mui/icons-material";
import useCommonUtilities from "../../hooks/useCommonUtilities";
import happy_cloud from '../../assets/happy_cloud.png';
import { WriteStoryModes } from "../WriteStoryPage/storyUtils";
import CopilotMessagePopup from "./MessagePopup";
import { zIndexValues } from "../../styles/zIndexValues";
import CustomModal from "../Shared/CustomModal";
import theme from "../../styles/theme";


const CopilotSelectionPanel = ({ writeStoryProps, showHeader, allowSelectCopilot, mode }) => {
    const { t } = useTranslation();
    const { openDialog } = useCommonUtilities();

    // Unpack
    const { writeStoryState: { story }, dispatchAndUpdateStory, copilotManager } = writeStoryProps;
    const copilot = story?.data?.copilot;
    const messagesArray = copilot?.messagesArray;
    const { selectedCopilots, copilotProcessing } = copilotManager;

    // States
    const [tappedCopilot, setTappedCopilot] = useState(null);
    const [tappedPos, setTappedPos] = useState(null);
    const avatarRefs = useRef(Array.isArray(selectedCopilots) ? selectedCopilots.map(() => React.createRef()) : null);

    // Handlers
    const handleTapCopilotAvatar = (index, copilotName) => {
        if (mode === WriteStoryModes.CREATE) {
            if (copilot?.numNewMessages?.[copilotName] > 0) {
                dispatchAndUpdateStory({
                    _id: story._id, updateObject: {
                        [`data.copilot.numNewMessages.${copilotName}`]: 0
                    }
                })
            }
        }
        if (copilotName === tappedCopilot) {
            setTappedPos(null);
            setTappedCopilot(null);
        } else {
            const avatarRef = avatarRefs.current[index].current;
            const { left, width } = avatarRef.getBoundingClientRect();
            setTappedPos({ left: left + width / 2 });
            setTappedCopilot(copilotName);
        }
    };

    const handleLikeCopilotMsg = (message) => {
        const messageIndex = messagesArray.findIndex(({ key, copilotType }) => {
            return key === message.key && copilotType === message.copilotType;
        });
        const prevLiked = Boolean(copilot?.messagesArray?.[messageIndex]?.liked);
        dispatchAndUpdateStory({
            _id: story._id, updateObject: {
                [`data.copilot.messagesArray.${messageIndex}.liked`]: !prevLiked
            }
        });
    }

    const handleMoreCopilots = () => {
        openDialog({
            content: "添加助手的功能将在近期上线！敬请期待！",
            image: (
                <>
                    <img src={happy_cloud} alt="happy_cloud" style={{ maxHeight: '90px', height: 'auto' }} />
                </>
            ),
        });
    };


    return (
        <Box sx={{
            zIndex: zIndexValues.modal, display: 'flex', flexDirection: 'column', position: 'fixed', bottom: 0, width: '100%',
            background: {
                'xs': theme.palette.primary.light,
                'sm': tappedCopilot === null ? 'linear-gradient(0deg, rgba(240, 240, 235, 1) 70%, rgba(240, 240, 235, 0) 100%)' : theme.palette.background.main
            },
        }}>
            <Container maxWidth='sm' sx={{ display: 'flex', justifyContent: 'center', zIndex: 3 }}>
                {showHeader &&
                    <Typography variant='subtitle2' sx={{ width: '100%', maxWidth: 'sm', textAlign: 'left', pl: '32px', color: 'primary.contrastText', position: 'absolute', top: '-22px' }}>
                        {t('copilot.selectionPanelHeader')}
                    </Typography>
                }

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    gap: { xs: 1, sm: 4 },
                    color: 'primary.contrastText',
                    paddingTop: '5px',
                    paddingBottom: '10px',
                    paddingX: { xs: '2px', sm: '20px' },
                    maxWidth: '460px',
                    boxSizing: 'border-box',
                    overflowX: 'auto',
                    flexGrow: 1,
                }}>
                    {selectedCopilots.map((copilotName, index) => (
                        <Box
                            key={index}
                            ref={avatarRefs.current[index]}
                            sx={{
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: '4px',
                                cursor: 'pointer',
                            }}
                            onClick={() => handleTapCopilotAvatar(index, copilotName)}
                        >
                            <Badge
                                color="error"
                                invisible={mode === WriteStoryModes.VIEW}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                overlap="circular"
                                badgeContent={copilot?.numNewMessages?.[copilotName]}
                            >
                                <Avatar src={copilotAvatarMapping[copilotName]} sx={{ width: '40px', height: '40px' }} />
                            </Badge>
                            <Typography variant='subtitle2' sx={{ whiteSpace: 'nowrap', width: 'auto' }}>{copilotName}</Typography>

                            {Array.isArray(copilotProcessing?.copilotTypes) && copilotProcessing?.copilotTypes.includes(copilotName) &&
                                <AvatarTypingBubble />
                            }
                        </Box>
                    ))}

                    {allowSelectCopilot &&
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: '4px',
                            }}
                        >
                            <IconButton sx={{ width: '40px', height: '40px', backgroundColor: 'background.gray600' }} disableRipple onClick={handleMoreCopilots}>
                                <PersonAddAlt1Rounded />
                            </IconButton>
                            <Typography variant='subtitle2' sx={{ whiteSpace: 'nowrap', width: 'auto' }}>更多助手</Typography>
                        </Box>
                    }
                </Box>
            </Container>

            <CustomModal
                isOpen={tappedCopilot !== null}
                onClose={() => { setTappedCopilot(null); setTappedPos(null); }}
                modalStyle={{
                    maxHeight: { sm: '75vh', xs: '65vh' },
                    top: 'auto',
                    bottom: '100px',
                    left: '50%',
                    transform: 'translate(-50%, -0%)',
                }}
                maxWidth={'sm'}
                zIndex={zIndexValues.modal - 1}
            >
                <CopilotMessagePopup
                    tappedCopilot={tappedCopilot}
                    messagesArray={messagesArray}
                    showIntro={true}
                    allowLike={mode === WriteStoryModes.CREATE}
                    toggleLike={handleLikeCopilotMsg}
                />
            </CustomModal>

            {tappedPos &&
                <Box
                    sx={{
                        border: '20px solid transparent',
                        borderTopColor: 'primary.dark',
                        position: 'fixed',
                        bottom: '40px',
                        left: `${tappedPos.left}px`,
                        transform: 'translate(-50%, -50%)',
                    }}
                />
            }
        </Box >
    );
};

export default CopilotSelectionPanel;


const AvatarTypingBubble = () => (
    <Box
        sx={{
            position: 'absolute',
            bottom: '24px',
            left: '44px',
            backgroundColor: {xs: 'background.main600', sm: 'background.gray600'},
            minWidth: '26px',
            height: '16px',
            borderRadius: '10px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            pb: 1,
            pl: '7px'
        }}
    >
        <Typography sx={{ fontWeight: 900, }}>
            <span className="typing-animation"></span>
        </Typography>
    </Box>
);