import { useState } from 'react';

const usePromptDialog = ({ onDialogClose }) => {
    const [promptDialogOpen, setPromptDialogOpen] = useState(false);

    const handlePromptDialogOpen = () => setPromptDialogOpen(true);

    const handlePromptDialogClose = ({ newPrompts }) => {
        setPromptDialogOpen(false);
        if (newPrompts && typeof(onDialogClose) === 'function') {
            onDialogClose(newPrompts);
        }
    };

    return {
        promptDialogOpen,
        handlePromptDialogOpen,
        handlePromptDialogClose
    };
};

export default usePromptDialog;
