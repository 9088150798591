export const defaultUserTag = 'betaUser';

export const companyName = 'Puff Wonderland';

export const companyNameZH = '上海噗噗噗教育科技有限公司';
export const ICPNumber = '沪ICP备2024056296号-1';

export const defaultLang = 'zh';  // Default language
export const supportedLangs = ['zh',];  // Supported languages
export const languageNames = {
    zh: '简体中文',
    en: 'English',
};

export const maxCardToSelect = 3;

export const maxNumberOfSampleEssays = 3;

export const wechatAppID = "wxd9b0d26363f15662";