import FaceRounded from '@mui/icons-material/FaceRounded';
import LandscapeRounded from '@mui/icons-material/LandscapeRounded';
import AutoFixHighRounded from '@mui/icons-material/AutoFixHighRounded';
import AutoStoriesRounded from '@mui/icons-material/AutoStoriesRounded';
import { CameraRounded, KeyRounded, StarBorderRounded } from '@mui/icons-material';
import { IconButton } from '@mui/material';


export function getPCardColor({ collection, cardDisabled = false, rgba = false }) {
    if (cardDisabled === true) {
        return 'background.entities.disabled';
    }
    switch (collection) {
        case 'location':
            return rgba ? 'rgba(237, 230, 179, 1)' : 'background.entities.locations';
        case 'keyItem':
            return rgba ? 'rgba(243, 225, 214, 1)' : 'background.entities.keyItems';
        case 'character':
            return rgba ? 'rgba(213, 242, 242, 1)' : 'background.entities.characters';
        default:
            return rgba ? 'rgba(221, 223, 216, 1)' : 'background.gray';
    }
};

export function getCardIcon({ collection, size = '28px', color = 'text.secondary' }) {
    let icon;
    switch (collection) {
        case 'character':
            icon = <FaceRounded sx={{ fontSize: `calc(${size} * 0.8)`, color }} />;
            break;
        case 'location':
            icon = <LandscapeRounded sx={{ fontSize: `calc(${size} * 0.8)`, color }} />;
            break;
        case 'keyItem':
            icon = <AutoFixHighRounded sx={{ fontSize: `calc(${size} * 0.8)`, color }} />;
            break;
        case 'story':
            icon = <AutoStoriesRounded sx={{ fontSize: `calc(${size} * 0.8)`, color }} />;
            break;
        case 'wildcard':
            icon = <StarBorderRounded sx={{ fontSize: `calc(${size} * 0.8)`, color }} />;
            break;
        default:
            return null;
    }

    return (
        <IconButton
            disableRipple
            sx={{
                position: 'absolute',
                top: 2,
                left: 2,
                color: 'text.secondary',
                backgroundColor: 'background.main300',
                '&:hover': {
                    backgroundColor: 'background.main300',
                },
                borderRadius: '50%',
                width: size,
                height: size,
                boxShadow: 2,
                cursor: 'default',
            }}
        >
            {icon}
        </IconButton>
    );
};

export const availablePCardTypes = ['character', 'location', 'keyItem', 'event'];

export const PCardIconMapping = {
    'character': FaceRounded,
    'location': LandscapeRounded,
    'keyItem': KeyRounded,
    'event': CameraRounded
};

export const PCardColorMapping = {
    'character': 'rgba(246, 239, 186, 1)',  // #F6EFBA
    'location': 'rgba(213, 242, 242, 1)',   // #D5F2F2
    'keyItem': 'rgba(245, 206, 208, 1)',    // #F5CED0
    'event': 'rgba(212, 243, 205, 1)',      // #D4F3CD
};

