import React from "react";
import theme from "../../../styles/theme";
import { Box, Typography } from "@mui/material";
import CoverImageDisplay from "../CoverImageDisplay";
import { processNewLines } from "../../../utils/StringUtils";
// import CardIcon from "../CardIcon";
// import { AutoStoriesRounded } from "@mui/icons-material";


const EssayCard = ({ metadata, onClick, loading }) => {

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                position: 'relative',
                boxSizing: 'border-box',
                width: '100%',
                maxWidth: '660px',
                padding: '6px',
                paddingRight: '10px',
                color: 'text.primary',
                backgroundColor: 'background.main800',
                boxShadow: theme.custom.shadow.small,
                borderRadius: theme.custom.radius.xsmall,
                cursor: 'pointer',
                marginX: 'auto',
            }}
            onClick={() => {
                // if (loading) { return };
                onClick();
            }}
        >
            <CoverImageDisplay
                coverImages={metadata.coverImages}
                size={{ xs: '160px', sm: '200px' }}
                loading={loading}
            />

            {/* <CardIcon 
                Icon={AutoStoriesRounded}
            /> */}

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    marginLeft: {
                        xs: 2,
                        sm: 3,
                    },
                }}
            >
                <Typography
                    variant="h6"
                    sx={{
                        fontSize: '1.15em',
                        textAlign: 'start',
                        width: '100%',
                        color: 'text.primary',
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitLineClamp: 1,
                        WebkitBoxOrient: 'vertical',
                    }}
                >
                    {processNewLines(metadata?.title)}
                </Typography>

                <Typography
                    variant='body2'
                    sx={{
                        textAlign: 'start',
                        width: '100%',
                        color: 'text.secondary',
                        fontSize: '1em',
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        borderTop: 1,
                        borderColor: 'primary.dark',
                    }}
                >
                    {processNewLines(metadata?.description)}
                </Typography>
            </Box>
        </Box>
    );
};

export default EssayCard;
