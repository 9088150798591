import { Box } from '@mui/material';
import React from 'react';
import ImprovementRound from './ImprovementRound';


const OutlineArchitect = ({ writeStoryProps, mode }) => {
    const { writeStoryState: { story } } = writeStoryProps;
    const improvementAreas = story.data?.outlineArchitect?.improvementAreas;
    
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '100%',
                marginTop: '20px',
                boxSizing: 'border-box',
                gap: 2,
                color: 'text.primary',
            }}
        >
            {Array.isArray(improvementAreas) &&
                improvementAreas.map((_, index) => (
                    <ImprovementRound
                        key={index}
                        index={index}
                        writeStoryProps={writeStoryProps}
                        mode={mode}
                    />
                ))
            }
        </Box>
    );
};

export default OutlineArchitect;
