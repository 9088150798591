import { Avatar, Box, IconButton, Typography } from "@mui/material";
import React from "react";
import theme from "../../styles/theme";
import { FavoriteBorderRounded, FavoriteRounded } from "@mui/icons-material";
import { copilotAvatarMapping, copilotIntroMapping, formatCopilotResponse } from "./copilotUtils";
import { processNewLines, renderFormattedParagraphs } from "../../utils/StringUtils";


const CopilotMessagePopup = ({ tappedCopilot, messagesArray, showIntro, allowLike, toggleLike }) => {
    const filteredMessages = Array.isArray(messagesArray) ? messagesArray.filter(item => item.copilotType === tappedCopilot) : [];

    return (
        <React.Fragment>
            {tappedCopilot != null && showIntro &&
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '20px',
                        pr: 2
                    }}
                >
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '4px',
                        minWidth: '80px',
                    }}>
                        <Avatar src={copilotAvatarMapping[tappedCopilot]} />
                        <Typography variant='subtitle2'>{tappedCopilot}</Typography>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexGrow: 1,
                    }}>
                        <Typography sx={{ textAlign: 'left', width: '100%' }}>{copilotIntroMapping[tappedCopilot]}</Typography>
                    </Box>
                </Box>
            }

            {tappedCopilot != null && filteredMessages.length > 0 &&
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 9,
                    mt: 9,
                    color: 'text.primary'
                }}>
                    {filteredMessages.map((message, index) => (
                        <Box key={index}
                            sx={{
                                width: '100%',
                                boxSizing: 'border-box',
                                backgroundColor: 'background.main',
                                borderRadius: theme.custom.radius.small,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    mt: -5,
                                    mb: 2,
                                    gap: 1,
                                    backgroundColor: 'background.gray',
                                    borderRadius: theme.custom.radius.xsmall,
                                    py: 1,
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <Typography
                                    variant="subtitle2"
                                    sx={{
                                        width: '100%',
                                        textAlign: 'left',
                                        display: '-webkit-box',
                                        overflow: 'hidden',
                                        WebkitBoxOrient: 'vertical',
                                        WebkitLineClamp: 2, // Limit to two lines
                                        fontStyle: 'italic',
                                        fontSize: '10pt',
                                        textOverflow: 'ellipsis',
                                        color: 'primary.contrastText',
                                        paddingX: 1
                                    }}
                                >
                                    {processNewLines(message?.userInput?.studentAnswer)}
                                </Typography>

                                {allowLike &&
                                    <IconButton disableRipple
                                        onClick={() => toggleLike(message)} sx={{ mr: 1 }}
                                    >
                                        {message.liked ? <FavoriteRounded sx={{ color: 'red' }} /> : <FavoriteBorderRounded sx={{ color: 'gray' }} />}
                                    </IconButton>
                                }
                            </Box>

                            {renderFormattedParagraphs(formatCopilotResponse(message.copilotType, message.copilotResponse), { textIndent: '0', paddingX: '15px' })}
                        </Box>
                    ))}
                </Box>
            }
        </React.Fragment>
    );
};

export default CopilotMessagePopup;
