import React from 'react';
import Avatar from '@mui/material/Avatar';
import { PersonRounded } from '@mui/icons-material';

const StringAvatar = ({ name, size = '42px', showInitial = true }) => {
    const stringToColor = (string) => {
        const _string = string ?? ''
        let hash = 0;
        for (let i = 0; i < _string.length; i++) {
            hash = _string.charCodeAt(i) + ((hash << 5) - hash);
        }
        const hue = hash % 360;
        return `hsl(${hue}, 60%, 70%)`; // 60% saturation, 70% lightness
    };

    const getInitials = (name) => {
        return name ? name[0].toUpperCase() : '';
    };

    const avatarStyle = {
        width: size,
        height: size,
        backgroundColor: stringToColor(name),
        fontSize: `calc(${size} / 2)`,
    };

    return (
        <Avatar style={avatarStyle}>
            {(showInitial && name) ? getInitials(name) : <PersonRounded style={{ fontSize: `calc(${size} / 2)` }} />}
        </Avatar>
    );
};

export default StringAvatar;
