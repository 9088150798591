import axios from 'axios';


class ApiServices {
    static instance;

    static getInstance() {
        if (!ApiServices.instance) {
            ApiServices.instance = new ApiServices();
        }
        return ApiServices.instance;
    }

    constructor() {
        if (ApiServices.instance) {
            throw new Error("Use ApiServices.getInstance() instead of new.");
        }

        this.baseUrl = process.env.REACT_APP_SERVER_URL;
        ApiServices.instance = this;
    }

    // Method to handle API responses
    handleResponse(response) {
        return response.data;
    }

    // Method to handle errors
    handleError(error) {
        console.error("API call failed:", error);
        throw error;
    }

    // Auth APIs
    userLogin = async (username, password) => {
        try {
            const response = await axios.post(
                `${this.baseUrl}/auth/login`,
                { username, password },
                {
                    timeout: 30000
                });
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        };
    };

    userSignup = async (username, password, sinupProps) => {
        try {
            const response = await axios.post(
                `${this.baseUrl}/auth/register`,
                { username, password, ...sinupProps },
                {
                    timeout: 30000
                });
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        };
    };

    userWechatLogin = async (code, user_tags, expiration_date = null) => {
        try {
            const response = await axios.post(
                `${this.baseUrl}/auth/wechat_login`,
                { code, user_tags, expiration_date },
                {
                    timeout: 30000,
                });
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        };
    };

    requestSmsCode = async (mobile_number) => {
        try {
            const response = await axios.post(
                `${this.baseUrl}/auth/request_sms_code`,
                { mobile_number },
                {
                    timeout: 30000
                });
            return response;
        } catch (error) {
            return this.handleError(error);
        };
    };

    verifySmsCode = async (mobile_number, code) => {
        try {
            const response = await axios.post(
                `${this.baseUrl}/auth/verify_sms_code`,
                { mobile_number, code },
                {
                    timeout: 30000
                });
            return response;
        } catch (error) {
            return this.handleError(error);
        };
    };

    resetPassword = async (username, new_password) => {
        try {
            const response = await axios.post(
                `${this.baseUrl}/auth/reset_password`,
                { username, new_password },
                {
                    timeout: 30000
                });
            return response;
        } catch (error) {
            return this.handleError(error);
        };
    };

    fetchUserInfo = async (token) => {
        try {
            const response = await axios.get(
                `${this.baseUrl}/auth/get_user_info`,
                {
                    headers: { 'Authorization': `Bearer ${token}` },
                    timeout: 30000,
                });
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        };
    };

    setUserNickname = async (token, newNickname) => {
        try {
            const response = await axios.post(
                `${this.baseUrl}/auth/update_user_info`,
                { category: 'personalInfo', field: 'nickname', value: newNickname },
                {
                    headers: { 'Authorization': `Bearer ${token}` },
                    timeout: 30000,
                });
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        };
    };

    setUserGradeLevel = async (token, gradeLevel) => {
        try {
            const response = await axios.post(
                `${this.baseUrl}/auth/update_user_info`,
                { category: 'personalInfo', field: 'gradeLevel', value: gradeLevel },
                {
                    headers: { 'Authorization': `Bearer ${token}` },
                    timeout: 30000,
                });
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        };
    };

    setUserTutorialCompleted = async (token, tutorialCompleted) => {
        try {
            const response = await axios.post(
                `${this.baseUrl}/auth/update_user_info`,
                { category: 'onboardingStatus', field: 'tutorialCompleted', value: tutorialCompleted },
                {
                    headers: { 'Authorization': `Bearer ${token}` },
                    timeout: 30000,
                });
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        };
    };

    checkParentalControlsPassword = async (username, password) => {
        try {
            const response = await axios.post(
                `${this.baseUrl}/auth/check_parental_controls_password`,
                { username, password },
                {
                    timeout: 30000
                });
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        };
    };

    updateUserStats = async (token, field, value, method = 'add') => {
        try {
            const response = await axios.post(
                `${this.baseUrl}/auth/update_user_statistics`,
                { field, value, method },
                {
                    headers: { 'Authorization': `Bearer ${token}` },
                    timeout: 30000,
                });
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        };
    };

    postVisitKey = async (visitKey) => {
        try {
            const response = await axios.post(
                `${this.baseUrl}/auth/post_visit_key`,
                { visitKey },
                {
                    timeout: 30000,
                });
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        };
    };

    // Home APIs
    fetchUserStoryData = async (token, lang) => {
        try {
            const response = await axios.get(
                `${this.baseUrl}/home/load_user_story_data/${lang}`,
                {
                    headers: { 'Authorization': `Bearer ${token}` },
                    timeout: 30000,
                });
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        };
    };

    // Game API calls
    fetchTemplate = async (token, lang, collection, templateName, cardCategory) => {
        try {
            const response = await axios.get(
                `${this.baseUrl}/game/get_template/${lang}/${collection}/${templateName}/${cardCategory}`,
                {
                    headers: { 'Authorization': `Bearer ${token}` },
                    timeout: 30000,
                });
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        };
    };

    addDoc = async (token, lang, collection, newDocument) => {
        try {
            const response = await axios.post(
                `${this.baseUrl}/game/add_doc`,
                { lang, collection, newDocument },
                {
                    headers: { 'Authorization': `Bearer ${token}` },
                    timeout: 30000,
                }
            );
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        };
    };

    updateDoc = async (token, _id, lang, collection, documentUpdates, method = 'write') => {
        try {
            const response = await axios.post(
                `${this.baseUrl}/game/update_doc`,
                { _id, lang, collection, documentUpdates, method },
                {
                    headers: { 'Authorization': `Bearer ${token}` },
                    timeout: 30000,
                }
            );
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        };
    };

    getDoc = async (token, _id, lang, collection) => {
        try {
            const response = await axios.post(
                `${this.baseUrl}/game/get_doc`,
                { _id, lang, collection },
                {
                    headers: { 'Authorization': `Bearer ${token}` },
                    timeout: 30000,
                }
            );
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        };
    };

    viewDoc = async (_id, lang, collection) => {
        try {
            const response = await axios.post(
                `${this.baseUrl}/game/view_doc`,
                { _id, lang, collection },
                {
                    timeout: 30000,
                }
            );
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        };
    };

    runTextCompletions = async (token, _id, prompt, params, lang, collection) => {
        const MAX_RETRIES = 2;
        const RETRY_DELAY = 2000;
        const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

        for (let i = 0; i < MAX_RETRIES; i++) {
            try {
                const response = await axios.post(
                    `${this.baseUrl}/game/text_completions`,
                    { _id, prompt, params, lang, collection },
                    {
                        headers: { 'Authorization': `Bearer ${token}` },
                        timeout: 120000,
                    }
                );
                return this.handleResponse(response);
            } catch (error) {
                const retryCodes = [502];
                if (i < MAX_RETRIES - 1 && (error.response == null || retryCodes.includes(error.response?.status))) {
                    console.warn(`runTextCompletion: Attempt ${i + 1} failed. Retrying in ${RETRY_DELAY}ms...`);
                    await sleep(RETRY_DELAY);
                } else {
                    console.warn(`runTextCompletion: Attempt ${i + 1} failed. Exceeded max number of retries ...`)
                    this.handleError(error);
                }
            }
        }
    };

    runImageGeneration = async (token, _id, prompt, params, lang, collection) => {
        const MAX_RETRIES = 2;
        const RETRY_DELAY = 2000;
        const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

        for (let i = 0; i < MAX_RETRIES; i++) {
            try {
                const response = await axios.post(
                    `${this.baseUrl}/game/generate_image`,
                    { _id, prompt, params, lang, collection },
                    {
                        headers: { 'Authorization': `Bearer ${token}` },
                        timeout: 120000,
                    }
                );
                return this.handleResponse(response);
            } catch (error) {
                const retryCodes = [502];
                if (i < MAX_RETRIES - 1 && (error.response == null || retryCodes.includes(error.response?.status))) {
                    console.warn(`runImageGeneration: Attempt ${i + 1} failed. Retrying in ${RETRY_DELAY}ms...`);
                    await sleep(RETRY_DELAY);
                } else {
                    console.warn(`runImageGeneration: Attempt ${i + 1} failed. Exceeded max number of retries ...`)
                    this.handleError(error);
                }
            }
        }
    };
}

export default ApiServices;
