export function setObjectValueByPath(obj, pathArray, value, method) {
    let current = obj;
    for (let i = 0; i < pathArray.length; i++) {
        const key = pathArray[i];

        if (Array.isArray(current) && /^\d+$/.test(key)) {
            const intKey = parseInt(key, 10);
            if (intKey < current.length) {
                if (i === pathArray.length - 1) {   // End of pathArray
                    if (Array.isArray(current[intKey]) && method === 'append') {
                        current[intKey].push(value);
                    } else {
                        current[intKey] = value;
                    }
                    return;
                }
                else {
                    current = current[intKey];
                    continue;
                }
            }
            else if (intKey === current.length) {
                if (i === pathArray.length - 1) {   // End of pathArray
                    current[intKey] = value;
                    return;
                }
                else {
                    current[intKey] = {};
                    current = current[intKey];
                    continue;
                }
            }
            else {
                console.warn(`Canned set to array ${current} with index ${intKey}`);
                return;
            }
        }

        if (i === pathArray.length - 1) { // If it's the last key in the path
            if (method === 'append') {
                if (Array.isArray(current[key])) {
                    current[key].push(value);
                } else if (current[key] == null) {
                    current[key] = [value];
                } else {
                    console.warn(`Cannot append to non-array value key '${pathArray.join('.')}'.`);
                }
            } else if (method === 'write') {
                current[key] = value;
            }
        } else {
            if (!current[key] || typeof current[key] !== 'object') {
                current[key] = {};
            }
            current = current[key];
        }
    }
};


export function updateMetaTag(name, content) {
    let element = document.head.querySelector(`meta[name="${name}"]`);
    if (!element) {
        element = document.createElement('meta');
        element.setAttribute('name', name);
        document.head.appendChild(element);
    }
    element.setAttribute('content', content);
};

