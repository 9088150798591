import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Button } from '@mui/material';
import useCommonUtilities from '../../../hooks/useCommonUtilities';

const PromptDialog = ({ promptDialogOpen, handlePromptDialogClose, promptObject }) => {
    const [prompts, setPrompts] = useState(promptObject);
    const [selectedTab, setSelectedTab] = useState(0);
    const [currentPrompt, setCurrentPrompt] = useState('');
    const [isEdited, setIsEdited] = useState(false);
    const { openDialog, closeDialog } = useCommonUtilities();

    useEffect(() => {
        if (!prompts) { return; }
        const promptString = JSON.stringify(Object.values(prompts)[selectedTab], null, 2);
        setCurrentPrompt(promptString);
        setIsEdited(false);
    }, [prompts, selectedTab]);

    const promptUnsavedChangesDialog = (onConfirmAction) => {
        openDialog({
            title: "Warning",
            content: "Prompt edits not saved... Do you wish to DISCARD these changes?",
            onConfirm: () => {
                setCurrentPrompt(JSON.stringify(Object.values(prompts)[selectedTab], null, 2));
                setIsEdited(false);
                onConfirmAction();
                closeDialog();
            },
            onCancel: closeDialog,
        });
    };

    const handleClose = () => {
        if (isEdited) {
            promptUnsavedChangesDialog(() => handlePromptDialogClose({ newPrompts: prompts }));
        } else {
            handlePromptDialogClose({ newPrompts: prompts });
        }
    };

    const handleTabChange = (event, newValue) => {
        if (isEdited) {
            promptUnsavedChangesDialog(() => setSelectedTab(newValue));
        } else {
            setSelectedTab(newValue);
        }
    };

    const handleChange = (event) => {
        setCurrentPrompt(event.target.value);
        setIsEdited(true);
    };

    const handleSave = () => {
        try {
            const updatedPrompt = JSON.parse(currentPrompt);
            setPrompts(prev => ({ ...prev, [Object.keys(prompts)[selectedTab]]: updatedPrompt }));
            setIsEdited(false);
        } catch (e) {
            const errorMsg = `Error parsing JSON:\n${e}`;
            openDialog({
                open: true,
                content: errorMsg,
            })
            console.error(errorMsg);
        }
    };

    return (
        <Dialog
            open={promptDialogOpen}
            onClose={handleClose}
            sx={{
                '& .MuiDialog-paper': {
                    width: '96%',
                    maxWidth: '900px',
                    height: '620px',
                    boxSizing: 'border-box',
                },
            }}
        >
            <DialogTitle>Edit Prompts</DialogTitle>
            <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}> {/* Enable horizontal scrolling */}
                <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {prompts && Object.keys(prompts).map((key, index) => (
                        <Tab
                            label={key}
                            key={index}
                            sx={{
                                textTransform: 'none',
                                minWidth: 100, // Optional: set a minimum width for each tab
                            }}
                        />
                    ))}
                </Tabs>
            </div>
            <DialogContent>
                <TextField
                    helperText="This is the prompt send to GPT"
                    variant="outlined"
                    multiline
                    fullWidth
                    rows={18}
                    value={currentPrompt}
                    onChange={handleChange}
                    sx={{
                        width: '100%',
                        boxSizing: 'border-box',
                    }}
                />
            </DialogContent>
            <Button
                variant="contained"
                color="primary"
                disabled={!isEdited}
                onClick={handleSave}
                style={{ position: 'absolute', bottom: 42, right: 24 }}
            >
                Save
            </Button>
        </Dialog>
    );
}

export default PromptDialog;
