import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { SentimentVeryDissatisfiedRounded, SentimentVerySatisfiedRounded } from '@mui/icons-material';


const RatingComponent = ({ headerText, noteText, onRate, rate, freeze, sx }) => {
    const [rating, setRating] = useState(rate ?? 0);

    const handleRate = (value) => {
        if (freeze) { return; }
        setRating(value);
        if (typeof onRate === 'function') {
            onRate(value);
        }
    };

    return (
        <Box sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'primary.dark',
            ...sx
        }}>

            <Typography variant="h6" sx={{ color: 'text.primary' }}>{headerText}</Typography>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%',
                    maxWidth: '310px',
                    mt: '20px',
                    color: 'text.secondary',
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <SentimentVeryDissatisfiedRounded sx={{ mr: '5px' }} />
                    <Typography variant='subtitle2'> {noteText?.rate0}</Typography>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography variant='subtitle2'>{noteText?.rate10}</Typography>
                    <SentimentVerySatisfiedRounded sx={{ ml: '5px' }} />
                </Box>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    width: '100%',
                    maxWidth: '320px',
                    mt: '20px',

                }}
            >
                {[...Array(10)].map((_, index) => (
                    <Box
                        key={index + 1}
                        onClick={() => handleRate(index + 1)}
                        sx={{
                            color: 'primary.contrastText',
                            width: '20px',
                            padding: 0,
                            borderRadius: 1,
                            backgroundColor: index + 1 <= rating ? 'primary.main' : 'transparent',
                            boxShadow: 2,
                        }}
                    >
                        {index + 1}
                    </Box>
                ))}
            </Box>
        </Box >
    );
};

export default RatingComponent;
