import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { processNewLines } from '../../utils/StringUtils';
import useCommonUtilities from '../../hooks/useCommonUtilities';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { CheckCircleOutlineRounded, ErrorOutlineRounded, WarningRounded } from '@mui/icons-material';
import withRoot from '../../withRoot';


const DialogComponent = () => {
    const { dialogProps, tryCloseDialog, setDialogProps } = useCommonUtilities();
    const { t } = useTranslation();

    const buttonStyles = {
        color: 'text.secondary',
        '&:hover': {
            backgroundColor: 'transparent',
        }
    };

    const closeDialog = () => {
        setDialogProps(prevProps => ({ ...prevProps, open: false }));
    };

    const IconSwitcher = ({ iconName }) => {
        switch (iconName) {
            case 'check':
                return <CheckCircleOutlineRounded sx={{ color: 'green', fontSize: '24px' }} />;
            case 'error':
                return <ErrorOutlineRounded sx={{ color: 'red', fontSize: '24px' }} />;
            case 'warn':
                return <WarningRounded sx={{ color: 'orange', fontSize: '24px' }} />;
            default:
                return null;
        }
    };

    return (
        <Dialog open={dialogProps.open} onClose={tryCloseDialog}
            sx={{
                '& .MuiDialog-paper': {
                    minWidth: '300px',
                    maxWidth: '600px',
                    minHeight: '100px',
                    maxHeight: '400px',
                },
            }}
        >
            {dialogProps.title &&
                <DialogTitle>
                    {processNewLines(dialogProps.title)}
                </DialogTitle>
            }

            <DialogContent>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {dialogProps.icon && (
                        <Box sx={{ marginRight: 1, display: 'flex', alignItems: 'center' }}>
                            <IconSwitcher iconName={dialogProps.icon} />
                        </Box>
                    )}
                    <DialogContentText sx={{ color: 'text.primary' }}>
                        {processNewLines(dialogProps.content)}
                    </DialogContentText>
                </Box>
            </DialogContent>

            {dialogProps.image && (
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {dialogProps.image}
                </Box>
            )}

            <DialogActions>
                {dialogProps.onCancel && (
                    <Button disableRipple onClick={() => { dialogProps.onCancel(); closeDialog(); }} color="primary" sx={buttonStyles}>
                        {t('dialogs.cancel')}
                    </Button>
                )}
                {dialogProps.onConfirm && (
                    <Button disableRipple onClick={() => { dialogProps.onConfirm(); closeDialog(); }} color="primary" sx={buttonStyles}>
                        {t('dialogs.confirm')}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default withRoot(DialogComponent);
