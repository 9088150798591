import { Box, Container, Typography } from "@mui/material";
import React from "react";
import sorry_cloud_png from '../../assets/sorry_cloud.png';
import { processNewLines } from "../../utils/StringUtils";
import AppAppBar from "./AppAppBar";


const RenderErrorPage = ({ sx, message }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                minHeight: '100vh',
                width: '100vw',
                backgroundColor: 'background.main',
            }}
        >
            <AppAppBar navPages={['home']} />

            <Container maxWidth='sm'
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxSizing: 'border-box',
                    ...sx
                }}
            >
                <img
                    src={sorry_cloud_png}
                    alt="error page"
                    style={{
                        width: '100%',
                        maxWidth: '200px'
                    }}
                />

                {message &&
                    <Typography variant='h6' sx={{ color: 'text.primary' }}>
                        {processNewLines(message)}
                    </Typography>
                }
            </Container>
        </Box>
    )
};

export default RenderErrorPage;
