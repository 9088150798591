import { useCallback, useEffect, useState } from "react";
import { CommonUtilitiesContext } from "../contexts/CommonUtilitiesContext";
import { defaultLang, supportedLangs } from "../configs/appConfig";
import { useTranslation } from "react-i18next";
import ApiServices from "../services/ApiServices";
import { useAuthentication } from "../hooks/useAuthentication";
import DialogComponent from "../components/Shared/DialogComponent";
import AccountModal from "../components/Shared/AccountModal";


export const CommonUtilitiesProvider = ({ children }) => {
    const verboseSetting = process.env.REACT_APP_VERBOSE ?? 0;
    const { token, handleLogout, userInfo } = useAuthentication();

    // Localization 
    const { i18n, t } = useTranslation();
    const [lang, setLang] = useState(defaultLang);

    const setLanguage = (newLang) => {
        if (typeof newLang === 'string' && supportedLangs.includes(newLang)) {
            setLang(newLang);
            i18n.changeLanguage(newLang);
        }
    };

    // Dialog Utilities
    const [dialogProps, setDialogProps] = useState({
        open: false,
        content: null,
        title: null,
        onConfirm: null,
        onCancel: null,
    });

    const openDialog = useCallback((options) => {
        setDialogProps(prevProps => {
            if (!prevProps.open) {
                return { open: true, ...options };
            } else {
                console.warn("A dialog is already open. Ignoring new dialog request.");
                if (options.onCancel) {
                    options.onCancel();
                }
                return prevProps;
            }
        });
    }, []);

    const closeDialog = useCallback(() => {
        setDialogProps(prevProps => ({ ...prevProps, open: false }));
    }, []);

    const tryCloseDialog = useCallback(() => {
        if (dialogProps.onConfirm && dialogProps.onCancel == null) {
            return;
        }
        else if (typeof dialogProps.onCancel === 'function') {
            dialogProps.onCancel();
        }
        setDialogProps(prevProps => ({ ...prevProps, open: false }));
    }, [dialogProps]);

    // General purpose error handling
    const handleAPIError = useCallback((error) => {
        if (error.response?.status === 401) {
            console.error("Unauthorized request. Logging out...");
            handleLogout();
            openDialog({
                content: t('errors.unauthorized'),
                onConfirm: () => { }
            });
        }
        else if (error.response?.status >= 500) {
            openDialog({ content: t('errors.system_bug'), icon: 'error', });
        };
    }, [openDialog, handleLogout, t]);

    // Read-only user home data: cards, decks, stories
    const [userStoryData, setUserStoryData] = useState(null);

    const fetchUserStoryData = useCallback(async () => {
        try {
            const result = await ApiServices.getInstance().fetchUserStoryData(token, lang);
            setUserStoryData(result);

            if (verboseSetting > 1) {
                console.debug("Fetched userdata:", result);
            }
        } catch (error) {
            handleAPIError(error);
        }
    }, [token, lang, verboseSetting, handleAPIError]);

    // Prompt
    const [promptVisibility, setPromptVisibility] = useState(true);
    const handleShowPromptToggle = () => {
        setPromptVisibility(!promptVisibility);
    };

    // Subscription dialog states
    const [subscriptionDialogOpen, setSubscriptionDialogOpen] = useState(false);

    // Account Modal
    const [accountModalOpen, setAccountModalOpen] = useState(false);

    // visitKey logging 
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const visitKey = queryParams.get('visitKey');

        if (visitKey) {
            ApiServices.getInstance().postVisitKey(visitKey).catch(console.error);
        }
    }, []);

    const contextValue = {
        verboseSetting,
        lang,
        setLanguage,
        userStoryData,
        fetchUserStoryData,
        handleShowPromptToggle,
        promptVisibility,
        subscriptionDialogOpen,
        setSubscriptionDialogOpen,
        openDialog,
        closeDialog,
        tryCloseDialog,
        dialogProps,
        setDialogProps,
        handleAPIError,
        setAccountModalOpen,
    };


    return (
        <CommonUtilitiesContext.Provider value={contextValue}>
            {children}
            <DialogComponent />

            {userInfo &&
                <AccountModal
                    isOpen={accountModalOpen || userInfo?.onboardingStatus?.tutorialCompleted === false}
                    onClose={() => setAccountModalOpen(false)}
                    allowedSettings={["gradeLevel"]}
                />
            }

        </CommonUtilitiesContext.Provider>
    );
}