import { Container, Typography } from "@mui/material";
import React from "react";
import IdeaIntakeC1 from "./IdeaIntakeC1";
import { useTranslation } from "react-i18next";
import { WriteStoryModes } from "../storyUtils";
import ActionMenu from "../ActionMenu";
import CopilotSelectionPanel from "../../PupuCopilot/SelectionPanel";


const C1WritePCardContent = ({ writeStoryProps, mode }) => {
    const { t } = useTranslation();

    const { writeStoryState: { story } } = writeStoryProps;
    const currentState = story?.states?.currentState;

    return (
        <Container maxWidth='sm'
            sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                marginY: '160px',
                boxSizing: 'border-box',
                gap: 4,
            }}
        >
            <Typography variant='h5' fontSize={28}
                sx={{
                    color: 'primary.contrastText',
                    fontWeight: 'bold',
                }}>
                {t('writeStory.c1.pageHeader')}
            </Typography>

            {story?._id != null && mode === WriteStoryModes.CREATE &&
                <ActionMenu writeStoryProps={writeStoryProps}
                    sx={{ mt: -7, width: '100%', display: 'flex', justifyContent: 'flex-end' }} />
            }

            {story &&
                <>
                    {(['initial', 'inProgress', 'completed'].includes(currentState)) &&
                        <IdeaIntakeC1 writeStoryProps={writeStoryProps} mode={mode} />
                    }

                    {(['inProgress', 'completed'].includes(currentState)) &&
                        (story.data?.outlineArchitect) &&
                        <></>
                    }

                    {(['completed'].includes(currentState)) &&
                        (story.data?.storyComposer) &&
                        <></>
                    }

                    {mode === WriteStoryModes.CREATE &&
                        <CopilotSelectionPanel
                            writeStoryProps={writeStoryProps}
                            showHeader={currentState === 'initial'}
                            allowSelectCopilot={currentState === 'initial'}
                            mode={mode}
                        />
                    }
                </>
            }
        </Container>
    )
};

export default C1WritePCardContent;