import pupu_avatar from "../../assets/puff_copilot.png";
import copilot_avatar_1 from "../../assets/copilot_avatar_1.png";
import copilot_avatar_2 from "../../assets/copilot_avatar_2.png";
import copilot_avatar_3 from "../../assets/copilot_avatar_3.png";
import copilot_avatar_4 from "../../assets/copilot_avatar_4.png";


export const supportedCopilotTypes = [
    '字词老师', '思路老师', '拓展老师', '百科老师'
];

export const copilotAvatarMapping = {
    '字词老师': copilot_avatar_1,
    '思路老师': copilot_avatar_2,
    '拓展老师': copilot_avatar_3,
    '百科老师': copilot_avatar_4,
};

export const copilotIntroMapping = {
    '字词老师': '总能帮你想起好词好句的字词老师',
    '思路老师': '脑海里总能很多升华文章的点子！',
    '拓展老师': '总能为你的文章添上更多生动细节的拓展老师',
    '百科老师': '提供百科信息，让你的作品知识更丰富！',
}

export const pupuAvatar = pupu_avatar;

export const formatCopilotResponse = (copilotType, response) => {
    switch (copilotType) {
        case '字词老师':
            const words = response['words'].join("，");
            const sentences = response['sentences'].join("，");
            return `可以试试这些好词：\n${words}\n以及这些好句：\n${sentences}`;
        case '思路老师':
            const examples = response['examples'].join("\n");
            return `可以试试在文中体现：\n${examples}`;
        case '百科老师':
            return `希望这些知识可以帮助到你：\n${response}`
        default:
            return response;
    };
};