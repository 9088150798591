import { useEffect, useRef, useState } from "react";
import { runPromptsForTextCompletions } from "../../../utils/promptUtils";
import { useAuthentication } from "../../../hooks/useAuthentication";
import useCommonUtilities from "../../../hooks/useCommonUtilities";
import ApiServices from "../../../services/ApiServices";
import { WriteStoryModes, getAreaWithRatingBelowThresh } from "../storyUtils";
import { useTranslation } from "react-i18next";


export const useImprovementRound = ({ roundIndex, writeStoryProps, mode }) => {
    const { token, userInfo, fetchUserInfo } = useAuthentication();
    const { lang, verboseSetting, openDialog } = useCommonUtilities();
    const { t } = useTranslation();

    // Unpack
    const { writeStoryState, dispatchAndUpdateStory, copilotManager: { triggerCopilotResponse } } = writeStoryProps;
    const { story } = writeStoryState;
    const outlineArchitect = story.data?.outlineArchitect;
    const curImprovementAreas = outlineArchitect?.improvementAreas?.[roundIndex];
    const curQuestions = outlineArchitect?.improvementQuestions?.[roundIndex] ?? [];
    const gradeLevel = userInfo?.personalInfo?.gradeLevel;
    const _id = story._id;

    // States and Refs
    const [currentAction, setCurrentAction] = useState(null);
    const doneImprovingAreas = useRef(false);

    // User inputs
    const [userAnswers, setUserAnswers] = useState(curQuestions.map(qaPair => {
        return (qaPair.answer ?? '');
    }));
    const handleUserAnswerChange = (questionIndex, answer) => {
        setUserAnswers(currentAnswers => {
            const updatedAnswers = [...currentAnswers];
            updatedAnswers[questionIndex] = answer;
            return updatedAnswers;
        });
    };

    const [revisedOutline, setRevisedOutline] = useState(outlineArchitect?.revisedOutlines?.[roundIndex]);
    const handleRevisedOutlineChange = (event) => {
        setRevisedOutline(event.target.value);
    };
    const handleRevisedOutlineBlur = () => {
        dispatchAndUpdateStory({
            _id, updateObject: {
                [`data.outlineArchitect.revisedOutlines.${roundIndex}`]: revisedOutline,
            }
        });
    };

    // Props needed to run text completions
    const runTextCompletionsProps = {
        doc: story,
        state: writeStoryState,
        lang,
        token,
        mock: false,
    };

    useEffect(() => {
        const tryGenQuestion = async () => {
            try {
                await genImprovementQuestions({ verbose: verboseSetting, improvementAreas: curImprovementAreas });
            } catch (error) {
                console.error('Failed to generate question', error);
                openDialog({ content: t('errors.system_bug') });
            } finally {
                setCurrentAction(null);
                fetchUserInfo().catch(console.error);
            }
        }

        if ((mode === WriteStoryModes.CREATE && outlineArchitect.trigger)
            && (Array.isArray(curImprovementAreas) && curImprovementAreas.length > 0)) {
            tryGenQuestion();
        }
        // eslint-disable-next-line
    }, []);

    // Button click handlers
    const onSendUserAnswer = async ({ qnaIndex, skipCheck = false, verbose = verboseSetting }) => {
        try {
            let interimImprovementAreas;

            if (skipCheck) {
                interimImprovementAreas = await skipUserAnswerCheck({ qnaIndex, verbose });
            } else {

                await dispatchAndUpdateStory({  // Locally clear reject reason
                    _id: null, updateObject: {
                        [`data.outlineArchitect.improvementQuestions.${roundIndex}.${qnaIndex}.rejectReason`]: null,
                    }, method: 'write', verbose: verbose
                }); 

                const result = await checkUserAnswer({ qnaIndex, verbose });
                const checkPassed = result.checkPassed;
                interimImprovementAreas = result.interimImprovementAreas;

                if (checkPassed) {
                    // Trigger copilot
                    const copilotInput = { studentAnswer: userAnswers[qnaIndex], question: curQuestions?.[qnaIndex]?.question };
                    triggerCopilotResponse({ userInput: copilotInput, gradeLevel, key: `outlineArchitect_${roundIndex}_${qnaIndex}` });
                } else {
                    setCurrentAction(null);
                    return;
                }
            };

            // Last question in current round
            if (qnaIndex === curQuestions.length - 1) {
                dispatchAndUpdateStory({
                    _id, updateObject: {
                        'data.outlineArchitect.trigger': true,
                    }, method: 'write', verbose: verbose
                });
                await genImprovementQuestions({ verbose, improvementAreas: interimImprovementAreas });

                // Rebuild outline if genImprovementQuestions checks exit condition
                if (doneImprovingAreas.current) {
                    await rebuildOutline({ interimImprovementAreas });
                }
            }

        } catch (error) {
            console.error(error);
            openDialog({ content: t('errors.system_bug') });
        } finally {
            setCurrentAction(null);
            fetchUserInfo().catch(console.error);
        };
    };

    const onSendRevisedOutline = async () => {
        ApiServices.getInstance().updateUserStats(token, 'num_stories_completed', 1);

        const updateObject = {
            'metadata.description': revisedOutline,
            'data.storyComposer.trigger': true,
            'data.storyComposer.sampleStories': [],
            'states.currentState': 'completed',
        };
        dispatchAndUpdateStory({ _id, updateObject, method: 'write', verbose: verboseSetting });
    };

    const onClickHint = async (qnaIndex, reveal) => {
        if (reveal) {
            const curRevealedTimes = curQuestions?.[qnaIndex].hintRevealedTimes || 0;
            if (curRevealedTimes) { return; }
            const updateObject = {
                [`data.outlineArchitect.improvementQuestions.${roundIndex}.${qnaIndex}.hintRevealedTimes`]: curRevealedTimes + 1,
            };
            dispatchAndUpdateStory({ _id, updateObject, method: 'write', verbose: verboseSetting });
        }
    };

    // Action functions
    const genImprovementQuestions = async ({ verbose, improvementAreas }) => {
        const action = "genImprovementQuestions";

        const improveArea = getAreaWithRatingBelowThresh({ improvementAreas });

        // Exit condition
        if (improveArea === null || curQuestions.length > 4) {    // Do not ask more than 5 questions
            doneImprovingAreas.current = true;
            return;
        }

        setCurrentAction(action);

        // Prepare
        const interimState = {
            'temp.userGradeLevel': gradeLevel,
            'temp.improveArea': improveArea,
            'temp.questionToAvoid': improveArea?.questionToAvoid,
        };

        // Run prompts
        const result = await runPromptsForTextCompletions({
            action,
            interimState,
            verbose,
            ...runTextCompletionsProps,
        });

        // Post propress
        const newImprovementQuestion = result['1_result']['newImprovementQuestion'];

        dispatchAndUpdateStory({
            _id, updateObject: {
                [`data.outlineArchitect.improvementQuestions.${roundIndex}`]: newImprovementQuestion,
            }, method: 'append', verbose: verbose
        });

        dispatchAndUpdateStory({
            _id, updateObject: {
                'data.outlineArchitect.trigger': false,
            }, method: 'write', verbose: verbose
        });
    };

    const checkUserAnswer = async ({ qnaIndex, verbose }) => {
        const action = 'checkImprovementAnswer';
        setCurrentAction(`checkImprovementAnswer_${qnaIndex}`);

        // Prepare
        const questionData = curQuestions[qnaIndex];
        const interimState = {
            'temp.userGradeLevel': gradeLevel,
            'temp.improveArea': curImprovementAreas.find(area => area.name === questionData.name),
            'temp.questionAndAnswer': { question: questionData.question, answer: userAnswers[qnaIndex] }
        };

        // Run prompts
        const result = await runPromptsForTextCompletions({
            action,
            interimState,
            verbose,
            ...runTextCompletionsProps,
        });

        // Post process
        const checkPassed = result['1_result']['validAnswer'];

        const interimImprovementAreas = JSON.parse(JSON.stringify(curImprovementAreas));

        if (checkPassed) {
            const adjustedRating = result['1_result']?.['adjustedRating'];

            // Update improvementArea with new rating
            const foundObject = interimImprovementAreas.find(area => area.name === adjustedRating.name);
            if (foundObject) {
                foundObject.rating = Number(adjustedRating.rating ?? 2);
                foundObject.addedDetails = (foundObject.addedDetails ?? "") + userAnswers[qnaIndex] + ";";
                foundObject.questionToAvoid = (foundObject.questionToAvoid ?? "") + curQuestions[qnaIndex]?.question;
            }

            if (verbose) { console.log('Update Improvement Areas', foundObject); }
            if (verbose) { console.log('Improvement Areas after update ', interimImprovementAreas); }

            dispatchAndUpdateStory({
                _id, updateObject: {
                    [`data.outlineArchitect.improvementQuestions.${roundIndex}.${qnaIndex}.answer`]: userAnswers[qnaIndex],
                    [`data.outlineArchitect.improvementQuestions.${roundIndex}.${qnaIndex}.answered`]: true,
                    [`data.outlineArchitect.improvementQuestions.${roundIndex}.${qnaIndex}.rejectReason`]: null,
                    [`data.outlineArchitect.improvementQuestions.${roundIndex}.${qnaIndex}.skipped`]: false,
                    [`data.outlineArchitect.improvementAreas.${roundIndex}`]: interimImprovementAreas,
                }, method: 'write', verbose: verbose
            });
        } else {
            dispatchAndUpdateStory({
                _id, updateObject: {
                    [`data.outlineArchitect.improvementQuestions.${roundIndex}.${qnaIndex}.answer`]: userAnswers[qnaIndex],
                    [`data.outlineArchitect.improvementQuestions.${roundIndex}.${qnaIndex}.rejectReason`]: result['1_result']?.['explanation'],
                }, method: 'write', verbose: verbose
            });
        };

        return { checkPassed, interimImprovementAreas };
    };

    const skipUserAnswerCheck = async ({ qnaIndex, verbose }) => {
        const action = 'skipUserAnswerCheck';
        setCurrentAction(action);

        const name = curQuestions[qnaIndex]?.name;  // The name of improvement area being skipped
        const interimImprovementAreas = JSON.parse(JSON.stringify(curImprovementAreas));
        const foundObject = interimImprovementAreas.find(area => area.name === name);
        if (foundObject) {
            // foundObject.rating = String(Number(foundObject.rating || 1) + 1);  // If user reject, increase rating so that we do not stuck on the same area forever.
            foundObject.rating = 3;
            foundObject.questionToAvoid = (foundObject.questionToAvoid ?? "") + curQuestions[qnaIndex]?.question;
        }

        await dispatchAndUpdateStory({
            _id, updateObject: {
                [`data.outlineArchitect.improvementQuestions.${roundIndex}.${qnaIndex}.answer`]: userAnswers[qnaIndex],
                [`data.outlineArchitect.improvementQuestions.${roundIndex}.${qnaIndex}.answered`]: true,
                [`data.outlineArchitect.improvementQuestions.${roundIndex}.${qnaIndex}.rejectReason`]: null,
                [`data.outlineArchitect.improvementQuestions.${roundIndex}.${qnaIndex}.skipped`]: true,
                [`data.outlineArchitect.improvementAreas.${roundIndex}`]: interimImprovementAreas,
            }, method: 'write', verbose: verbose
        });

        return interimImprovementAreas;
    };

    const rebuildOutline = async ({ interimImprovementAreas, verbose = verboseSetting }) => {
        const action = 'rebuildOutline';
        setCurrentAction(action);

        let addedDetails = "";

        // Prepare
        interimImprovementAreas.forEach((area) => {
            addedDetails = addedDetails + (area.addedDetails || '');
        });

        const interimState = {
            'temp.qna': addedDetails,
        };

        // Run prompts
        const result = await runPromptsForTextCompletions({
            action,
            interimState,
            verbose,
            ...runTextCompletionsProps,
        });

        // Post process
        const outline = result['1_result']['outline'];

        if (roundIndex === 0) {
            dispatchAndUpdateStory({
                _id, updateObject: {
                    'data.outlineArchitect.revisedOutlines': [outline],
                }, method: 'write', verbose: verbose
            });
        } else {
            dispatchAndUpdateStory({
                _id, updateObject: {
                    'data.outlineArchitect.revisedOutlines': outline,
                }, method: 'append', verbose: verbose
            });
        };
        setRevisedOutline(outline);
    };

    return {
        currentAction,
        userAnswers,
        handleUserAnswerChange,
        onSendUserAnswer,
        revisedOutline,
        handleRevisedOutlineChange,
        handleRevisedOutlineBlur,
        onSendRevisedOutline,
        onClickHint,
    };
};
