import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import RenderTextLogo from './RenderTextLogo';
import { StyledTextButton } from '../../styles/StyledButtons';
import { Drawer } from '@mui/material';
import { zIndexValues } from '../../styles/zIndexValues';
import { useNavigate } from 'react-router-dom';
import { useAuthentication } from '../../hooks/useAuthentication';
import UserMenu from './UserMenu';
import { useTranslation } from 'react-i18next';

const logoHeight = '30px';

function AppAppBar({ displayLanguage = 'zh', navPages = [] }) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { handleLogout, userInfo } = useAuthentication();

    const showNavDrawer = Array.isArray(navPages) && navPages.length > 0;
    const [navDrawerOpen, setNavDrawerOpen] = useState(false);
    const handleCloseNavDrawer = (clickedPage) => {
        setNavDrawerOpen(false);

        if (typeof clickedPage === 'string' && navPages.includes(clickedPage)) {
            switch (clickedPage) {
                case 'login':
                    navigate(`/login`);
                    return;
                case 'signup':
                    navigate(`/signup`);
                    return;
                case 'home':
                    navigate(`/home`);
                    return;
                default:
                    return;
            }
        };
    };

    return (
        <AppBar
            position="fixed"
            elevation={0}
            sx={{
                boxShadow: 0,
                bgcolor: 'background.main',
                backgroundImage: 'none',
                zIndex: zIndexValues.appBar,
                py: 1,
            }}
        >
            <Container maxWidth="lg" sx={{paddingX: {xs: '5px', sm: '15px'}}}>
                <Toolbar disableGutters>

                    {/* xs and sm */}
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, alignItems: 'center', justifyContent: 'flex-start', gap: '2px' }}>
                        {showNavDrawer && <>
                            <IconButton
                                size="large"
                                onClick={() => setNavDrawerOpen(true)}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Drawer
                                anchor="left"
                                open={navDrawerOpen}
                                onClose={() => handleCloseNavDrawer()}
                                sx={{ zIndex: zIndexValues.drawer, }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        flexGrow: 1,
                                        justifyContent: 'flex-end',
                                        alignItems: 'center',
                                        backgroundColor: 'background.main',
                                        color: 'text.primary',
                                        gap: 4,
                                        paddingY: 10,
                                        paddingX: 5,
                                    }}
                                >
                                    {navPages.map((page) => (
                                        <Typography key={page} onClick={() => handleCloseNavDrawer(page)} textAlign="center">
                                            {t(`appBar.${page}`)}
                                        </Typography>
                                    ))}
                                </Box>
                            </Drawer>
                        </>}

                        <Box sx={{ cursor: 'pointer' }} onClick={() => navigate(`/home`)}>
                            <RenderTextLogo lang={displayLanguage} logoHeight={logoHeight} showIcon={!showNavDrawer} />
                        </Box>
                    </Box>

                    {/* md and up */}
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, alignItems: 'center', justifyContent: 'space-between', }}>

                        <Box sx={{ cursor: 'pointer' }} onClick={() => navigate(`/home`)}>
                            <RenderTextLogo lang={displayLanguage} logoHeight={logoHeight} />
                        </Box>
                        {showNavDrawer &&
                            <Box sx={{ display: 'flex' }}>
                                {navPages.map((page) => (
                                    <StyledTextButton
                                        disableRipple
                                        key={page}
                                        onClick={() => handleCloseNavDrawer(page)}
                                        sx={{ display: 'block' }}
                                    >
                                        {t(`appBar.${page}`)}
                                    </StyledTextButton>
                                ))}
                            </Box>
                        }
                    </Box>

                    {userInfo &&
                        <UserMenu userInfo={userInfo} handleLogout={handleLogout} />
                    }
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default AppAppBar;
