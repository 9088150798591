import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const getSizeStyles = (size) => {
    switch (size) {
        case 'small':
            return { fontSize: '14px', padding: '8px 22px' };
        case 'large':
            return { fontSize: '18px', padding: '12px 30px' };
        case 'medium':
        default:
            return { fontSize: '16px', padding: '10px 26px' };
    }
};

export const StyledContainedButton = styled(Button)(({ theme, disabled, size }) => {
    const { fontSize, padding } = getSizeStyles(size);

    return {
        boxSizing: 'border-box',
        textTransform: 'none',
        fontSize: fontSize,
        padding: padding,
        color: theme.palette.primary.contrastText, // Default color
        backgroundColor: theme.palette.primary.main, // Default background color
        boxShadow: disabled ? theme.custom.shadow.xsmall : theme.custom.shadow.small, // Box shadow based on disabled state
        borderRadius: theme.custom.radius.large,
        '&:hover': {
            backgroundColor: theme.palette.primary.light, // Default hover color
            boxShadow: disabled ? theme.custom.shadow.xsmall : theme.custom.shadow.small,
        },
    };
});

export const StyledTextButton = styled(Button)(({ theme, disabled, size, sx = {} }) => {
    const { fontSize, padding } = getSizeStyles(size);

    return {
        boxSizing: 'border-box',
        textTransform: 'none',
        fontSize: fontSize,
        padding: padding,
        color: theme.palette.primary.contrastText, // Default color
        backgroundColor: 'transparent', // Default background color
        '&:hover': {
            backgroundColor: 'transparent', // Default hover color
        },
        ...sx
    };
});
