import React from "react";
import { Typography, Box } from '@mui/material';
import { ForwardRounded } from "@mui/icons-material";
import { processNewLines } from "../../../../utils/StringUtils";


const SampleEssayAnalysis = ({ analysis }) => {
    const { goodWords = [], goodPhrases = [], compositionStructure = [] } = analysis || {};

    return (
        <React.Fragment>

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                gap: 2,
            }}>
                <Typography variant="h6">
                    段落结构:
                </Typography>

                {compositionStructure.map((section, index) => (
                    <Box key={index}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                            gap: 1,
                        }}
                    >
                        <Typography fontWeight='bold' textAlign='start'>
                            {section?.moduleName ?? section?.paragraphNumber}  
                        </Typography>

                        <Typography textAlign='start' sx={{ paddingLeft: { xs: 1, sm: 2 } }}>
                            {section?.mainContent}
                        </Typography>

                        <Typography textAlign='start' sx={{ paddingLeft: { xs: 1, sm: 2 } }}>
                            {section?.moduleValue ?? section?.paragraphValue}
                        </Typography>
                    </Box>
                ))}

                <Box sx={{ width: '100%', height: '0px', borderBottom: 1, borderColor: 'primary.main', my: 1 }} />

                <Typography variant="h6">
                    词句建议
                </Typography>

                {[...goodWords].map((phrase, index) => (
                    <Box key={`words-${index}`}
                        sx={{
                            borderLeft: 1, borderColor: 'primary.main', pl: 1, borderRadius: 3,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography textAlign='start'>
                            {phrase.word}
                        </Typography>

                        <Typography sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            color: 'text.secondary',
                        }}>
                            {(phrase.similarWords) &&
                                <>
                                    <ForwardRounded
                                        sx={{ color: 'primary.main' }}
                                    />
                                    {Array.isArray(phrase.similarWords) ? phrase.similarWords.join('，') : phrase.similarWords}
                                </>
                            }
                        </Typography>
                    </Box>
                ))}

                {[...goodPhrases].map((phrase, index) => (
                    <Box key={`phrase-${index}`}
                        sx={{
                            borderLeft: 1, borderColor: 'primary.main', pl: 1, borderRadius: 3,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography textAlign='start'>
                            {phrase.phrases}
                        </Typography>

                        <Typography sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            color: 'text.secondary',
                        }}>
                            {(phrase.similarPhrases) &&
                                <>
                                    <ForwardRounded
                                        sx={{ color: 'primary.main' }}
                                    />
                                    {processNewLines(Array.isArray(phrase.similarPhrases) ? phrase.similarPhrases.join('\n') : phrase.similarPhrases)}
                                </>
                            }
                        </Typography>
                    </Box>
                ))}

                <Box sx={{ width: '100%', height: '0px', borderBottom: 1, borderColor: 'primary.main', my: 1 }} />
            </Box>
        </React.Fragment>
    );
};

export default SampleEssayAnalysis;
