import { useState, useCallback, useEffect } from 'react';
import { AuthenticationContext } from '../contexts/AuthenticationContext';
import ApiServices from '../services/ApiServices';


export const AuthenticationProvider = ({ children }) => {
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [userInfo, setUserInfo] = useState(null);

    const verboseSetting = process.env.REACT_APP_VERBOSE ?? 0;

    const fetchUserInfo = useCallback(async (forceFetch = false) => {
        // Check for token existence and optionally forceFetch flag
        if (token) {
            if (forceFetch || !userInfo) {
                try {
                    // Use ApiServices to fetch user data
                    const data = await ApiServices.getInstance().fetchUserInfo(token);
                    setUserInfo(data);
                    if (verboseSetting > 1) { console.debug("Fetched userInfo:\n", data) }
                    return data;
                } catch (error) {
                    console.error('Failed to fetch user data:', error);
                    throw error;
                }
            } else {
                return userInfo;
            }
        }
    }, [token, userInfo, verboseSetting]);

    const handleLogin = useCallback((token) => {
        setToken(token);
        localStorage.setItem('token', token);
    }, []);

    const handleLogout = useCallback(() => {
        localStorage.removeItem('token');
        setToken(null);
    }, []);

    // Handle user data on token change
    useEffect(() => {
        if (token) {
            fetchUserInfo(true).catch((error) => { });
        } else {
            setUserInfo(null);
        }
        // eslint-disable-next-line
    }, [token]);

    return (
        <AuthenticationContext.Provider value={{ token, handleLogin, handleLogout, userInfo, fetchUserInfo }}>
            {children}
        </AuthenticationContext.Provider>
    );
};
