import React from 'react';
import { Error } from '@mui/icons-material';
import theme from '../../styles/theme';

const ErrorHelperText = ({ error, children }) => (
    <span
        style={{
            fontSize: theme.typography.helper.fontSize,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: `calc(0.5 * ${theme.typography.helper.fontSize})`,
            color: 'red',
        }}
    >
        {
            error &&
            <>
                <Error
                    style={{
                        fontSize: `calc(1.2 * ${theme.typography.helper.fontSize})`,
                        marginBottom: `calc(0.1 * ${theme.typography.helper.fontSize})`,
                    }}
                />
                {children}
            </>
        }
    </span>
);

export default ErrorHelperText;
