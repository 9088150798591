import React, { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import { useWriteStoryState } from './useWriteStoryState';
import usePromptDialog from '../Shared/PromptDialog/usePromptDialog';
import { LoadingSpinnerWithOverlay } from '../Shared/Progresses/LoadingSpinnerWithOverlay';
import useCommonUtilities from '../../hooks/useCommonUtilities';
import { NotesRounded } from '@mui/icons-material';
import PromptDialog from '../Shared/PromptDialog/PromptDialog';
import { usePupuCopilot } from '../PupuCopilot/usePupuCopilot';
import AppAppBar from '../Shared/AppAppBar';
import withRoot from '../../withRoot';
import { useAuthentication } from '../../hooks/useAuthentication';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { WriteStoryModes, storyContentMapping } from './storyUtils';
import RenderErrorPage from '../Shared/RenderErrorPage';


const WriteStoryPage = () => {
    const { story_type, story_id } = useParams();
    const { promptVisibility, lang, handleAPIError } = useCommonUtilities();
    const { userInfo } = useAuthentication();
    const { t } = useTranslation();

    const StoryContent = storyContentMapping[story_type];

    const {
        writeStoryState,
        initEmptyStoryState,
        loadStoryState,
        tryCreateNewStory,
        dispatchAndUpdateStory,
    } = useWriteStoryState({ storyType: story_type });

    const copilotManager = usePupuCopilot({ writeStoryState, dispatchAndUpdateStory });

    // Props passed down to children
    const writeStoryProps = {
        writeStoryState, tryCreateNewStory, dispatchAndUpdateStory, copilotManager
    };

    // Prompt dialog handlers and states
    const updatePrompts = (newPrompts) => {
        dispatchAndUpdateStory({ updateObject: { prompts: newPrompts } });
    };
    const {
        promptDialogOpen,
        handlePromptDialogClose,
        handlePromptDialogOpen,
    } = usePromptDialog({ onDialogClose: updatePrompts });

    // Load story and user app data when page mounts
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const promptTemplateName = userInfo?.promptSettings?.defaultPromptTemplate ?? 'default';

    const onMountPage = async () => {
        setLoading(true);
        const promises = [];
        promises.push(story_id ? loadStoryState(story_id) : initEmptyStoryState({ promptTemplateName }));
        await Promise.all(promises)
            .then(() => setError(null))
            .catch((error) => {
                setError(error);
                handleAPIError(error);
                console.error(error);
            });
        setLoading(false);
    };

    useEffect(() => {
        if (StoryContent) {
            onMountPage();
        }
        // eslint-disable-next-line
    }, [lang]);

    if (error?.response?.status === 404) {
        return <RenderErrorPage message={t('errors.storyNotFound')} />;
    }

    if (!StoryContent) {
        return <RenderErrorPage message={t('errors.pageNotFound')} />;
    }

    if (loading || userInfo == null) {
        return <LoadingSpinnerWithOverlay size={70} />;
    }

    const { story } = writeStoryState;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                width: '100vw',
                backgroundColor: 'background.main',
                paddingTop: {xs: '100px', sm: '160px'},
                paddingBottom: {xs: '220px', sm: '160px'},
            }}
        >
            <AppAppBar navPages={['home']} />

            <StoryContent writeStoryProps={writeStoryProps} mode={WriteStoryModes.CREATE} />

            {/* Admin prompt utlities */}
            {userInfo?.role === 'admin' &&
                <>
                    {promptVisibility &&
                        <Button
                            children={<NotesRounded sx={{ fontSize: '30px' }} />}
                            onClick={() => {
                                console.log(story);     // For dev purposes
                                handlePromptDialogOpen();
                            }}
                            sx={{ position: 'fixed', top: 360, right: 0, zIndex: 110 }}
                        />
                    }
                    <PromptDialog
                        promptDialogOpen={promptDialogOpen}
                        handlePromptDialogClose={handlePromptDialogClose}
                        promptObject={story?.prompts}
                    />
                </>
            }
        </Box>
    );
}

export default withRoot(WriteStoryPage);
