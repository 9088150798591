import { useEffect, useState } from "react";
import { runPromptForImageGeneration, runPromptsForTextCompletions } from "../../../../utils/promptUtils";
import { useAuthentication } from "../../../../hooks/useAuthentication";
import useCommonUtilities from "../../../../hooks/useCommonUtilities";
import happy_cloud from '../../../../assets/happy_cloud.png';
import sorry_cloud from '../../../../assets/sorry_cloud.png';
import { useTranslation } from "react-i18next";
import ApiServices from "../../../../services/ApiServices";


export const useStoryComposer = ({ writeStoryProps }) => {
    const { t } = useTranslation();
    const { token, userInfo, fetchUserInfo } = useAuthentication();
    const { lang, verboseSetting, openDialog } = useCommonUtilities();

    // Unpack
    const { writeStoryState, dispatchAndUpdateStory } = writeStoryProps;
    const { story } = writeStoryState;
    const gradeLevel = userInfo?.personalInfo?.gradeLevel;
    const storyComposer = story.data?.storyComposer;
    const _id = story._id;

    // States
    const [currentAction, setCurrentAction] = useState(null);

    // Props needed to run text completions
    const runTextCompletionsProps = {
        doc: story,
        state: writeStoryState,
        lang,
        token,
        mock: false,
    };

    useEffect(() => {
        if (storyComposer.trigger === true) {
            dispatchAndUpdateStory({
                _id, updateObject: {
                    'data.storyComposer.trigger': false,
                }, method: 'write', verbose: verboseSetting
            });

            onGenSampleEssay({ index: 0, verbose: verboseSetting });
        }
        // eslint-disable-next-line
    }, []);

    // Button click handlers
    const onGenSampleEssay = async ({ index, verbose = verboseSetting }) => {
        try {
            const { sampleEssay } = await genSampleStory({ index, verbose });

            await Promise.all([
                analyzeSampleEssay({ sampleEssay, index, verbose }),
                extractGoodPhrases({ sampleEssay, index, verbose }),
                genStoryIllustration({ sampleEssay, index, verbose }),
            ]);

            ApiServices.getInstance().updateUserStats(token, 'num_sample_essay_generated', 1);
        } catch (error) {
            console.error('Failed onGenSampleessay', error);
            openDialog({ content: t('errors.system_bug') })
        };

        setCurrentAction(null);
        fetchUserInfo().catch(console.error);
    };

    const handleRateStory = (rating, fieldName = 'satisfaction') => {
        if (story?.userFeedback?.[fieldName] == null) {
            if (rating < 4) {
                openDialog({
                    content: t('userFeedback.sorryfulReply'),
                    image: (
                        <><img src={sorry_cloud} alt="sorry_cloud" style={{ maxHeight: '90px', height: 'auto' }} /></>
                    ),
                });
            } else {
                openDialog({
                    content: t('userFeedback.thankfulReply'),
                    image: (
                        <><img src={happy_cloud} alt="happy_cloud" style={{ maxHeight: '90px', height: 'auto' }} /></>
                    ),
                });
            }
        };

        dispatchAndUpdateStory({
            _id, updateObject: {
                [`userFeedback.${fieldName}`]: rating,
            }, method: 'write', verbose: verboseSetting
        });
    }

    // Action functions
    const genSampleStory = async ({ index, verbose }) => {
        const action = 'genSampleStory';
        setCurrentAction(action);

        // Prepare
        const revisedOutlines = story.data?.outlineArchitect?.revisedOutlines;
        if (
            !(Array.isArray(revisedOutlines) && revisedOutlines.length > 0) ||
            revisedOutlines[revisedOutlines.length - 1] === '') {
            return;
        }

        const interimState = {
            'temp.userGradeLevel': gradeLevel,
            'temp.finalOutline': revisedOutlines[revisedOutlines.length - 1]
        };

        // Run prompts
        const result = await runPromptsForTextCompletions({
            action: `${action}_${index + 1}`,                               // Use different prompts to create sample essays
            interimState,
            verbose,
            ...runTextCompletionsProps
        });

        // Post propress
        const sampleEssay = result['1_result']['essay'];
        const title = result['1_result']?.title;

        dispatchAndUpdateStory({
            _id, updateObject: {
                'metadata.title': title,
                'data.storyComposer.trigger': false,
            }, method: 'write', verbose: verbose
        });
        dispatchAndUpdateStory({
            _id, updateObject: {
                'data.storyComposer.sampleStories': {
                    sampleEssay,
                    'metadata': { 'title': title },
                },
            }, method: 'append', verbose: verbose
        });

        return { sampleEssay, title };
    };

    const analyzeSampleEssay = async ({ sampleEssay, index, verbose }) => {
        const action = 'analyzeSampleEssay';
        setCurrentAction(action);

        // Prepare
        const interimState = {
            'temp.userGradeLevel': gradeLevel,
            'temp.sampleStory': sampleEssay,
        };

        // Run prompts
        const result = await runPromptsForTextCompletions({
            action,
            interimState,
            verbose,
            ...runTextCompletionsProps
        });

        // Post propress
        const analyzeResult = result['1_result'];

        dispatchAndUpdateStory({
            _id, updateObject: {
                [`data.storyComposer.sampleStories.${index}.analysis.corePlot`]: analyzeResult.corePlot,
                [`data.storyComposer.sampleStories.${index}.analysis.compositionStructure`]: analyzeResult.compositionStructure,
            }, method: 'write', verbose: verbose
        });
    };

    const extractGoodPhrases = async ({ sampleEssay, index, verbose }) => {
        const action = 'extractGoodPhrases';
        setCurrentAction(action);

        // Prepare
        const interimState = {
            'temp.userGradeLevel': gradeLevel,
            'temp.sampleStory': sampleEssay,
        };

        // Run prompts
        const result = await runPromptsForTextCompletions({
            action,
            interimState,
            verbose,
            ...runTextCompletionsProps
        });

        // Post propress
        const extractResult = result['1_result'];

        dispatchAndUpdateStory({
            _id, updateObject: {
                [`data.storyComposer.sampleStories.${index}.analysis.goodWords`]: extractResult.goodWords,
                [`data.storyComposer.sampleStories.${index}.analysis.goodPhrases`]: extractResult.goodPhrases,
            }, method: 'write', verbose: verbose
        });
    };

    const genStoryIllustration = async ({ sampleEssay, index, verbose }) => {
        const action = 'genIllustrationPrompt';
        setCurrentAction(`${action}_${index}`);

        // Prepare
        const interimState = {
            'temp.scene': sampleEssay,
        };

        // Run prompts
        const result = await runPromptsForTextCompletions({
            action,
            interimState,
            verbose,
            ...runTextCompletionsProps,
        });

        // Post propress
        const imagePrompt = result['3_result'];

        const genImageResult = await runPromptForImageGeneration({
            action: 'genIllustration',
            interimState: { 'temp.imagePrompt': imagePrompt },
            verbose,
            ...runTextCompletionsProps,
        });

        const coverImage = genImageResult['1_result'];

        dispatchAndUpdateStory({
            _id, updateObject: {
                [`data.storyComposer.sampleStories.${index}.metadata.coverImages.images`]: [coverImage],
            }, method: 'write', verbose: verbose
        });

        dispatchAndUpdateStory({
            _id, updateObject: {
                'metadata.coverImages.images': coverImage,
            }, method: 'append', verbose: verbose
        });
    };

    return {
        currentAction,
        onGenSampleEssay,
        handleRateStory,
    };
};
