import A1WriteEssayContent from "./A1WriteEssay/A1WriteEssayContent";
import C1WritePCardContent from "./C1WritePCard/C1WritePCardContent";

export const storyContentMapping = {
    'a1': A1WriteEssayContent,          // 常规作文辅导
    'c1': C1WritePCardContent,     // 灵感卡牌创生
};

export const storyCollectionMapping = {
    'a1': 'essays',
    'c1': 'pcards',
};

export const collectionToWriteUrlMapping = {
    'essays': '/write-story/a1',
    'pcards': '/write-story/c1',
};

export const collectionToReadUrlMapping = {
    'essays': '/read-story/a1',
    'pcards': '/read-story/c1',
};

export const WriteStoryModes = {
    CREATE: 'create',
    EDIT: 'edit',
    VIEW: 'view',
};

export const importanceWeightDefault = 1.5;
export const ratingWeightDefault = 1;
export const thresholdDefault = 3;

export function sortImprovementAreas({ improvementAreas, limit = 4, importanceWeight = importanceWeightDefault, ratingWeight = ratingWeightDefault, order = 'descend' }) {
    const copiedAreas = JSON.parse(JSON.stringify(improvementAreas));

    let sortedAreas = copiedAreas.map(area => ({
        ...area,
        importance: area.importance ? Number(area.importance) : 2,
        rating: area.rating ? Number(area.rating) : 2
    }))
        .map(area => ({
            ...area,
            score: (area.importance * importanceWeight) + ((4 - area.rating) * ratingWeight)
        }))
        .sort((a, b) => order === 'descend' ? (b.score - a.score) : (a.score - b.score));

    // Limit the number of items returned
    if (limit !== null) {
        sortedAreas = sortedAreas.slice(0, limit);
    }
    return sortedAreas;
};

export function getAreaWithRatingBelowThresh({ improvementAreas, threshold = thresholdDefault }) {
    for (const area of improvementAreas) {
        if (area.rating < threshold) {
            return area;
        }
    }
    return null;
}
