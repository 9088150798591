import { Box, Container, Link, Typography } from "@mui/material";
import React, { useEffect } from "react";
import withRoot from "../../withRoot";
import AppAppBar from "../Shared/AppAppBar";
import AppFooter from "../Shared/AppFooter";
import DividingLine from "../Shared/DividingLine";
import WeChatLogin from "../Shared/WeChatLogin";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { useAuthentication } from "../../hooks/useAuthentication";
import { Link as RouterLink } from 'react-router-dom';


function WeChatLoginPage() {
    const { t } = useTranslation();
    const { token } = useAuthentication();
    const navigate = useNavigate();

    useEffect(() => {
        if (token) {
            navigate(`/home`);
        }
    }, [token, navigate]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                width: '100vw',
                backgroundColor: 'background.main',
            }}
        >
            <AppAppBar />

            <Container maxWidth='xs'
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '50px'
                }}
            >
                <Typography
                    variant='h4'
                    sx={{
                        textAlign: 'center',
                        fontWeight: 400,
                        marginBottom: 4,
                        color: 'primary.contrastText',
                    }}
                >
                    {t('AuthPage.loginHeader')}
                </Typography>

                <WeChatLogin />

                <DividingLine />

                <Typography
                    sx={{ color: 'text.secondary', marginTop: 1, fontSize: '1em' }}
                >
                    {t('AuthPage.moreLoginOptions')}
                    <Link
                        component={RouterLink}
                        to={`/login`}
                        color="primary.contrastText"
                        style={{ textTransform: 'none', textDecoration: 'none', fontWeight: 500, fontSize: '1em', marginLeft: '5pt' }}
                    >
                        {t('AuthPage.mobileLogin')}
                    </Link>
                </Typography>

            </Container>

            <AppFooter />
        </Box>
    );
};

export default withRoot(WeChatLoginPage);
