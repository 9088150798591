import useCommonUtilities from "../../hooks/useCommonUtilities";
import { useCallback, useReducer } from "react";
import { storyDeckStateReducer } from "../../reducers/storyDeckStateReducer";
import ApiServices from "../../services/ApiServices";
import { useAuthentication } from "../../hooks/useAuthentication";
import { collectionToWriteUrlMapping, storyCollectionMapping } from "./storyUtils";


export const useWriteStoryState = ({ storyType }) => {
    const { lang, verboseSetting } = useCommonUtilities();
    const { token } = useAuthentication();
    const collection = storyCollectionMapping[storyType];

    const [writeStoryState, dispatch] = useReducer(storyDeckStateReducer, {
        story: null,
        children: {},
    });

    const initEmptyStoryState = useCallback(async ({ promptTemplateName = 'default' }) => {
        if (verboseSetting > 1) console.debug(`Initializing empty story with template: ${promptTemplateName}`);

        return Promise.all([
            ApiServices.getInstance().fetchTemplate(token, lang, collection, promptTemplateName),
        ])
            .then(([storyTemplate]) => {
                const emptyDeckState = {
                    story: storyTemplate,
                    children: {},
                };
                dispatch({
                    type: 'RESET_STATE',
                    initialState: emptyDeckState,
                });
            });
    }, [lang, verboseSetting, token, collection]);

    const loadStoryState = useCallback(async (storyID) => {
        if (verboseSetting > 1) console.debug("Loading story: ", storyID, " lang: ", lang);

        return Promise.all([
            ApiServices.getInstance().getDoc(token, storyID, lang, collection),
        ])
            .then(([storyDoc]) => {
                const deckState = {
                    story: storyDoc,
                    children: {},
                };
                dispatch({
                    type: 'RESET_STATE',
                    initialState: deckState,
                });
            });
    }, [lang, verboseSetting, token, collection]);

    const tryCreateNewStory = useCallback(async ({ story, verbose = verboseSetting }) => {
        let _id = story._id;
        if (_id == null) {
            // Try add a new story document on database
            const addResult = await ApiServices.getInstance().addDoc(token, lang, collection, { ...story, prompts: null });
            _id = addResult.inserted_id;
            const docUpdates = addResult.doc_updates;
            if (verbose > 1) { console.debug(`Created story with ID ${_id}`); }

            // Update user statistic to reflect a new story added
            ApiServices.getInstance().updateUserStats(token, `num_stories_created`, 1);

            dispatch({
                type: 'UPDATE_STORY',
                updateObject: { ...docUpdates, _id },
            });

            const newUrl = `${collectionToWriteUrlMapping[collection]}/${_id}`;
            window.history.replaceState({ path: newUrl }, '', newUrl);
        };
        return _id;
    }, [lang, token, collection, verboseSetting]);

    const dispatchAndUpdateStory = useCallback(async ({ _id, updateObject, action = 'UPDATE_STORY', method = 'write', verbose = verboseSetting }) => {
        dispatch({ type: action, updateObject, method, verbose });

        if (_id == null) { return; }
        const updateResult = await ApiServices.getInstance().updateDoc(token, _id, lang, collection, updateObject, method);
        if (verbose > 1) { console.debug(`Story ${_id}:\nupdateDoc call returned matched count ${updateResult.matched_count}`, updateObject) }
    }, [dispatch, lang, token, collection, verboseSetting]);

    return {
        writeStoryState,
        initEmptyStoryState,
        loadStoryState,
        tryCreateNewStory,
        dispatchAndUpdateStory,
    }
};
