import { setObjectValueByPath } from "../utils/utils";


function updateStateObject(stateObject, updateObject, method, verbose = 0) {
    const newStateObject = JSON.parse(JSON.stringify(stateObject));

    for (let field in updateObject) {
        const keys = field.split('.');
        const newValue = updateObject[field];

        if (verbose > 2) {
            console.log(`Updating ${keys.join('.')} with method ${method} and new value`, newValue);
        }

        setObjectValueByPath(newStateObject, keys, newValue, method);
    }

    if (verbose > 0) {
        // console.log(`Updated state with method ${method}:`, updateObject);
    }

    return newStateObject;
}


export function storyDeckStateReducer(state, action) {
    switch (action.type) {

        case 'RESET_STATE':
            return { ...action.initialState };

        // case 'UPDATE_DECK': {
        //     const { updateObject, method = 'write', verbose = 0 } = action;

        //     // Safety checks
        //     if (!updateObject || !method || Object.keys(updateObject).length === 0) {
        //         console.error('Error UPDATE_DECK: Must provide non-empty updateObject and method.');
        //         return state;
        //     };
        //     const ACCEPTED_METHODS = ['write', 'append'];
        //     if (!ACCEPTED_METHODS.includes(method)) {
        //         console.error(`Error UPDATE_DECK: Unknown method '${method}'.`);
        //         return state;
        //     };

        //     const newDeck = updateStateObject(state.deck, updateObject, method, verbose);

        //     return { ...state, deck: newDeck };
        // };

        case 'UPDATE_STORY': {
            const { updateObject, method = 'write', verbose = 0 } = action;

            // Safety checks
            if (!updateObject || !method || Object.keys(updateObject).length === 0) {
                console.error('Error UPDATE_STORY: Must provide non-empty updateObject and method.');
                return state;
            };
            const ACCEPTED_METHODS = ['write', 'append'];
            if (!ACCEPTED_METHODS.includes(method)) {
                console.error(`Error UPDATE_STORY: Unknown method '${method}'.`);
                return state;
            };

            const newStory = updateStateObject(state.story, updateObject, method, verbose);

            return { ...state, story: newStory };
        };

        default:
            throw new Error(`Unknown action type: ${action.type}`);
    };
}