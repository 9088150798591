import { useState } from "react";
import useCommonUtilities from "../../../hooks/useCommonUtilities";
import { useAuthentication } from "../../../hooks/useAuthentication";
import { useTranslation } from "react-i18next";
import { runPromptsForTextCompletions } from "../../../utils/promptUtils";


const useIdeaIntakeC1 = ({ writeStoryState, tryCreateNewStory, dispatchAndUpdateStory }) => {
    const { lang, verboseSetting, openDialog } = useCommonUtilities();
    const { token, userInfo, fetchUserInfo } = useAuthentication();
    const { t } = useTranslation();

    // Unpack 
    const { story } = writeStoryState;
    const ideaIntake = story?.data?.ideaIntake;
    const gradeLevel = userInfo?.personalInfo?.gradeLevel;

    // States
    const [currentAction, setCurrentAction] = useState(null);

    const [userIdea, setUserIdea] = useState(ideaIntake?.userIdea);
    const handleUserIdeaChange = (event) => {
        setUserIdea(event.target.value);
    };

    const [selectedCardCategory, setSelectedCardCategory] = useState(story?.cardCategory);

    // Props needed to run text completions
    const runTextCompletionsProps = {
        doc: story,
        state: writeStoryState,
        lang,
        token,
        mock: false,
    };

    const storyTopic = ''

    // Button click handler
    const runIdeaIntake = async ({ verbose = verboseSetting }) => {
        try {
            setCurrentAction('createNewStory');
            const storyID = await tryCreateNewStory({ story });

            const results = await Promise.all([
                // Check userOutline to see if it's comprehensive
                checkUserOutline({ _id: storyID, verbose }),

                // Conditionally run explainStoryTopic if storyTopic is not empty
                ...(storyTopic !== '' ? [explainStoryTopic({ _id: storyID, verbose })] : []),
            ]);

            const checkPassed = results[0];
            const explainedTopic = storyTopic ? results[1] : undefined;

            if (!checkPassed) {
                // Identify areas to improve
                await identifyImprovementAreas({ _id: storyID, explainedTopic, verbose });
            };

        } catch (error) {
            console.error('Failed runIdeaIntake', error);
            openDialog({ content: t('errors.system_bug') })
        } finally {
            setCurrentAction(null);
            fetchUserInfo().catch(console.error);
        }
    };

    // Action functions
    const checkUserOutline = async ({ _id, verbose }) => {
        const action = 'checkUserOutline';
        setCurrentAction(action);

        // Prepare
        const interimState = {
            'data.ideaIntake.userIdea': userIdea,
            'data.outlineArchitect': {},
            'data.storyComposer': { sampleStories: [] },
            'data.copilot': { messages: {} },
            'states.currentState': 'initial',
            'metadata.title': `${t('writeStory.defaultStoryName')}`,
            'metadata.description': userIdea,
        };

        dispatchAndUpdateStory({ _id, updateObject: interimState });

        // Run prompts
        const result = await runPromptsForTextCompletions({
            action,
            interimState,
            verbose,
            docID: _id,
            ...runTextCompletionsProps,
        });

        // Post process
        const checkPassed = result['1_result']['isComprehensive'];

        if (checkPassed) {
            dispatchAndUpdateStory({
                _id, updateObject: {
                    'states.currentState': 'completed',
                    'data.outlineArchitect.revisedOutlines': [userIdea],
                }
            });
        }
        return checkPassed;
    };

    const explainStoryTopic = async ({ _id, verbose }) => {
        const action = 'explainStoryTopic';
        setCurrentAction(action);

        // Prepare
        const interimState = {
            'data.ideaIntake.userIdea': userIdea,
            'temp.userGradeLevel': gradeLevel,
        };

        // Run prompts
        const result = await runPromptsForTextCompletions({
            action,
            interimState,
            verbose,
            docID: _id,
            ...runTextCompletionsProps,
        });

        const stepOneResult = result?.['1_result'];

        // Post propress
        const updateObject = {
            'data.ideaIntake.proposedTitle': stepOneResult?.['proposedTitle'],
            'data.ideaIntake.keyElements': stepOneResult?.['keyElements'],
            'data.ideaIntake.stylisticForm': stepOneResult?.['stylisticForm'],
            'data.ideaIntake.structure': stepOneResult?.['structure'],
        }
        dispatchAndUpdateStory({ _id, updateObject });

        return updateObject;
    };

    const identifyImprovementAreas = async ({ _id, explainedTopic = {}, verbose }) => {
        const action = 'identifyImprovementAreas';
        setCurrentAction(action);

        // Prepare
        const interimState = {
            'data.ideaIntake.userIdea': userIdea,
            'temp.userGradeLevel': gradeLevel,
            ...explainedTopic,
        };

        // Run prompts
        const result = await runPromptsForTextCompletions({
            action,
            interimState,
            verbose,
            docID: _id,
            ...runTextCompletionsProps,
        });

        // Post propress
        const improvementAreas = result['1_result']['improvementAreas'];
        dispatchAndUpdateStory({
            _id, updateObject: {
                'data.outlineArchitect.improvementAreas': [improvementAreas],
                'data.outlineArchitect.trigger': true,
                'states.currentState': 'inProgress',
            }
        });
    };

    return {
        // State controls
        currentAction,
        userIdea,
        handleUserIdeaChange,
        selectedCardCategory,
        setSelectedCardCategory,

        // Functions
        runIdeaIntake
    }
};

export default useIdeaIntakeC1;
