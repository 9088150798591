import React from 'react';
import { StyledContainedButton } from '../../styles/StyledButtons';
import { useTranslation } from 'react-i18next';
import { ShareRounded } from '@mui/icons-material';

const ShareButton = ({ btnText, shareUrl, shareTitle, sx }) => {
    const { t } = useTranslation();

    const handleShare = async () => {
        if (navigator.share) {
            // Use Web Share API if supported
            try {
                await navigator.share({
                    title: t('share.defaultShareText'),
                    text: shareTitle ?? t('puffLearn'),
                    url: shareUrl,
                });
            } catch (error) {
                console.error('Error sharing content', error);
            }
        } else {
            // Fallback to copying URL to clipboard
            try {
                await navigator.clipboard.writeText(shareUrl);
                alert(t('share.shareLinkCopied')); // Provide feedback to the user
            } catch (error) {
                console.error('Failed to copy URL', error);
                alert(t('share.failedToCopyLink')); // Fallback feedback
            }
        }
    };

    return (
        <StyledContainedButton onClick={handleShare} sx={sx} variant='contained'>
            <ShareRounded sx={{ marginRight: 1 }} />
            {btnText ?? t('share.share')}
        </StyledContainedButton>
    );
};

export default ShareButton;
