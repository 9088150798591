import pupugushiji_svg from '../../assets/pupugushiji.svg';
import puffwonderland_svg from '../../assets/puffwonderland.svg';
import book_logo_png from '../../assets/book_logo.png';
import { Box } from '@mui/material';

const RenderTextLogo = ({ logoHeight = '50px', lang = 'zh', showIcon = true }) => {
    const textLogo = lang === 'zh' ? pupugushiji_svg : puffwonderland_svg;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: logoHeight,
                gap: `calc(${logoHeight} * 0.1)`,
            }}
        >
            {
                showIcon &&
                <img
                    src={book_logo_png}
                    alt="logo"
                    style={{
                        height: `calc(${logoHeight} * 2.6)`,
                        // marginBottom: '14px'
                    }}
                />
            }

            <img
                src={textLogo}
                alt="Puff Text Logo"
                style={{ height: logoHeight }}
            />
        </Box>
    );
};

export default RenderTextLogo;
