import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Switch from '@mui/material/Switch';
import useCommonUtilities from '../../hooks/useCommonUtilities';
import { useTranslation } from 'react-i18next';
import MessageBubble from './MessageBubble';
import pupu_avatar from "../../assets/puff_copilot.png";
import { useAuthentication } from '../../hooks/useAuthentication';
import StringAvatar from './StringAvatar';
import ApiServices from '../../services/ApiServices';
import { languageNames, supportedLangs } from '../../configs/appConfig';
import CustomModal from './CustomModal';
import CustomTextField from './CustomTextField';
import { StyledContainedButton } from '../../styles/StyledButtons';
import { CircularProgress, InputAdornment } from '@mui/material';
import { useTheme } from '@emotion/react';
import { CheckCircleOutlineRounded } from '@mui/icons-material';
import ErrorHelperText from './ErrorHelperText';
import withRoot from '../../withRoot';


const AccountModal = ({ isOpen, onClose, allowedSettings = ["status", "gradeLevel", "expirationDate", "credits", "language"] }) => {
    const { lang, setLanguage, handleShowPromptToggle, promptVisibility } = useCommonUtilities();
    const { t } = useTranslation();
    const theme = useTheme();
    const { token, userInfo, fetchUserInfo } = useAuthentication();

    const personalInfo = userInfo?.personalInfo;
    const nickname = personalInfo?.nickname ?? '';
    const tutorialCompleted = userInfo?.onboardingStatus?.tutorialCompleted;

    const [editedNickname, setEditedNickname] = useState(nickname);
    const [loading, setLoading] = useState(false);
    const [saveSuccessful, setSaveSuccessful] = useState(false);
    const [accountError, setAccountError] = useState({ 'nickname': null });

    const availableGradeLevels = ["三年级", "四年级", "五年级", "六年级"];
    const [userGradeLevel, setUserGradeLevel] = useState(
        availableGradeLevels.includes(personalInfo?.gradeLevel)
            ? personalInfo?.gradeLevel
            : '四年级'  // Default grade level to show
    );

    const preSaveChecks = () => {
        if (!editedNickname) {
            setAccountError({ ...accountError, 'nickname': t('account.errors.nicknameError') });
            return false;
        }
        return true;
    };

    const saveChanges = async () => {
        try {
            const promises = [];
            if (editedNickname !== nickname) {
                promises.push(ApiServices.getInstance().setUserNickname(token, editedNickname));
            }
            if (userGradeLevel !== personalInfo?.gradeLevel) {
                promises.push(ApiServices.getInstance().setUserGradeLevel(token, userGradeLevel));
            }
            if (!tutorialCompleted) {
                promises.push(ApiServices.getInstance().setUserTutorialCompleted(token, true));
            }
            await Promise.all(promises);
            return true;
        } catch (error) {
            console.error(error);
            return false;
        } finally {
            fetchUserInfo(true).catch(console.error);
        }
    };

    const onClickSave = async ({ closeAfterSave = true }) => {
        if (!preSaveChecks()) {
            return false;
        }

        setLoading(true);
        const saveSuccessful = await saveChanges();
        setLoading(false);

        if (saveSuccessful) {
            if (closeAfterSave) {
                onClose();
            }
            else {
                setSaveSuccessful(true);
                setTimeout(() => setSaveSuccessful(false), 3000);
            }
        }
    };

    const onCloseModal = async () => {
        if (!preSaveChecks()) {
            return;
        };
        onClose();
        saveChanges();
    };

    const CustomTextFieldStyles = {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: 'none',
            },
            '&:hover fieldset': {
                border: 'none',
            },
            '&.Mui-focused fieldset': {
                border: 'none',
            },
            '& .MuiInputBase-input': {
                color: 'text.primary',
            },
            height: '46px'
        }
    };

    const UserGradeStyles = {
        width: '100%',
        backgroundColor: 'background.main300',
        boxShadow: theme.custom.shadow.xsmall,
        borderRadius: theme.custom.radius.xsmall
    };

    return (
        <CustomModal isOpen={isOpen} onClose={onCloseModal} >

            <React.Fragment>
                <StringAvatar name={nickname} size='100px' showInitial={true} />

                <Grid container direction="column" alignItems="center" justifyContent='center' sx={{ width: '100%', mt: '10px', gap: 3, color: 'text.primary' }}>

                    {/* Nickname */}
                    <CustomTextField
                        header={t('account.nickname')}
                        disabled={loading}
                        placeholder={t('account.enterNickname')}
                        value={editedNickname}
                        setValue={(e) => { setEditedNickname(e.target.value); setAccountError({ ...accountError, 'nickname': null }) }}
                        autoFocus={!Boolean(tutorialCompleted)}
                        sx={{ ...CustomTextFieldStyles }}
                        helperText={<ErrorHelperText error={!!accountError.nickname}>{accountError.nickname}</ErrorHelperText>}
                    />

                    {/* Mobile */}
                    <CustomTextField
                        header={t('account.mobile_number')}
                        disabled={true}
                        value={personalInfo?.mobile_number ?? ''}
                        sx={{ ...CustomTextFieldStyles, backgroundColor: 'background.gray300' }}
                        startAdornments={<InputAdornment position="start">+86</InputAdornment>}
                        showAdornments='always'
                    />

                    {/* Status */}
                    {allowedSettings.includes('status') &&
                        <Grid item container direction="row" alignItems="center" justifyContent='center' sx={{ width: '100%' }}>
                            <Grid item sx={{ width: '50%' }}>
                                <Typography variant="body">
                                    {t('account.status')}:
                                </Typography>
                            </Grid>
                            <Grid item sx={{ width: '50%', justifyContent: 'center', display: 'flex' }}>
                                <Typography variant="body">
                                    {t(`subscription.${userInfo?.subscription?.status}`)}
                                </Typography>
                            </Grid>
                        </Grid>
                    }

                    {/* Expiration Date */}
                    {allowedSettings.includes('expirationDate') &&
                        <Grid item container direction="row" alignItems="center" justifyContent='center' sx={{ width: '100%' }}>
                            <Grid item sx={{ width: '50%' }}>
                                <Typography variant="body">
                                    {t('account.expirationDate')}:
                                </Typography>
                            </Grid>
                            <Grid item sx={{ width: '50%', justifyContent: 'center', display: 'flex' }}>
                                <Typography variant="body">
                                    {(userInfo?.subscription?.periodEnd ?? '').split(" ")[0]}
                                </Typography>
                            </Grid>
                        </Grid>
                    }

                    {/* Credits */}
                    {allowedSettings.includes('credits') &&
                        <Grid item container direction="row" alignItems="center" justifyContent='center' sx={{ width: '100%' }}>
                            <Grid item sx={{ width: '50%' }}>
                                <Typography variant="body">
                                    {t('account.creditBalance')}:
                                </Typography>
                            </Grid>
                            <Grid item sx={{ width: '50%', justifyContent: 'center', display: 'flex' }}>
                                <Typography variant="body">
                                    {userInfo?.credits?.balance}
                                </Typography>
                            </Grid>
                        </Grid>
                    }

                    {/* User Grade */}
                    {allowedSettings.includes('gradeLevel') &&
                        <Grid item container direction="row" alignItems="center" justifyContent='center' sx={{ ...UserGradeStyles, height: '46px', }}>
                            <Grid item sx={{ width: '50%' }}>
                                <Typography sx={{ pl: '9pt' }} >
                                    {t('account.gradeLevel')}:
                                </Typography>
                            </Grid>
                            <Grid item sx={{ width: '50%', justifyContent: 'flex-end', display: 'flex' }}>
                                <FormControl sx={{ width: '100%' }}>
                                    <Select
                                        labelId="gradeLevel-select-label"
                                        id="gradeLevel-select"
                                        value={userGradeLevel}
                                        onChange={(e) => setUserGradeLevel(e.target.value)}
                                        sx={{
                                            height: '40px',
                                            borderTopLeftRadius: 0,
                                            borderBottomLeftRadius: 0,
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: 'none',
                                                borderLeft: '2px solid',
                                                borderColor: 'primary.light',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                border: 'none',
                                                borderLeft: '2px solid',
                                                borderColor: 'primary.light',
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                border: 'none',
                                                borderLeft: '2px solid',
                                                borderColor: 'primary.light',
                                            },
                                        }}
                                    >
                                        {availableGradeLevels.map((gradeLevel) => (
                                            <MenuItem key={gradeLevel} value={gradeLevel}>
                                                {gradeLevel}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    }

                    {/* Language */}
                    {allowedSettings.includes('language') &&
                        <Grid item container direction="row" alignItems="center" justifyContent='center' sx={{ width: '100%' }}>
                            <Grid item sx={{ width: '50%' }}>
                                <Typography variant="body">
                                    {t('account.language')}:
                                </Typography>
                            </Grid>
                            <Grid item sx={{ width: '50%', justifyContent: 'flex-end', display: 'flex' }}>
                                <FormControl sx={{ width: '80%' }}>
                                    <Select
                                        labelId="language-select-label"
                                        id="language-select"
                                        value={lang}
                                        onChange={(e) => setLanguage(e.target.value)}
                                        sx={{
                                            height: '40px',
                                        }}
                                    >
                                        {supportedLangs.map((langCode) => (
                                            <MenuItem key={langCode} value={langCode}>
                                                {languageNames[langCode]}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    }

                    {userInfo?.role === 'admin' &&
                        <>
                            <Typography sx={{ mb: -1, mt: 4, borderBottom: 1, width: '100%', borderColor: 'primary.main', textAlign: 'center' }}>
                                Admin only
                            </Typography>
                            <Grid item container direction="row" alignItems="center" justifyContent='center' sx={{ mt: '0px', width: '100%' }}>
                                <Grid item sx={{ width: '50%' }}>
                                    <Typography variant="body">
                                        Show Prompts:
                                    </Typography>
                                </Grid>
                                <Grid item sx={{ width: '50%', justifyContent: 'center', display: 'flex' }}>
                                    <Switch onChange={handleShowPromptToggle} checked={promptVisibility} />
                                </Grid>
                            </Grid>
                            <Grid item container direction="row" alignItems="center" justifyContent='center' sx={{ mt: '-10px', width: '100%' }}>
                                <Grid item sx={{ width: '50%' }}>
                                    <Typography variant="body">
                                        Prompt Name:
                                    </Typography>
                                </Grid>
                                <Grid item sx={{ width: '50%', justifyContent: 'center', display: 'flex' }}>
                                    {userInfo?.promptSettings?.defaultPromptTemplate}
                                </Grid>
                            </Grid>
                        </>
                    }

                    {!tutorialCompleted &&
                        <Box sx={{ width: '100%' }}>
                            <MessageBubble
                                message={t('account.onboardingGuide')}
                                username={t('writeStory.botName')}
                                avatarImage={pupu_avatar}
                                bgColor='background.main'
                                usernameFontSize='10pt'
                            />
                        </Box>
                    }

                    <StyledContainedButton
                        variant='contained'
                        onClick={() => onClickSave({closeAfterSave: !tutorialCompleted})}
                        disabled={loading}
                        disableRipple
                    >
                        {loading ? (
                            <CircularProgress size={'20px'} sx={{ color: 'primary.contrastText', marginRight: 2 }} />
                        ) : saveSuccessful ? (
                            <CheckCircleOutlineRounded sx={{ color: 'green', fontSize: '20px', marginRight: 2 }} />
                        ) : null}
                        {t('account.confirm')}
                    </StyledContainedButton>
                </Grid>
            </React.Fragment>
        </CustomModal>
    );
};

export default withRoot(AccountModal);
