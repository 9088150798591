import { Box, Typography } from "@mui/material";


export const processNewLines = (input) => {
    if (typeof input !== 'string') {
        return null;
    }

    return input.split('\n').map((line, index, arr) => (
        <span key={index}>
            {line}
            {index !== arr.length - 1 && <br />}
        </span>
    ));
};

export const getBaseURL = () => {
    return `${window.location.protocol}//${window.location.host}`;
};

export const toBoolean = (value) => {
    if (typeof value === 'string') {
        return value.toLowerCase() === 'true';
    }
    return Boolean(value);
};

export const renderFormattedParagraphs = (text, style = {}) => {
    const paragraphs = text.split('\n');
    return (
        <Box>
            {paragraphs.map((paragraph, index) => (
                <Typography key={index} paragraph variant="body1" sx={{ textAlign: 'start', textIndent: '2em', ...style }}>
                    {paragraph.trim()}
                </Typography>
            ))}
        </Box>
    );
}

