import { createTheme } from '@mui/material/styles';

const theme = createTheme({
	palette: {
		primary: {
			main: '#CFBFB6',
			light: '#E6DCD5',
			dark: '#CFA791',
			contrastText: '#596175',
		},
		colors: {
			main: '#D4E8CE',
			green: '#D4E8CE',
			yellow: '#EDD51E',
			blue: '#BEEBE8',
			red: '#DEB4B4',
		},
		background: {
			main800: '#E6E4DA',
			main700: '#EDEBE2',
			main600: '#F0F0E6',
			main: '#F0F0EB',
			main400: '#FCFCF4',
			main300: '#FFFFFD',

			gray700: '#BABCB5',
			gray600: '#CBCEC4',
			gray: '#DDDFD8',
			gray400: '#E9ECE6',
			gray300: '#EEF2EE',
		},
		text: {
			primary: '#444444',
			secondary: '#888888',
		},
	},
	custom: {
		shadow: {
			xsmall: 1,
			small: 2,
			medium: 4,
			modal: 24,
		},
		radius: {
			xxs: '3pt',
			xsmall: '5pt',
			small: '10pt',
			medium: '15pt',
			large: '30pt'
		}
	},
	typography: {
		helper: {
			fontSize: '10pt',
		},
		paragraph: {
			fontSize: '1.1em',

		}
	},
	components: {
		MuiMenu: {
			styleOverrides: {
				paper: {
					borderRadius: '8px',
				}
			}
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					borderRadius: '6px',
					padding: '10px 30px',
					'&:hover': {
						backgroundColor: 'transparent',
					},
				}
			}
		}
	}
});

export default theme;