import React, { useState } from 'react';
import { Box, Typography } from "@mui/material"
import { useTheme } from '@emotion/react';
import { PCardColorMapping, getCardIcon } from './PCardUtils';
import { processNewLines } from '../../../utils/StringUtils';
import PCardExpandedView from './PCardExpandedView';
import CoverImageDisplay from '../CoverImageDisplay';

const PCardCardView = ({ cardData, allowSelection }) => {
    const theme = useTheme();

    const [cardExpanded, setCardExpanded] = useState(false);

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    position: 'relative',
                    boxSizing: 'border-box',
                    width: '96%',
                    maxWidth: '220px',
                    padding: '6px',
                    color: 'text.primary',
                    backgroundColor: PCardColorMapping[cardData.cardCategory],
                    boxShadow: theme.custom.shadow.small,
                    borderRadius: theme.custom.radius.xsmall,
                    cursor: 'pointer',
                }}
                onClick={(event) => { event.stopPropagation(); setCardExpanded(true) }}
            >
                <CoverImageDisplay coverImages={cardData.metadata.coverImages} size={'100%'} />

                {getCardIcon({ collection: cardData.cardCategory })}

                <Typography
                    variant="h6"
                    sx={{
                        color: 'text.primary',
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitLineClamp: 1,
                        WebkitBoxOrient: 'vertical',
                    }}
                >
                    {processNewLines(cardData.metadata.title)}
                </Typography>

                <Typography
                    variant='body2'
                    sx={{
                        textAlign: 'start',
                        width: '90%',
                        color: 'text.secondary',
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        borderTop: 1,
                        borderColor: 'background.gray',
                        paddingRight: allowSelection ? 3 : 0,
                    }}
                >
                    {processNewLines(cardData.metadata.description)}
                </Typography>
            </Box>

            <PCardExpandedView isOpen={cardExpanded} onClose={(event) => { setCardExpanded(false); event.stopPropagation(); }} cardData={cardData} />
        </>
    );
};

export default PCardCardView;
