import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { zIndexValues } from '../../../styles/zIndexValues';

export const LoadingSpinnerWithOverlay = ({ size, color = 'primary.contrastText', sx = {}, alpha = 0.3 }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                top: '0',
                bottom: '0',
                left: '0',
                right: '0',
                zIndex: zIndexValues.overlay,
                backgroundColor: `rgba(100,100,100,${alpha})`,
                ...sx
            }}
        >
            <CircularProgress size={size} sx={{ color: color }} />
        </Box>
    );
};
