import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getBaseURL } from '../../utils/StringUtils';
import ApiServices from '../../services/ApiServices';
import { v4 as uuidv4 } from 'uuid';
import { defaultUserTag, wechatAppID } from '../../configs/appConfig';
import useCommonUtilities from '../../hooks/useCommonUtilities';
import { useAuthentication } from '../../hooks/useAuthentication';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

function WeChatLogin() {
    const navigate = useNavigate();
    const location = useLocation();
    const { handleLogin } = useAuthentication();
    const { openDialog, verboseSetting } = useCommonUtilities();
    const { t } = useTranslation();
    const baseURL = getBaseURL();
    const redirectURL = `${baseURL}/wechat-login/oauth/wechat-callback`;
    const isRedirected = location.pathname === new URL(redirectURL).pathname;

    const RenderLoginProgress = () => (
        <Box
            sx={{
                height: '200px',
                color: 'primary.contrastText',
                mt: '60px',
            }}
        >
            <CircularProgress size={70} />

            <Typography variant='h6'>
                {t('AuthPage.loginInProgress')}
            </Typography>
        </Box>
    );

    useEffect(() => {
        const initWeChatLogin = () => {
            const state = uuidv4();
            sessionStorage.setItem('oauth_state', state);
            // console.log(state);

            new window.WxLogin({
                self_redirect: false,
                id: "login_container",
                appid: wechatAppID,
                scope: "snsapi_login",
                redirect_uri: encodeURIComponent(redirectURL),
                state,
                style: "black",
                // href: "https://ts.pufflearn.com/css/wechat-login-styles.css"
            });
        };

        const handleWeChatAuth = async () => {
            const searchParams = new URLSearchParams(location.search);
            const code = searchParams.get('code');
            const receivedState = searchParams.get('state');
            const originalState = sessionStorage.getItem('oauth_state');
            const error = searchParams.get('error');

            if (code && receivedState === originalState) {
                try {
                    const userTags = defaultUserTag
                    const data = await ApiServices.getInstance().userWechatLogin(code, userTags);
                    handleLogin(data.access_token);
                    navigate(`/home`);
                } catch (error) {
                    openDialog({
                        content: t('AuthPage.errors.wexinLoginFailed'),
                        icon: 'error', onConfirm: () => navigate(`/login`)
                    })
                    console.error('Failed to login:', error);
                }
            } else {
                console.error(`OAuth error: ${error}, received state ${receivedState}, original state ${originalState}`);
                navigate(`/wechat-login`);
            }
        };

        if (verboseSetting > 1) { console.log('Mounting WeChatLogin') }

        if (isRedirected) {
            handleWeChatAuth();
        } else {
            initWeChatLogin();
        }
    }, [location, redirectURL, navigate, isRedirected, openDialog, verboseSetting, handleLogin, t]);

    return (
        <>
            {isRedirected ?
                <RenderLoginProgress /> :
                <div id="login_container"></div>
            }
        </>
    )
}

export default WeChatLogin;
