import React, { useState } from 'react';
import EssayCard from '../../../Shared/Essay/EssayCard';
import CustomModal from '../../../Shared/CustomModal';
import SampleEssayContents from './SampleEssayContents';


const SampleEssayCard = ({ sampleStory, sampleEssayProps, loading, shareURL }) => {
    const [isStoryModalOpen, setIsStoryModalOpen] = useState(false);

    return (
        <React.Fragment>
            <EssayCard
                metadata={{ ...sampleStory?.metadata, description: sampleStory?.sampleEssay }}
                loading={loading}
                onClick={() => setIsStoryModalOpen(true)}
            />

            <CustomModal
                isOpen={isStoryModalOpen}
                onClose={() => setIsStoryModalOpen(false)}
                maxWidth='sm'
                modalStyle={{
                    maxHeight: '86vh',
                    paddingY: 7,
                    paddingX: '20px',
                    color: 'text.primary',
                    boxSizing: 'border-box',
                }}
            >
                <SampleEssayContents
                    sampleStory={sampleStory}
                    sampleEssayProps={sampleEssayProps}
                    shareURL={shareURL}
                />
            </CustomModal>
        </React.Fragment>
    );
};

export default SampleEssayCard;
