import { DeleteRounded, MoreHorizRounded } from "@mui/icons-material";
import { Box, IconButton, Menu, MenuItem, Typography, } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useCommonUtilities from "../../hooks/useCommonUtilities";
import { useNavigate } from "react-router-dom";


const ActionMenu = ({ writeStoryProps, sx }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { openDialog, closeDialog } = useCommonUtilities();
    const [anchorEl, setAnchorEl] = useState(null);

    const { dispatchAndUpdateStory, writeStoryState } = writeStoryProps;

    const storyID = writeStoryState?.story?._id;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = () => {
        setAnchorEl(null);

        openDialog({
            content: t('ActionMenu.confirmDelete'),
            icon: 'warn',
            onConfirm: () => {
                dispatchAndUpdateStory({
                    _id: storyID,
                    updateObject: { "status": "deleted" },
                })
                closeDialog();
                navigate(`/home`);
            },
            onCancel: () => { }
        });
    };


    return (
        <Box sx={sx}>
            <IconButton disableRipple sx={{ backgroundColor: 'primary.main', height: '26px', width: '26px' }}
                onClick={handleClick}
            >
                <MoreHorizRounded />
            </IconButton>

            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onClose={handleClose}
                sx={{ mt: '5px' }}
            >
                {storyID &&
                    <MenuItem onClick={handleDelete}>
                        <DeleteRounded />
                        <Typography textAlign="center" sx={{ ml: 1 }}>{t(`ActionMenu.delete`)}</Typography>
                    </MenuItem>
                }
            </Menu>
        </Box>
    );
};

export default ActionMenu;
