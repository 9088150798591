import React from "react";
import { Box, Typography } from '@mui/material';
import { processNewLines } from '../../../utils/StringUtils';
import { Link, useNavigate } from "react-router-dom";
import CoverImageDisplay from "../CoverImageDisplay";
import { collectionToWriteUrlMapping } from "../../WriteStoryPage/storyUtils";

const GalleryCard = ({ artifact, theme, index }) => {
    const navigate = useNavigate();

    const urlBase = collectionToWriteUrlMapping[artifact.collection];
    const generatedUrl = urlBase ? `${urlBase}/${artifact._id}` : null;

    const handleClick = (event) => {
        event.preventDefault();

        if (generatedUrl) {
            navigate(generatedUrl);
        }
    };

    const content = (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                position: 'relative',
                boxSizing: 'border-box',
                width: '96%',
                maxWidth: '660px',
                padding: '6px',
                paddingRight: '10px',
                color: 'text.primary',
                borderRadius: theme.custom.radius.xsmall,
                boxShadow: theme.custom.shadow.small,
                cursor: 'pointer',
                marginX: 'auto',
                backgroundColor: 'background.main700',
            }}
            onClick={(event) => handleClick(event)}
        >
            <CoverImageDisplay coverImages={artifact.metadata.coverImages} size={{ xs: '160px', sm: '200px' }} />

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    marginLeft: {
                        xs: 2,
                        sm: 3,
                    },
                }}
            >
                <Typography
                    variant="h6"
                    sx={{
                        fontSize: '1.15em',
                        textAlign: 'start',
                        width: '100%',
                        color: 'text.primary',
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitLineClamp: 1,
                        WebkitBoxOrient: 'vertical',
                    }}
                >
                    {processNewLines(artifact.metadata.title)}
                </Typography>

                <Typography
                    variant='body2'
                    sx={{
                        textAlign: 'start',
                        width: '100%',
                        color: 'text.secondary',
                        fontSize: '1em',
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: 'vertical',
                        borderTop: 1,
                        borderColor: 'primary.main',
                    }}
                >
                    {processNewLines(artifact.metadata.description)}
                </Typography>
            </Box>
        </Box>
    );


    return generatedUrl ? (
        <Link href={generatedUrl} style={{ textDecoration: 'none', color: 'inherit' }} target="_self" rel="noopener noreferrer">
            {content}
        </Link>
    ) : content;
};

export default GalleryCard;
