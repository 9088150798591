import React, { useState } from 'react';
import { Grid, Box, Tab, Tabs, Typography, Checkbox, Stack } from '@mui/material';
import PCardCardView from './PCardCardView';
import { useTranslation } from 'react-i18next';
import { StyledContainedButton } from '../../../styles/StyledButtons';
import useCommonUtilities from '../../../hooks/useCommonUtilities';


const PCardGrid = ({ cards, maxCardToDisplay, ButtonCard, showCategoryTabs, title, containerStyles, onClickSelectionBtn, selectedPCardIDs, setSelectedPCardIDs, maxCardToSelect }) => {
    const { t } = useTranslation();
    const { openDialog } = useCommonUtilities();

    // Category Tabs
    const [selectedTab, setSelectedTab] = useState('all');
    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    // Filter PCards with current selected tab
    const filterCards = (card) => {
        return (selectedTab === 'all' || card.cardCategory === selectedTab);
    };
    const displayAll = maxCardToDisplay == null;        // If maxCardToDisplay is null, display all cards

    const cardsToDisplay = displayAll ? cards.filter(filterCards) : cards.filter(filterCards).slice(0, maxCardToDisplay);

    // Select PCards
    const handleSelectCard = (cardId, event) => {
        event.stopPropagation();

        const selectedIndex = selectedPCardIDs.indexOf(cardId);
        let newSelected = [];

        if (selectedIndex === -1) {
            if (maxCardToSelect != null && selectedPCardIDs.length >= maxCardToSelect) {
                openDialog({
                    content: t('PCard.maxCardSelectionError')
                })
                return;
            }
            newSelected = newSelected.concat(selectedPCardIDs, cardId);
        } else if (selectedIndex >= 0) {
            newSelected = newSelected.concat(
                selectedPCardIDs.slice(0, selectedIndex),
                selectedPCardIDs.slice(selectedIndex + 1),
            );
        }
        setSelectedPCardIDs(newSelected);
    };

    const handleAddCard = (event) => {
        if (onClickSelectionBtn) onClickSelectionBtn(event, selectedPCardIDs);
    };

    const defaultStyles = { width: '100%', maxWidth: '980px', minHeight: '100px', padding: 1, boxSizing: 'border-box' };

    return (
        <Box sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: 2,
            width: '100%',
        }}>
            {title &&
                <Typography variant='h5'
                    sx={{
                        color: 'primary.contrastText',
                        fontWeight: 'bold',
                    }}>
                    {title}
                </Typography>
            }

            {showCategoryTabs && (
                <Box sx={{ width: '100%' }}>
                    <Tabs value={selectedTab} onChange={handleTabChange} centered>
                        <Tab label={t('PCard.all')} value="all" disableRipple />
                        <Tab label={t('PCard.character')} value="character" disableRipple />
                        <Tab label={t('PCard.location')} value="location" disableRipple />
                        <Tab label={t('PCard.keyItem')} value="keyItem" disableRipple />
                    </Tabs>
                </Box>
            )}

            <Box sx={{ ...defaultStyles, ...containerStyles, position: 'relative' }}>
                <Grid container rowSpacing={2}>
                    {cardsToDisplay.map((card) => (
                        <Grid item key={card._id} xs={6} sm={4} md={3} lg={3}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', position: 'relative' }}>
                                <PCardCardView cardData={card}
                                    allowSelection={setSelectedPCardIDs != null}
                                />
                                {setSelectedPCardIDs != null && (
                                    <Checkbox
                                        checked={selectedPCardIDs.includes(card._id)}
                                        onChange={(e) => handleSelectCard(card._id, e)}
                                        disableRipple
                                        sx={{
                                            transform: 'scale(1.5)',
                                            '& .MuiSvgIcon-root': { fontSize: 28 },
                                            '&.Mui-checked': {
                                                color: 'primary.contrastText',
                                            },
                                            position: 'absolute', bottom: 4, right: 8
                                        }}
                                    />
                                )}
                            </Box>
                        </Grid>
                    ))}

                    {ButtonCard &&
                        <Grid item key={'displayAll'} xs={6} sm={4} md={3} lg={3}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', position: 'relative' }}>
                                {ButtonCard}
                            </Box>
                        </Grid>
                    }
                </Grid>
            </Box>

            {setSelectedPCardIDs != null &&
                <Stack direction="column" sx={{
                    position: 'sticky',
                    bottom: 0,
                    width: '90%',
                    maxWidth: '400px',
                    mt: -1,
                }}>
                    <StyledContainedButton variant="contained" onClick={(e) => handleAddCard(e)}>
                        {maxCardToSelect == null ?
                            t('PCard.selectBtn')
                            :
                            t('PCard.selectBtn2', { count: maxCardToSelect - selectedPCardIDs.length })
                        }
                    </StyledContainedButton>
                </Stack>
            }
        </Box>
    );
};

export default PCardGrid;
