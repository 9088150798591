import React, { useEffect, useState } from 'react';
import useCommonUtilities from '../../hooks/useCommonUtilities';
import { Box, Container } from '@mui/material';
import PCardGrid from '../Shared/PCard/PCardsGrid';
import ActionHub from './ActionHub';
import UserGalleries from './UserGalleries';
import MoreActionsCard from './MoreActionsCard';
import { LoadingSpinnerWithOverlay } from '../Shared/Progresses/LoadingSpinnerWithOverlay';
import withRoot from '../../withRoot';
import AppAppBar from '../Shared/AppAppBar';
import AppFooter from '../Shared/AppFooter';
import PCardGridModal from '../Shared/PCard/PCardGridModal';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';


const HomePage = () => {
    const { lang, fetchUserStoryData, userStoryData } = useCommonUtilities();
    const theme = useTheme();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [PCardModalOpen, setPCardModalOpen] = useState(false);

    const onMountPage = async () => {
        setLoading(true);
        await fetchUserStoryData();
        setLoading(false);
    };

    useEffect(() => {
        onMountPage();
        // eslint-disable-next-line
    }, [lang]);

    const containerStyles = { width: '100%', maxWidth: '980px', minHeight: '100px', backgroundColor: 'background.main400', borderRadius: theme.custom.radius.small, padding: 1, boxSizing: 'border-box', py: 2, };

    if (loading) {
        return <LoadingSpinnerWithOverlay size={70} />;
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                width: '100vw',
                backgroundColor: 'background.main',
            }}
        >
            <AppAppBar />

            <Container maxWidth='lg'
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    marginTop: '160px',
                    marginBottom: '80px',
                    boxSizing: 'border-box',
                    paddingX: 0,
                    gap: 10,
                }}
            >
                {userStoryData &&
                    <React.Fragment>
                        <ActionHub containerStyles={containerStyles} />

                        <UserGalleries essays={userStoryData?.essays} containerStyles={containerStyles} />

                        {Array.isArray(userStoryData?.pcards) && userStoryData?.pcards.length > 0 &&
                            <PCardGrid cards={userStoryData?.pcards} maxCardToDisplay={7} title={t('home.userGalleriesHeader2')} containerStyles={containerStyles}
                                ButtonCard={<MoreActionsCard onClickDisplayAllCard={() => setPCardModalOpen(true)} />}
                            />
                        }

                    </React.Fragment>
                }

            </Container>

            <AppFooter />

            <PCardGridModal isOpen={PCardModalOpen} onClose={() => setPCardModalOpen(false)}
                PCardGridProps={{ cards: userStoryData?.pcards, showCategoryTabs: true }}
            />
        </Box>
    );
}

export default withRoot(HomePage);
