
import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, Link, InputAdornment, IconButton, Button, CircularProgress } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import withRoot from '../../withRoot';
import { useAuthentication } from '../../hooks/useAuthentication';
import { useTranslation } from 'react-i18next';
import AppAppBar from '../Shared/AppAppBar';
import { StyledContainedButton } from '../../styles/StyledButtons';
import { StyledOutlineTextField } from '../../styles/StyledTextFields';
import ErrorHelperText from '../Shared/ErrorHelperText';
import { Edit, Visibility, VisibilityOff } from '@mui/icons-material';
import { isValidChineseMobile } from '../../utils/authUtils';
import ApiServices from '../../services/ApiServices';
import useCommonUtilities from '../../hooks/useCommonUtilities';
import AppFooter from '../Shared/AppFooter';


function ResetPasswordPage() {
    const { token } = useAuthentication();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { openDialog, closeDialog } = useCommonUtilities();
    const loginMethod = 'mobile';   // mobile only

    // User Inputs
    const [step, setStep] = useState(1);
    const [username, setUsername] = useState('');
    const [smsCode, setSmsCode] = useState('');

    const [newPassword, setNewPassword] = useState('');
    const [showNewPassword, setShowNewPassword] = useState(false);

    const [confirmPassword, setConfirmPassword] = useState('');
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    // Errors
    const [usernameError, setUsernameError] = useState(null);
    const [smsRequestError, setSmsRequestError] = useState(null);
    const [newPasswordError, setNewPasswordError] = useState(null);
    const [confirmPasswordError, setConfirmPasswordError] = useState(null);

    // UI related states
    const [isLoading, setIsLoading] = useState(false);
    const [requestingSms, setIsRequestingSms] = useState(false);
    const [countdown, setCountdown] = useState(() => {
        const storedCountdown = Number(localStorage.getItem('smsCountdown'));  // Initialize countdown from localStorage or default to 0
        return storedCountdown > 0 ? storedCountdown : 0;
    });

    const handleUsernameChange = (value) => {
        // If the sign-up method is 'mobile', enforce the 11-digit limit
        if (loginMethod === 'mobile') {
            if (value.length <= 11 && /^\d*$/.test(value)) {
                setUsername(value);
            }
        } else {
            setUsername(value);
        }
    };

    const handleSmsCodeChange = (e) => {
        setSmsRequestError(null);
        const value = e.target.value;
        if (value.length <= 6 && /^\d*$/.test(value)) {
            setSmsCode(value);
        }
    };

    const resetToStep1 = () => {
        setStep(1);
        setNewPassword(null);
        setConfirmPassword(null);
        setShowNewPassword(false);
        setShowConfirmPassword(false);
        setUsernameError(null);
        setNewPasswordError(null);
        setConfirmPasswordError(null);
    };

    const handleRequestSmsCode = async () => {
        if (!isValidChineseMobile(username)) {
            setUsernameError(t('AuthPage.errors.phoneError'));
            return;
        }
        if (countdown > 0) {
            return;
        }

        setIsRequestingSms(true);

        try {
            const response = await ApiServices.getInstance().requestSmsCode(username);
            if (response && response.status === 200) {
                setCountdown(60);
                localStorage.setItem('smsCountdown', '60');
                setSmsRequestError(null);
            }
        } catch (error) {
            console.error(error);
            if (error.response && error.response.status === 404) {
                setUsernameError(t('AuthPage.errors.accountNotFound'));
            } else {
                setSmsRequestError(t('AuthPage.errors.smsRequestError'));
            }
        }

        setIsRequestingSms(false);
    };

    const checkUsernameAndSmsCode = async (e) => {
        if (!isValidChineseMobile(username)) {
            setUsernameError(t('AuthPage.errors.phoneError'));
            return;
        }
        if (smsCode.length < 6) {
            setSmsRequestError(t('AuthPage.errors.smsCodeError'))
            return;
        }

        try {
            setIsLoading(true);

            const response = await ApiServices.getInstance().verifySmsCode(username, smsCode);
            if (response && response.status === 200) {
                setUsernameError(null);
                setSmsRequestError(null);
                setStep(2);
                return;
            }
        } catch (error) {
            setSmsRequestError(t('AuthPage.errors.smsCodeError'));
        } finally {
            setIsLoading(false);
        }
    }

    const resetPassword = async (e) => {
        let checkPassed = true;

        if (newPassword.length < 8) {
            setNewPasswordError(t('AuthPage.errors.passwordError'));
            setIsLoading(false);
            checkPassed = false;
        }
        if (confirmPassword.length < 8) {
            setConfirmPasswordError(t('AuthPage.errors.passwordError'));
            setIsLoading(false);
            checkPassed = false;
        }
        if (newPassword !== confirmPassword) {
            setConfirmPasswordError(t('AuthPage.errors.confirmPasswordError'));
            setIsLoading(false);
            checkPassed = false;
        }

        if (!checkPassed) {
            return;
        }

        try {
            setIsLoading(true);

            const response = await ApiServices.getInstance().resetPassword(username, newPassword);
            if (response && response.status === 200) {
                const newUrl = `/login`;
                window.history.replaceState({ path: newUrl }, '', newUrl);
                openDialog({
                    content: t('AuthPage.resetSuccess'),
                    icon: 'check',
                    onConfirm: () => {
                        closeDialog();
                        setIsLoading(false);
                        navigate(`/login`);
                    },
                });
            }
        } catch (error) {
            console.error('Failed to reset password', error);
            openDialog({ content: t('errors.system_bug'), icon: 'error', })
        } finally {
            setIsLoading(false);
        }
    };

    const handleResetPassword = (e) => {
        e.preventDefault();
        if (isLoading) return;

        if (step === 1) {
            checkUsernameAndSmsCode();
        }
        else if (step === 2) {
            resetPassword();
        }
    }

    // Sms code countdown
    useEffect(() => {
        let timerId;

        if (countdown > 0) {
            timerId = setTimeout(() => {
                setCountdown(countdown - 1);
                if (countdown % 10 === 0 || countdown === 1) {
                    localStorage.setItem('smsCountdown', countdown - 1);
                }
            }, 1000);
        } else {
            localStorage.removeItem('smsCountdown');
        }
        return () => clearTimeout(timerId);
    }, [countdown]);

    useEffect(() => {
        if (token) {
            navigate(`/home`);
        }
    }, [token, navigate]);


    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                width: '100vw',
                backgroundColor: 'background.main',
            }}
        >
            <AppAppBar />

            <Box
                component="form"
                onSubmit={handleResetPassword}
                sx={{ flex: 1, display: 'flex', flexDirection: 'column', minHeight: '90vh' }}
            >
                <Container maxWidth='xs'
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        variant='h4'
                        sx={{
                            textAlign: 'center',
                            fontWeight: 400,
                            marginBottom: 6,
                            color: 'primary.contrastText',
                            marginTop: 10,
                        }}
                    >
                        {t('AuthPage.resetPassword')}
                    </Typography>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        maxWidth: '320px',
                        boxSizing: 'border-box',
                    }}>
                        {step === 1 && (
                            <>
                                <StyledOutlineTextField
                                    variant="outlined"
                                    margin="none"
                                    required
                                    fullWidth
                                    label={t(`AuthPage.${loginMethod}`)}
                                    autoFocus
                                    value={username}
                                    onChange={(e) => {
                                        handleUsernameChange(e.target.value);
                                        setUsernameError(null);
                                    }}
                                    InputProps={loginMethod === 'mobile' ?
                                        {
                                            startAdornment: (
                                                <InputAdornment position="start">+86</InputAdornment>
                                            ),
                                            inputProps: {
                                                type: 'tel',
                                            }
                                        } : {
                                            inputProps: {
                                                type: 'text',
                                            }
                                        }}
                                    error={!!usernameError}
                                    helperText={<ErrorHelperText error={!!usernameError}>{usernameError}</ErrorHelperText>}
                                    disabled={isLoading}
                                />
                                <StyledOutlineTextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label={t('AuthPage.enterSmsCode')}
                                    value={smsCode}
                                    onChange={handleSmsCodeChange}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Button
                                                    disableRipple
                                                    disabled={isLoading}
                                                    onClick={handleRequestSmsCode}
                                                    sx={{
                                                        borderRadius: 0,
                                                        borderLeft: 1,
                                                        paddingX: '10px',
                                                        mr: '-10px',
                                                        height: '54px',
                                                        width: '110px',
                                                        color: 'primary.contrastText',
                                                        textTransform: 'none'
                                                    }}
                                                >
                                                    {requestingSms ?
                                                        <CircularProgress size={'20px'} sx={{ color: 'primary.contrastText' }} />
                                                        :
                                                        <>
                                                            {countdown > 0 ? t('AuthPage.smsCountDown', { countdown }) : t('AuthPage.getSmsCode')}
                                                        </>
                                                    }
                                                </Button>
                                            </InputAdornment>
                                        ),
                                        inputProps: {
                                            type: 'tel',
                                        },
                                    }}
                                    error={!!smsRequestError}
                                    helperText={<ErrorHelperText error={!!smsRequestError}>{smsRequestError}</ErrorHelperText>}
                                    disabled={isLoading}
                                />
                            </>
                        )}

                        {step === 2 && (
                            <>
                                {/* Phone number */}
                                <StyledOutlineTextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    label={t(`AuthPage.${loginMethod}`)}
                                    value={username}
                                    disabled
                                    autoComplete="username"
                                    InputProps={
                                        {
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={resetToStep1}
                                                        style={{ backgroundColor: 'transparent', position: 'relative', right: '-10px' }}
                                                        disabled={isLoading}
                                                    >
                                                        <Edit />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                            startAdornment: loginMethod === 'mobile' ? (
                                                <InputAdornment position="start">+86</InputAdornment>
                                            ) : null,
                                            inputProps: {
                                                type: loginMethod === 'mobile' ? 'tel' : 'text',
                                            },
                                        }
                                    }
                                />

                                {/* New Password */}
                                <StyledOutlineTextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label={t('AuthPage.newPassword')}
                                    value={newPassword}
                                    autoFocus
                                    autoComplete="new-password"
                                    onChange={(e) => {
                                        setNewPassword(e.target.value);
                                        if (e.target.value.length === 8) {
                                            setNewPasswordError(false);
                                        }
                                    }}
                                    type={showNewPassword ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => setShowNewPassword(!showNewPassword)}
                                                    style={{ backgroundColor: 'transparent', position: 'relative', right: '-10px' }}
                                                    disableRipple
                                                    disabled={isLoading}
                                                >
                                                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    error={!!newPasswordError}
                                    helperText={<ErrorHelperText error={!!newPasswordError}>{newPasswordError}</ErrorHelperText>}
                                    disabled={isLoading}
                                />

                                {/* Confirm Password */}
                                <StyledOutlineTextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label={t('AuthPage.confirmNewPassword')}
                                    value={confirmPassword}
                                    autoComplete="new-password"
                                    onChange={(e) => {
                                        setConfirmPassword(e.target.value);
                                        if (e.target.value.length === 8) {
                                            setConfirmPasswordError(false);
                                        }
                                    }}
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                    style={{ backgroundColor: 'transparent', position: 'relative', right: '-10px' }}
                                                    disableRipple
                                                    disabled={isLoading}
                                                >
                                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    error={!!confirmPasswordError}
                                    helperText={<ErrorHelperText error={!!confirmPasswordError}>{confirmPasswordError}</ErrorHelperText>}
                                    disabled={isLoading}
                                />
                            </>
                        )}
                    </Box>

                    <StyledContainedButton
                        variant='contained'
                        disableRipple
                        type='submit'
                        disabled={isLoading}
                        sx={{ marginTop: 3 }}
                    >
                        {isLoading &&
                            <CircularProgress size={'20px'} sx={{ color: 'primary.contrastText', marginRight: 2 }} />
                        }
                        {step === 1 ?
                            t('AuthPage.nextStep') :
                            t('AuthPage.confirmReset')
                        }
                    </StyledContainedButton>

                    <Typography sx={{ marginTop: 3, fontSize: '1em', }}>
                        <Link component={RouterLink}
                            to={`/login`}
                            color="text.secondary"
                            style={{ textTransform: 'none', textDecoration: 'none', fontWeight: 500 }}
                        >
                            {t('AuthPage.backToLogin')}
                        </Link>
                    </Typography>
                </Container>
            </Box>

            <AppFooter />
        </Box>
    );
}

export default withRoot(ResetPasswordPage);
