import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, TextField, Typography } from '@mui/material';

const CustomTextField = ({
    disabled,
    autoFocus = false,
    placeholder,
    setValue,
    value,
    helperText,
    header,
    showAdornments = 'never',
    endAdornments,
    startAdornments,
    onBlur,
    ruledLines,
    sx = {},
}) => {

    const theme = useTheme();
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
        if (typeof onBlur === 'function' && !disabled) { onBlur(value); };
    };

    let displayEndAdornments = null;
    let displayStartAdornments = null;

    if (showAdornments === 'always' || (showAdornments === 'onFocus' && isFocused)) {
        displayEndAdornments = endAdornments;
        displayStartAdornments = startAdornments;
    }

    const ruledLinesStyle = ruledLines ?
        {
            '& .MuiInputBase-inputMultiline': {
                backgroundImage: `repeating-linear-gradient(to bottom, transparent, transparent calc(2em - 1px), ${theme.palette.divider} 2em)`,
                backgroundSize: '2em',
                lineHeight: '2em',
                padding: '5px 5px',
            },
        } : {};

    const textFieldStyles = disabled ?
        { boxShadow: 0, borderRadius: theme.custom.radius.xsmall, backgroundColor: 'background.main300' }
        :
        { boxShadow: theme.custom.shadow.xsmall, borderRadius: theme.custom.radius.xsmall, backgroundColor: 'background.main300' }

    return (
        <Box sx={{ width: '100%' }}>
            {
                header != null &&
                <Typography
                    variant='subtitle2'
                    sx={{
                        width: '100%',
                        textAlign: 'start',
                        marginBottom: 1,
                        marginLeft: 1,
                        color: 'primary.contrastText',
                    }}
                >
                    {header}
                </Typography>
            }

            <TextField
                fullWidth
                multiline
                disabled={typeof disabled === 'string' ? disabled !== '' : disabled}
                autoFocus={autoFocus}
                placeholder={disabled ? '' : placeholder}
                sx={{
                    width: '100%',
                    ...textFieldStyles,
                    ...ruledLinesStyle,
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            border: 'none',
                        },
                        '&:hover fieldset': {
                            border: 'none',
                        },
                        '&.Mui-focused fieldset': {
                            border: 'none',
                        },
                        '& .MuiInputBase-input': {
                            color: 'text.primary',
                        },
                        "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: theme.palette.text.primary
                        },
                    },
                }}
                value={value}
                onChange={(e) => {
                    if (setValue) { setValue(e); };
                }}
                onFocus={handleFocus}
                onBlur={handleBlur}
                InputProps={{
                    endAdornment: (displayEndAdornments),
                    startAdornment: (displayStartAdornments),
                    style: { caretColor: disabled ? "transparent" : 'inherit' },
                }}
                helperText={helperText}
            />
        </Box>

    );
};

export default CustomTextField;
