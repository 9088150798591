import React from 'react';
import { Modal, Container, IconButton } from '@mui/material';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import withRoot from '../../withRoot';
import { useTheme } from '@emotion/react';
import { zIndexValues } from '../../styles/zIndexValues';


const CustomModal = ({ isOpen, onClose, children, maxWidth = 'xs', zIndex = zIndexValues.modal, modalStyle = {} }) => {
    const theme = useTheme();

    const _modalStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        boxSizing: 'border-box',
        position: 'absolute',
        paddingY: 5,
        paddingX: { xs: 4, sm: 5 },
        backgroundColor: 'background.main400',
        boxShadow: theme.custom.shadow.medium,
        borderRadius: theme.custom.radius.medium,
        color: 'primary.contrastText',
        ':focus': { outline: 'none' },
        overflowY: 'auto',
        maxHeight: '95vh',
        ...modalStyle
    };

    const handleClose = () => {
        if (typeof onClose === 'function') {
            onClose();
        }
    };

    return (
        <Modal open={isOpen} onClose={handleClose} sx={{ zIndex: zIndex }}>
            <Container maxWidth={maxWidth} sx={_modalStyle}>
                <IconButton
                    onClick={handleClose} disableRipple
                    sx={{ position: 'absolute', top: 8, right: 8, color: 'primary.dark' }}
                >
                    <HighlightOffRoundedIcon />
                </IconButton>
                {children}
            </Container>
        </Modal>
    );
};

export default withRoot(CustomModal);
