import { Container, Modal } from '@mui/material';
import React from 'react';
import { zIndexValues } from '../../../styles/zIndexValues';
import { useTheme } from '@emotion/react';
import useCommonUtilities from '../../../hooks/useCommonUtilities';
import PCardGrid from './PCardsGrid';


const PCardGridModal = ({ isOpen, onClose, PCardGridProps }) => {
    const theme = useTheme();
    const { userStoryData } = useCommonUtilities();

    const handleModalClose = (e) => {
        e.stopPropagation();
        onClose();
    };

    return (
        <Modal
            open={isOpen}
            onClose={handleModalClose}
        >
            <Container maxWidth='lg'
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    top: '45%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    boxSizing: 'border-box',
                    position: 'absolute',
                    zIndex: zIndexValues.modal,
                    paddingY: 2,
                    paddingX: 0,
                    backgroundColor: 'background.main400',
                    boxShadow: theme.custom.shadow.modal,
                    borderRadius: theme.custom.radius.medium,
                    color: 'text.primary',
                    ':focus': { outline: 'none' },
                    overflowY: 'auto',
                    height: '84vh',
                }}
            >
                {userStoryData &&
                    <PCardGrid
                        {...PCardGridProps}
                        onClickSelectionBtn={handleModalClose}
                    />
                }
            </Container>
        </Modal>
    );
};

export default PCardGridModal;
