import { Box } from "@mui/material";
import React, { useState } from "react";
import book_logo_png from '../../assets/book_logo.png'
import { LoadingSpinnerWithOverlay } from "./Progresses/LoadingSpinnerWithOverlay";
import { useTheme } from "@emotion/react";

const CoverImageDisplay = ({ coverImages, size, allowSelection, loading }) => {
    const theme = useTheme();
    const [selectedImageIndex, setSelectedImageIndex] = useState(coverImages?.selectedImage ?? 0);

    const handleImageSelection = (index) => {
        setSelectedImageIndex(index);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
            {loading &&
                <LoadingSpinnerWithOverlay
                    size={'40%'}
                    color='primary.light'
                    sx={{
                        borderRadius: theme.custom.radius.xsmall,
                        backdropFilter: 'blur(2px)',
                        zIndex: 0,
                    }}
                />
            }
            <Box
                component="img"
                sx={{
                    width: size,
                    height: 'auto',
                    objectFit: 'cover',
                    borderRadius: theme.custom.radius.xsmall,
                    backgroundColor: 'background.main400',
                }}
                src={coverImages?.images?.[selectedImageIndex]?.url ?? book_logo_png}
                alt="Cover Image"
            />


            {
                allowSelection &&
                Array.isArray(coverImages?.images) &&
                coverImages?.images.length > 1 &&
                <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: '8px' }}>
                    {coverImages.images.map((image, index) => (
                        <Box
                            key={index}
                            sx={{
                                width: 10,
                                height: 10,
                                borderRadius: '50%',
                                backgroundColor: selectedImageIndex === index ? 'primary.main' : 'grey.400',
                                margin: '0 5px',
                                cursor: 'pointer',
                                '&:hover': {
                                    backgroundColor: 'primary.dark',
                                },
                            }}
                            onClick={() => handleImageSelection(index)}
                        />
                    ))}
                </Box>
            }
        </Box>
    );
};

export default CoverImageDisplay;
