import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledOutlineTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: theme.palette.primary.contrastText,
            borderRadius: '15px'
        },
        '&:hover fieldset': {
            borderColor: theme.palette.primary.contrastText,
        },
        '&.Mui-focused fieldset': {
            borderColor: theme.palette.primary.contrastText,
        },
    },
    '.MuiInputLabel-root': {
        color: theme.palette.primary.contrastText,
    },
    '& .MuiInputLabel-root.Mui-focused': {
        color: theme.palette.primary.contrastText,
    },
}));