import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import CustomTextField from '../../../Shared/CustomTextField';
import { useTranslation } from 'react-i18next';
import { WriteStoryModes } from '../../storyUtils';
import { StyledContainedButton } from '../../../../styles/StyledButtons';
import useIdeaIntake from './useIdeaIntake';


const IdeaIntake = ({ writeStoryProps, mode }) => {
    const { t } = useTranslation();
    const { writeStoryState: { story } } = writeStoryProps;
    const currentState = story?.states?.currentState;

    const {
        currentAction,
        storyTopic,
        handleStoryTopicChange,
        userIdea,
        handleUserIdeaChange,
        runIdeaIntake,
    } = useIdeaIntake(writeStoryProps);

    const isReadonly = currentState !== 'initial' || mode === WriteStoryModes.VIEW;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '100%',
                boxSizing: 'border-box',
                gap: 3,
                color: 'text.primary',
            }}
        >
            <CustomTextField
                header={t('writeStory.a1.topicHeader')}
                disabled={currentAction !== null || isReadonly}
                placeholder={t('writeStory.a1.topicPlaceholder')}
                value={storyTopic}
                ruledLines
                setValue={handleStoryTopicChange}
            />

            <CustomTextField
                header={t('writeStory.a1.outlineHeader')}
                disabled={currentAction !== null || isReadonly}
                placeholder={t('writeStory.a1.outlinePlaceholder')}
                value={userIdea}
                ruledLines
                setValue={handleUserIdeaChange}
            />

            {/* {cards.length > 0 &&
                <PCardSelectionPanel
                    cards={cards}
                    selectedPCardIDs={selectedPCardIDs}
                    setSelectedPCardIDs={setSelectedPCardIDs}
                    maxCardToSelect={maxCardToSelect}
                    selectionLocked={currentAction !== '' || !isReadonly}
                />
            } */}

            {currentState === 'initial' &&
                <StyledContainedButton variant='contained' sx={{ width: { xs: '96%', sm: '80%' } }}
                    onClick={runIdeaIntake}
                    disabled={currentAction !== null || !userIdea}
                >
                    {currentAction === null ?
                        t('writeStory.a1.ideaIntakeBtn') :
                        <>
                            <CircularProgress size={'20px'} sx={{ color: 'primary.contrastText', marginRight: 2 }} />
                            {t(`writeStory.actions.${currentAction}`)}
                        </>
                    }
                </StyledContainedButton>
            }
        </Box >
    );
};

export default IdeaIntake