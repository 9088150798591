import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Typography, Link, InputAdornment, IconButton, Box, Container, CircularProgress } from '@mui/material';
import { Edit, Visibility, VisibilityOff } from '@mui/icons-material';
import { isValidEmail, isValidChineseMobile } from '../../utils/authUtils';
import { useAuthentication } from '../../hooks/useAuthentication';
import ApiServices from '../../services/ApiServices';
import ErrorHelperText from '../Shared/ErrorHelperText';
import { StyledOutlineTextField } from '../../styles/StyledTextFields';
import { processNewLines } from '../../utils/StringUtils';
import { StyledContainedButton } from '../../styles/StyledButtons';
import withRoot from '../../withRoot';
import AppAppBar from '../Shared/AppAppBar';
import { defaultUserTag } from '../../configs/appConfig';
import AppFooter from '../Shared/AppFooter';


function SignupPage() {
    const { handleLogin, token } = useAuthentication();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const signupMethod = 'mobile';   // email or mobile

    // User credentials
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    // Login states and errors
    const [step, setStep] = useState(1);
    const [usernameError, setUsernameError] = useState(null);
    const [passwordError, setPasswordError] = useState(null);
    const [accountExistError, setAccountExistError] = useState(null);
    const [passwordTouched, setPasswordTouched] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    // Handlers
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleUsernameChange = (value) => {
        // If the sign-up method is 'mobile', enforce the 11-digit limit
        if (signupMethod === 'mobile') {
            if (value.length <= 11 && /^\d*$/.test(value)) {
                setUsername(value);
            }
        } else {
            setUsername(value);
        }
    };

    const handleClickEditUsername = () => {
        setStep(1);
        setPassword("");
        setShowPassword(false);
        setUsernameError(null);
        setPasswordError(null);
        setPasswordTouched(null);
    };

    const mobileUsernameCheck = (username) => {
        if (!isValidChineseMobile(username)) {
            setUsernameError(t('AuthPage.errors.phoneError'));
            return false;
        } else {
            return true;
        }
    };

    const emailUsernameCheck = (username) => {
        if (!isValidEmail(username)) {
            setUsernameError(t('AuthPage.errors.emailError'));
            return false;
        } else {
            return true;
        }
    };

    const handleContinue = async (event) => {
        event.preventDefault();
        if (isLoading) return;
        setIsLoading(true);

        if (step === 1) {
            let isUsernameValid;

            if (signupMethod === 'mobile') {
                isUsernameValid = mobileUsernameCheck(username);
            } else if (signupMethod === 'email') {
                isUsernameValid = emailUsernameCheck(username);
            }

            if (isUsernameValid) {
                setUsernameError(null);
                setStep(2);
            }
            setIsLoading(false);
            return;
        } else if (step === 2) {
            if (password.length < 8) {
                setPasswordError(t('AuthPage.errors.passwordError'));
                setIsLoading(false);
                return;
            }
            try {
                const singupProps = {
                    ...(signupMethod === 'email' ? { email: username } : { mobile_number: username }),
                    user_tags: defaultUserTag,
                };
                await ApiServices.getInstance().userSignup(username, password, singupProps);
                const newUrl = `/login`;
                window.history.replaceState({ path: newUrl }, '', newUrl);
                const data = await ApiServices.getInstance().userLogin(username, password);
                handleLogin(data.access_token);
                setIsLoading(false);
                navigate(`/home`);
            } catch (error) {
                if (error.response && error.response.status === 400) {
                    setAccountExistError(t('AuthPage.errors.accountExistWithPhoneNumber'));
                } else {
                    console.error(error);
                }
                setIsLoading(false);
            }
        }
    };

    useEffect(() => {
        if (token) {
            navigate(`/home`);
        }
    }, [token, navigate]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                width: '100vw',
                backgroundColor: 'background.main',
            }}
        >
            <AppAppBar />

            <Box
                component="form"
                sx={{ flex: 1, display: 'flex', flexDirection: 'column', minHeight: '90vh' }}
                onSubmit={handleContinue}
            >
                <Container maxWidth='xs'
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        variant='h4'
                        sx={{
                            textAlign: 'center',
                            fontWeight: 400,
                            marginBottom: 6,
                            color: 'primary.contrastText',
                            marginTop: 10,
                        }}
                    >
                        {t('AuthPage.createYourAccount')}
                    </Typography>

                    {/* Credential input fields */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            maxWidth: '320px',
                            boxSizing: 'border-box',
                        }}
                    >
                        {step === 1 ? (
                            <>
                                <StyledOutlineTextField
                                    variant="outlined"
                                    margin="none"
                                    required
                                    fullWidth
                                    label={t(`AuthPage.${signupMethod}`)}
                                    autoFocus
                                    value={username}
                                    onChange={(e) => {
                                        handleUsernameChange(e.target.value);
                                        setAccountExistError(null);
                                        setUsernameError(null);
                                    }}
                                    InputProps={signupMethod === 'mobile' ?
                                        {
                                            startAdornment: (
                                                <InputAdornment position="start">+86</InputAdornment>
                                            ),
                                            inputProps: {
                                                type: 'tel',
                                            }
                                        } : {
                                            inputProps: {
                                                type: 'text',
                                            }
                                        }}
                                    error={!!usernameError}
                                    helperText={<ErrorHelperText error={!!usernameError}>{usernameError}</ErrorHelperText>}
                                />
                            </>
                        ) : (
                            <>
                                <StyledOutlineTextField
                                    variant="outlined"
                                    margin="none"
                                    fullWidth
                                    label={t(`AuthPage.${signupMethod}`)}
                                    value={username}
                                    disabled
                                    autoComplete="username"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={handleClickEditUsername}
                                                    style={{ backgroundColor: 'transparent', position: 'relative', right: '-10px' }}
                                                    disabled={isLoading}
                                                >
                                                    <Edit />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        startAdornment: signupMethod === 'mobile' ? (
                                            <InputAdornment position="start">+86</InputAdornment>
                                        ) : null,
                                        inputProps: {
                                            type: signupMethod === 'mobile' ? 'tel' : 'text',
                                        },
                                    }}
                                    error={!!accountExistError}
                                    helperText={<ErrorHelperText error={!!accountExistError}>{accountExistError}</ErrorHelperText>}
                                />
                                <StyledOutlineTextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label={t('AuthPage.password')}
                                    value={password}
                                    autoFocus
                                    autoComplete="new-password"
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                        setPasswordTouched(true);
                                        if (e.target.value.length === 8) {
                                            setPasswordError(null);
                                        }
                                    }}
                                    type={showPassword ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={handleClickShowPassword}
                                                    style={{ backgroundColor: 'transparent', position: 'relative', right: '-10px' }}
                                                    disableRipple
                                                    disabled={isLoading}
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    error={!!passwordError}
                                    helperText={<ErrorHelperText error={!!passwordError}>{passwordError}</ErrorHelperText>}
                                    disabled={isLoading}
                                />

                                {/* Password rules */}
                                {passwordTouched && (
                                    <Typography
                                        sx={{
                                            textAlign: 'left',
                                            width: '100%',
                                            color: 'primary.contrastText',
                                            fontSize: '10pt',
                                            border: 1,
                                            borderColor: 'primary.contrastText',
                                            borderRadius: '15px',
                                            boxSizing: 'border-box',
                                            padding: '12px',
                                        }}
                                    >
                                        {processNewLines(t('AuthPage.passwordRules'))}
                                    </Typography>
                                )}
                            </>
                        )}

                        {/* Continue Button */}
                        <StyledContainedButton
                            variant='contained'
                            disableRipple
                            type='submit'
                            disabled={isLoading}
                            sx={{
                                marginTop: 3
                            }}
                        >
                            {isLoading &&
                                <CircularProgress size={'20px'} sx={{ color: 'primary.contrastText', marginRight: 2 }} />
                            }
                            {t('AuthPage.signup')}
                        </StyledContainedButton>

                        {/* Link to Login */}
                        <Typography
                            sx={{ color: 'text.secondary', marginTop: 3, fontSize: '1em', }}
                        >
                            {t('AuthPage.alreadyHaveAccount')}{' '}
                            <Link
                                component={RouterLink}
                                to={`/login`}
                                color="primary.contrastText"
                                style={{ textTransform: 'none', textDecoration: 'none', fontWeight: 500, fontSize: '1em' }}
                            >
                                {t('AuthPage.login')}
                            </Link>
                        </Typography>
                    </Box>
                </Container>
            </Box>

            <AppFooter />
        </Box>
    );
}

export default withRoot(SignupPage);
