export const zIndexValues = {
    baseContent: 0,     // Regular content
    appBar: 100,        // App bars, headers
    dropdown: 200,      // Dropdown menus, popovers
    overlay: 300,       // Overlay layers, such as dimming behind modals
    modal: 400,         // Modals, dialog boxes
    drawer: 500,        // Drawer components, sliding over most content
    alert: 600,         // Alerts, toasts, notification banners
    loader: 700,        // Full-screen loaders, progress bars
};
