import React from 'react';
import { Modal, Container, Typography, Box, Stack, Chip } from '@mui/material';
import { useTheme } from '@emotion/react';
import { zIndexValues } from '../../../styles/zIndexValues';
import CoverImageDisplay from '../CoverImageDisplay';
import { PCardColorMapping, getCardIcon } from './PCardUtils';

const PCardExpandedView = ({ isOpen, onClose, cardData }) => {
    const theme = useTheme();

    return (
        <React.Fragment>
            <Modal
                open={isOpen}
                onClose={onClose}
            >
                <Container maxWidth='sm'
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        top: '45%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        boxSizing: 'border-box',
                        position: 'absolute',
                        zIndex: zIndexValues.modal,
                        paddingY: 3,
                        backgroundColor: 'background.main400',
                        boxShadow: theme.custom.shadow.modal,
                        borderRadius: theme.custom.radius.medium,
                        color: 'text.primary',
                        ':focus': { outline: 'none' },
                        overflowY: 'auto',
                        maxHeight: '86vh',
                        gap: 2,
                        borderStyle: 'solid',
                        borderWidth: { xs: 5, sm: 10 },
                        borderColor: PCardColorMapping[cardData.cardCategory],
                    }}
                >
                    <CoverImageDisplay coverImages={cardData.metadata.coverImages} size={'100%'} />

                    {getCardIcon({ collection: cardData.cardCategory, size: '40px' })}

                    <Box
                        sx={{
                            paddingX: '5%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 2,
                        }}
                    >
                        <Typography variant="h5">{cardData.metadata.title}</Typography>

                        <Typography>{cardData.metadata.description}</Typography>

                        <Stack spacing={2} sx={{ borderTop: 1, borderColor: 'primary.main', paddingY: 2 }}>
                            {Array.isArray(cardData.data?.infoArray) &&
                                cardData.data.infoArray.map((item, index) => (
                                    <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                                        {item.tags.map((tag, tagIndex) => (
                                            <Chip key={tagIndex} label={tag} size="small" sx={{ marginRight: 2 }} />
                                        ))}
                                        <Typography>{item.info}</Typography>
                                    </Box>
                                ))}
                        </Stack>
                    </Box>
                </Container>
            </Modal>
        </React.Fragment>
    );
};

export default PCardExpandedView;
