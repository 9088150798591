import React from "react";
import withRoot from "../../withRoot";
import { Box, Container } from "@mui/material";
import AppAppBar from "../Shared/AppAppBar";
import AppFooter from "../Shared/AppFooter";

const LandingPage = () => {

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                width: '100vw',
                backgroundColor: 'background.main',
            }}
        >
            <AppAppBar navPages={['signup', 'login']} />

            <Box
                component="main"
                sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}
            >
                <Container maxWidth="xs"
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    Landing Page under construction...
                </Container>
            </Box>

            <AppFooter />
        </Box>
    );
};

export default withRoot(LandingPage);
