import React, { useEffect, useState } from "react";
import withRoot from "../../withRoot";
import { Box, Stack, Typography } from "@mui/material";
import { LoadingSpinnerWithOverlay } from "../Shared/Progresses/LoadingSpinnerWithOverlay";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ApiServices from "../../services/ApiServices";
import AppAppBar from "../Shared/AppAppBar";
// import AppFooter from "../Shared/AppFooter";
import RenderErrorPage from "../Shared/RenderErrorPage";
import { useTranslation } from "react-i18next";
import { WriteStoryModes, collectionToReadUrlMapping, storyCollectionMapping, storyContentMapping } from "./storyUtils";
import { usePupuCopilot } from "../PupuCopilot/usePupuCopilot";
import SampleEssayContents from "./A1WriteEssay/StoryComposer/SampleEssayContents";
import { getBaseURL } from "../../utils/StringUtils";


const ViewStoryPage = () => {
    const { story_type, story_id } = useParams();
    const lang = 'zh';
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const sampleIndex = searchParams.get('sample-index');

    // Load story and user app data when page mounts
    const [loading, setLoading] = useState(false);

    const [story, setStory] = useState(null);

    const collection = storyCollectionMapping[story_type];

    const StoryContent = storyContentMapping[story_type];

    const onMountPage = async () => {
        setLoading(true);
        try {
            if (story_id) {
                const loadStoryPromise = ApiServices.getInstance().viewDoc(story_id, lang, collection);
                const storyData = await loadStoryPromise;
                setStory(storyData);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        onMountPage();
        // eslint-disable-next-line
    }, [lang]);

    // Props passed down to children
    const writeStoryState = { story };
    const copilotManager = usePupuCopilot({ writeStoryState });

    const writeStoryProps = { writeStoryState, copilotManager };

    const shareStoryURL = `${collectionToReadUrlMapping[collection]}/${story_id}`

    const RenderSampleStorySelectionButtons = () => {

        const handleSampleSelect = (index) => {
            if (String(index) !== sampleIndex) { setLoading(true); }
            navigate(shareStoryURL + `/?sample-index=${index}`);
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
            setTimeout(() => {
                setLoading(false);
            }, 500);
        };

        const sampleStories = story?.data?.storyComposer?.sampleStories ?? [];

        return (
            <Stack direction="row" spacing={2} sx={{ marginBottom: '60px', justifyContent: 'center' }}>
                {sampleStories.map((_, index) => (
                    <Box
                        key={index}
                        onClick={() => handleSampleSelect(index)}
                        sx={{
                            borderBottom: 4,
                            borderColor: String(index) === sampleIndex ? 'primary.main' : 'transparent',
                            padding: 0,
                            borderRadius: 0,
                            width: '30px',
                            cursor: 'pointer',
                        }}
                    >
                        {index + 1}
                    </Box>
                ))}
            </Stack>
        )
    };

    if (loading) {
        return <LoadingSpinnerWithOverlay size={70} />;
    };

    if (story == null) {
        return <RenderErrorPage message={t('errors.storyNotFound')} />;
    }

    const sampleStory = story?.data?.storyComposer?.sampleStories?.[sampleIndex];
    if (story && !sampleStory) {
        const newUrl = shareStoryURL;
        window.history.replaceState({ path: newUrl }, '', newUrl);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                minHeight: '100vh',
                width: '100vw',
                backgroundColor: 'background.main',
                paddingTop: sampleStory != null ? '20px' : { xs: '100px', sm: '160px' },
                paddingBottom: sampleStory != null ? '20px' : { xs: '220px', sm: '160px' },
            }}
        >
            <AppAppBar navPages={['home']} />

            {sampleStory != null ?
                <>
                    <SampleEssayContents sampleStory={sampleStory} sampleEssayProps={{ allowReadSampleEssay: true }} containerStyles={{ marginTop: '100px', marginBottom: '20px' }} shareURL={getBaseURL() + shareStoryURL + `/?sample-index=${sampleIndex}`} />
                    <Typography variant="subtitle2">{t('writeStory.readOtherSampleStories')}</Typography>
                    <RenderSampleStorySelectionButtons />
                </>
                :
                <StoryContent writeStoryProps={writeStoryProps} mode={WriteStoryModes.VIEW} />
            }

            {/* <AppFooter /> */}
        </Box>
    );
};

export default withRoot(ViewStoryPage);
