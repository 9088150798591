import ApiServices from "../services/ApiServices";

export const populatePromptTemplate = (promptTemplate, source, state, interimState = {}) => {

    return promptTemplate.replace(/{{(.*?)}}/g, (match, key) => {
        let value;
        let parts = key.trim().split('.');

        // Special case, retrieve value directly from interimState
        let modifiedKey = key.startsWith('this.') ? key.slice(5) : key;
        if (modifiedKey in interimState) {
            // key: {{current.data.userInput}}, source from interimState: {'current.data.userInput': ''}
            let value = interimState[modifiedKey];
            if (typeof value === 'object' && value !== null) {
                value = JSON.stringify(value);
            }
            return value;
        }

        if (parts[0] === 'this') {
            // key: {{this.current.data.userInput}}, source from source.current.data.userInput
            parts = parts.slice(1);
            value = parts.reduce((object, prop) => object?.[prop], source);
        }
        else if (parts[0] === 'deck' || parts[0] === 'story') {
            // key: {{deck.current.data.userInput}}, source from storyState.deck.current.data.userInput
            value = parts.reduce((object, prop) => object?.[prop], state);
        }
        else if (parts[0] === 'temp') {
            // key: {{temp.categoryName}}, source from interimState: {'temp.categoryName': ''}
            let stringKey = parts.join('.');
            if (stringKey in interimState) {
                value = interimState[stringKey];
            }
        }
        return value != null ? value : `__`;
    });
};

// Run sequence of prompts
export const runPromptsForTextCompletions = async ({ action, doc, state, interimState, lang, token, verbose = 0, mock = false, docID = null, collection = null }) => {
    let aggResult = {};
    const promptObjects = doc?.prompts[action];
    const _docID = docID ?? doc._id;
    const _collection = collection ?? doc.collection;

    if (promptObjects == null || _docID == null || _collection == null) {
        if (verbose > 0) { console.warn(`Unable to load prompts from provided doc ${action} ${_collection} ${_docID}`, doc); };
        return {};
    };

    // Run prompts and save results
    for (const [promptKey, promptObject] of Object.entries(promptObjects)) {
        const { template, ...params } = promptObject
        const promptString = populatePromptTemplate(template, doc, state, { ...aggResult, ...interimState });
        if (verbose > 0) {
            console.debug(`Running ${action}, prompt ${promptKey}, deck ID ${_docID}`);
            console.debug(promptString, params);
        };

        if (!mock) {
            const stepResult = await ApiServices.getInstance().runTextCompletions(token, _docID, promptString, { ...params, action, promptKey }, lang, _collection);
            if (verbose > 0) {
                console.debug('Completion result:\n', stepResult);
            }
            aggResult = { ...aggResult, [`${promptKey}_result`]: stepResult.completion_message };
        };
    };
    return aggResult;
};

export const runPromptForImageGeneration = async ({ action, doc, state, interimState, lang, token, verbose = 0, mock = false, docID = null, collection = null }) => {
    let aggResult = {};
    const promptObjects = doc?.prompts[action];
    const _docID = docID ?? doc._id;
    const _collection = collection ?? doc.collection;

    if (promptObjects == null || _docID == null || _collection == null) {
        if (verbose > 0) { console.warn(`Unable to load prompts from provided doc ${action} ${_collection} ${_docID}`, doc); };
        return {};
    };

    // Run prompts and save results
    for (const [promptKey, promptObject] of Object.entries(promptObjects)) {
        const { template, ...params } = promptObject
        const promptString = populatePromptTemplate(template, doc, state, { ...aggResult, ...interimState });
        if (verbose > 0) {
            console.debug(`Running ${action}, prompt ${promptKey}, deck ID ${_docID}`);
            console.debug(promptString, params);
        };

        if (!mock) {
            const stepResult = await ApiServices.getInstance().runImageGeneration(token, _docID, promptString, params, lang, _collection);
            if (verbose > 0) {
                console.debug('Completion result:\n', stepResult);
            }
            aggResult = { ...aggResult, [`${promptKey}_result`]: stepResult.generation_result };
        };
    };
    return aggResult;
};