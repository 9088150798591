import React from 'react';
import { Box, Container, Typography, Link, useMediaQuery } from '@mui/material';
import { ICPNumber, companyName, companyNameZH, } from '../../configs/appConfig';
import { useTheme } from '@emotion/react';


const AppFooter = () => {
    const hostname = window.location.hostname;
    const theme = useTheme();
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

    const RenderCopyRightText = () => {
        if (hostname.includes('pufflearn.com') || hostname.includes('localhost')) {
            return (
                <Typography variant="body2" fontSize='9pt'>
                    &copy; {new Date().getFullYear()} {companyNameZH}
                    {isSmUp ? ' ' : <br />}
                    <Link
                        href='https://beian.miit.gov.cn/#/Integrated/index'
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                            color: 'inherit',
                            textDecoration: 'none'
                        }}
                    >
                        {ICPNumber}
                    </Link>
                </Typography>
            );
        } else {
            return (
                <Typography variant="body2" fontSize='9pt'>
                    &copy; {new Date().getFullYear()} {companyName}. All rights reserved.
                </Typography>
            );
        }
    }

    return (
        <Box
            component="footer"
            sx={{ bgcolor: 'transparent', color: 'text.secondary' }}
        >
            <Container maxWidth="xs">
                <RenderCopyRightText />
            </Container>
        </Box>
    );
};

export default AppFooter;
